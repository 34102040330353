import React from "react";
import { Link } from "react-router-dom";
function OktaAccessInfo({ dismissAnnouncement }) {
  return (
    <React.Fragment>
      <div>
        <p>
          Login to Okta by using credentials saved in your GateKeeper profile.
          You can set up this utility to log in to your Okta dashboard instead
          of having to type in your credentials on the login page on the Okta
          website. You can also save your secret answer or set up the OTP based
          2FA through the utility. Upon launching the utility, it will
          automatically send the username/password first and then the second
          factor to authenticate into your Okta profile.
        </p>
        <p>
          Download the Okta Access utility from the{" "}
          <Link to="/downloads">Downloads</Link> page.
        </p>
      </div>
    </React.Fragment>
  );
}

export default OktaAccessInfo;
