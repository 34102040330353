import React from "react";
import ForgotPasswordStepContainer from "./ForgotPasswordStepContainer";

function ForgotPasswordLayout() {
  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-center align-center vh-100 vw-100 login-background hub-login-form">
        <div
          style={{
            width: "60vw",
            minWidth: 300,
            maxWidth: 480,
          }}
        >
          <ForgotPasswordStepContainer />
          <div className="d-flex justify-between mt-1">
            <a
              key="support"
              target="_blank"
              rel="noopener noreferrer"
              href="https://gkaccess.com/support"
              className="color-white"
            >
              Support
            </a>
            <a
              key="company"
              target="_blank"
              rel="noopener noreferrer"
              href="https://gkaccess.com"
              className="color-white"
            >
              Untethered Labs, Inc.
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForgotPasswordLayout;
