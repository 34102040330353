import React from "react";
import { Redirect } from "react-router-dom";
import { useAuthentication } from "../../shared/AuthenticationStore";
import Setup2FAStepContainer from "./Setup2FAStepContainer";

function Setup2FALayout({ stamp }) {
  const [deliveryType, setDeliveryType] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const [otpSecret, setOtpSecret] = React.useState(null);
  const [qrBase64Image, setQrBase64Image] = React.useState(null);

  const { isAuthenticated } = useAuthentication();
  const setup2FAProps = {
    deliveryType,
    setDeliveryType,
    phoneNumber,
    setPhoneNumber,
    qrBase64Image,
    setQrBase64Image,
    otpSecret,
    setOtpSecret,
    stamp,
  };

  if (isAuthenticated) {
    const redirectPage = "/dashboard";
    return <Redirect to={redirectPage} />;
  }

  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-center align-center vh-100 vw-100 login-background hub-login-form">
        <div
          style={{
            width: "60vw",
            minWidth: 300,
            maxWidth: 480,
          }}
        >
          <Setup2FAStepContainer props={setup2FAProps} />
          <div className="d-flex justify-between mt-1">
            <a
              key="support"
              target="_blank"
              rel="noopener noreferrer"
              href="https://gkaccess.com/support"
              className="color-white"
            >
              Support
            </a>
            <a
              key="company"
              target="_blank"
              rel="noopener noreferrer"
              href="https://gkaccess.com"
              className="color-white"
            >
              Untethered Labs, Inc.
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Setup2FALayout;
