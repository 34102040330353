import { Button, Tooltip } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import TaskCard from "./TaskCard";

function AddActiveDirectoryTask({ task, settings }) {
  const history = useHistory();

  const featureEnabled = settings?.EnableADManagement?.isAllowed;

  const handleAddButtonClick = (e) => {
    history.replace("/administration#activeDirectory");
  };

  return (
    <React.Fragment>
      <TaskCard
        task={task}
        title={"Add Active Directory"}
        actions={[
          <Tooltip
            key="add"
            title={
              !featureEnabled ? "Active Directory feature is not enabled." : ""
            }
          >
            <Button onClick={handleAddButtonClick} disabled={!featureEnabled}>
              Add Active Directory Connection
            </Button>
          </Tooltip>,
        ]}
      >
        <p>
          GateKeeper Hub supports Active Directory integration to manage
          computers, user accounts from your Active Directory.
        </p>
        {!featureEnabled ? (
          <p>
            You need to enable Active Directory feature from{" "}
            <Link to="/administration#hubFeatures">Administration</Link> page
            before creating an Active Directory connection.
          </p>
        ) : null}
      </TaskCard>
    </React.Fragment>
  );
}

export default AddActiveDirectoryTask;
