import React from "react";
import { Form, Input, Row, Col } from "antd";

function LicenseCodeForm({ licenseCode, setLicenseCode }) {
  return (
    <React.Fragment>
      <Row>
        <Col span={5}></Col>
        <Col span={15}>
          <Form.Item label={""} className="w-100" colon={false}>
            <Input
              type="text"
              value={licenseCode}
              autoFocus
              placeholder={"12341234-1234-1234-1234-123412341234"}
              onChange={(e) => setLicenseCode(e.target.value)}
              className="w-100"
              pattern="^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$"
            />
          </Form.Item>
        </Col>
        <Col span={5}></Col>
      </Row>
    </React.Fragment>
  );
}

export default LicenseCodeForm;
