import React from "react";
import { Divider, Radio, Upload } from "antd";
import PortalLoginFormContainer from "./PortalLoginFormContainer";
import LicenseCodeForm from "./LicenseCodeForm";
import { InboxOutlined } from "@ant-design/icons";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

function LicenseUploadUI({
  licenseType,
  setLicenseCode,
  setLicenseType,
  licenseProps,
  portalLicenses,
  setPortalLicenses,
  licenseCode,
  source = "admin",
}) {
  return (
    <React.Fragment>
      <div className="all-center">
        <span>
          Please contact{" "}
          <a href="mailto:support@gkaccess.com">support@gkaccess.com</a> to
          order more licenses.
        </span>
      </div>
      <Divider type="horizontal">OR</Divider>
      <div className="d-flex flex-column justify-center  h-100">
        <div className="h-100 d-flex flex-1 justify-center mb-1">
          <Radio.Group
            value={licenseType}
            // size="large"
            // buttonStyle="solid"
            className="d-flex justify-center"
            onChange={(e) => {
              const value = e.target.value;
              setLicenseCode("");
              setLicenseType(value);
            }}
          >
            <Radio style={source === "admin" ? radioStyle : {}} value="file">
              Upload a license file
            </Radio>
            <Radio style={source === "admin" ? radioStyle : {}} value="code">
              Enter license code
            </Radio>
            <Radio style={source === "admin" ? radioStyle : {}} value="portal">
              Get from Customer Portal
            </Radio>
          </Radio.Group>
        </div>
        <div className="h-100 w-100">
          {licenseType === "file" ? (
            <div className="p-1">
              <Upload.Dragger {...licenseProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag license file to this area to upload.
                </p>
              </Upload.Dragger>
            </div>
          ) : licenseType === "portal" ? (
            <div className="p-1">
              <PortalLoginFormContainer
                licenseCode={licenseCode}
                setLicenseCode={setLicenseCode}
                portalLicenses={portalLicenses}
                setPortalLicenses={setPortalLicenses}
              />
            </div>
          ) : licenseType === "code" ? (
            <div className="p-1">
              <LicenseCodeForm
                licenseCode={licenseCode}
                setLicenseCode={setLicenseCode}
              />
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}

export default LicenseUploadUI;
