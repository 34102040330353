import React from "react";
import { Spin } from "antd";

function LoaderWithText({ text = "Loading" }) {
  return (
    <>
      <div className="d-flex w-100 all-center" style={{ height: "calc(60vh)" }}>
        <Spin />
        <span className="ml-1"></span> &nbsp; {text}
      </div>
    </>
  );
}

export default LoaderWithText;
