import React from "react";
import {
  ResetPassword,
  SendForgotPasswordCode,
  VerifyForgotPasswordEmail,
  VerifyResetPasswordCode,
} from "../../DataService/Account";

const ForgotPasswordContext = React.createContext({});

export const getNewForgotPasswordObject = () => ({
  identity: "",
  userId: "",
  email: "",
  phoneNumber: "",
  code: "",
  password: "",
  deliveryType: "email",
});

const defaultForgotPasswordData = {
  ...getNewForgotPasswordObject(),
};

const ForgotPasswordProvider = (props) => {
  const [forgotPasswordData, setForgotPasswordData] = React.useState(
    getNewForgotPasswordObject()
  );

  const updateKey = (key, value) => {
    setForgotPasswordData({
      ...forgotPasswordData,
      [key]: value,
    });
  };

  const updateForgotPasswordData = (data) => {
    const updatedData = {
      ...forgotPasswordData,
      ...data,
    };
    setForgotPasswordData(updatedData);
  };

  const verifyUsernameEmail = async () => {
    try {
      const { identity } = forgotPasswordData;

      const dataToSend = {
        identity,
        identityType: 1,
      };

      const account = await VerifyForgotPasswordEmail(dataToSend);

      setForgotPasswordData({ ...forgotPasswordData, ...account });
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };

  const sendRecoveryCodeToUser = async () => {
    try {
      const { userId, deliveryType } = forgotPasswordData;
      const data = { userId, deliveryType, identityType: 1 };

      const dataToSend = data;
      await SendForgotPasswordCode(dataToSend);
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };

  const validateRecoveryCode = async () => {
    try {
      const { userId, code } = forgotPasswordData;
      const dataToSend = JSON.stringify({
        userId,
        code,
        identityType: 1,
      });
      await VerifyResetPasswordCode(dataToSend);
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };

  const updatePassword = async () => {
    try {
      const { userId, password, code } = forgotPasswordData;
      const data = {
        userId,
        password,
        code,
        identityType: 1,
      };

      const dataToSend = data;
      await ResetPassword(dataToSend);
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };

  const resetData = () => {
    setForgotPasswordData(defaultForgotPasswordData);
  };

  const value = React.useMemo(
    () => ({
      forgotPasswordData,
      verifyUsernameEmail,
      resetData,
      sendRecoveryCodeToUser,
      updateForgotPasswordData,
      validateRecoveryCode,
      updatePassword,
      updateKey,
    }),
    [
      forgotPasswordData,
      verifyUsernameEmail,
      resetData,
      sendRecoveryCodeToUser,
      updateForgotPasswordData,
      validateRecoveryCode,
      updatePassword,
      updateKey,
    ]
  );

  return <ForgotPasswordContext.Provider value={value} {...props} />;
};

const useForgotPassword = () => {
  const context = React.useContext(ForgotPasswordContext);
  if (!context) {
    throw new Error(
      "useForgotPassword must be used within ForgotPasswordProvider"
    );
  }

  return context;
};

export { ForgotPasswordProvider, useForgotPassword };
