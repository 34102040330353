import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, Tooltip, message } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import useMountedState from "../shared/hooks/useMountedState";
import { Send2FACode } from "../../DataService/Account";

function TwoFactorInputStep3({
  prev,
  deliveryType,
  handle2FALogin,
  twoFactorData,
  loginLoading,
}) {
  const [code, setCode] = React.useState("");
  const [sendingCode, setSendingCode] = React.useState(false);
  const isMounted = useMountedState();

  const handleCodeChange = (e) => {
    const { value } = e.target;
    setCode(value);
  };

  const handleResendCode = async () => {
    try {
      setSendingCode(true);
      if (deliveryType === "email" || deliveryType === "phone") {
        const data = {
          stamp: twoFactorData.stamp,
          type: deliveryType === "email" ? 2 : 1,
        };
        await Send2FACode(data);
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      if (isMounted()) {
        setSendingCode(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!code) {
      message.error("OTP is required");
      return;
    }
    await handle2FALogin(code);
  };
  return (
    <React.Fragment>
      <Form
        layout="vertical"
        wrapperCol={{ span: 14, offset: 6 }}
        onSubmit={handleSubmit}
      >
        <Form.Item htmlFor="otpcode" className="mb-2">
          <Input.Group>
            <Input
              value={code}
              onChange={handleCodeChange}
              id="otpcode"
              autoComplete={"new-username"}
              autoFocus
              allowClear
              style={{ width: "calc(100% - 40px)", textAlign: "center" }}
            />{" "}
            {deliveryType === "email" || deliveryType === "phone" ? (
              <Tooltip title="Resend">
                <Button icon={<ReloadOutlined />} onClick={handleResendCode} />
              </Tooltip>
            ) : null}
          </Input.Group>
        </Form.Item>
      </Form>

      <div className="h-center mt-1">
        <Button
          htmlType="submit"
          type="primary"
          style={{ width: "160px" }}
          loading={loginLoading || sendingCode}
          onClick={handleSubmit}
        >
          {loginLoading
            ? "Signing in"
            : sendingCode
            ? "Sending Code"
            : "Submit"}
        </Button>
      </div>
      <div className="h-center mt-1">
        <Button onClick={prev} type="link" style={{ width: "160px" }}>
          Go Back
        </Button>
      </div>
    </React.Fragment>
  );
}

export default TwoFactorInputStep3;
