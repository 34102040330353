import React from "react";
import { Link } from "react-router-dom";
import TaskCard from "./TaskCard";

function ChangeFeaturesTask({ task }) {
  return (
    <React.Fragment>
      <TaskCard task={task} title={"Enable a GateKeeper Feature"}>
        <p>
          Features are add-ons that unlock additional functionality in
          GateKeeper Hub.
        </p>
        <p>
          On <Link to="/administration#hubFeatures">Administration</Link> page,
          turn on the switch for a feature to enable the functionality of the
          feature from <span className="bold">Hub Features</span> section.
        </p>
        <p>
          The additional functionality is bundled as part of your GateKeeper
          Enterprise license. Please visit the{" "}
          <a
            href="https://gkaccess.com/store/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Store
          </a>{" "}
          to see the available features for each type of license.
        </p>
      </TaskCard>
    </React.Fragment>
  );
}

export default ChangeFeaturesTask;
