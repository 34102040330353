import React from "react";
import { Spin } from "antd";
import Routes from "../shared/Routes";
import { useLocation } from "react-router-dom";
import PageDescription from "../PageDescription";
import { EmptyDivider } from "../StyledComponents";
import { useLayout } from "../shared/LayoutStore";

function Main() {
  const location = useLocation();
  const { settingsLoading } = useLayout();

  const getPagePath = () => {
    const currentPagePath = location?.pathname?.substring(1) ?? "";
    return currentPagePath;
  };

  const pagePath = getPagePath();

  return (
    <React.Fragment>
      <React.Suspense
        fallback={
          <div className="h-100 all-center" style={{ minHeight: "100vh" }}>
            <Spin /> <div className="p-1"></div> Loading...
          </div>
        }
      >
        <div className="p-1">
          <PageDescription page={pagePath} />
          {pagePath !== "/" && pagePath !== "dashboard" ? (
            <EmptyDivider height={20} />
          ) : null}
          {settingsLoading ? (
            <div className="h-100 all-center" style={{ minHeight: "100vh" }}>
              <Spin /> <div className="p-1"></div>
            </div>
          ) : (
            <Routes />
          )}
        </div>
      </React.Suspense>
    </React.Fragment>
  );
}

export default Main;
