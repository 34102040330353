import React from "react";
import AccountSetupLayout from "./AccountSetupLayout";
import { Redirect } from "react-router-dom";

function AccountSetup() {
  const params = window.location.search?.replace("?", "").split("&") ?? [];

  if (!params || params?.length === 0) {
    return <Redirect to="/login" />;
  }
  const paramsObject = {};
  params.forEach((param) => {
    const [key, value] = param.split("=");
    if (key.toLowerCase() === "token") {
      paramsObject["token"] = value;
    }
    if (key.toLowerCase() === "email") {
      paramsObject["email"] = value;
    }
  });

  const token = paramsObject["token"];
  const email = paramsObject["email"];
  if (!token || !email) return <Redirect to="/login" />;

  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-center align-center vh-100 vw-100 login-background hub-login-form">
        <div
          style={{
            width: "60vw",
            minWidth: 300,
            maxWidth: 480,
          }}
        >
          <AccountSetupLayout token={token} email={email} />

          <div className="d-flex justify-between mt-1">
            <a
              key="support"
              target="_blank"
              rel="noopener noreferrer"
              href="https://gkaccess.com/support"
              className="color-white"
            >
              Support
            </a>
            <a
              key="company"
              target="_blank"
              rel="noopener noreferrer"
              href="https://gkaccess.com"
              className="color-white"
            >
              Untethered Labs, Inc.
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AccountSetup;
