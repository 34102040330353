import React from "react";
import { useLocation } from "react-router-dom";

function useSearchParams() {
  const location = useLocation();
  const params = React.useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  return params;
}

export default useSearchParams;
