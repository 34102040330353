import React from "react";
import { WarningOutlined } from "@ant-design/icons";

function CustomError(props) {
  const type = props.match.params.type;
  return (
    <React.Fragment>
      <div
        className="h-100 all-center bold"
        style={{ fontSize: "2em", minHeight: "calc(100vh - 200px)" }}
      >
        <WarningOutlined style={{ color: "red" }} />
        <div className="p-1"></div>
        {type && type === "license"
          ? "License Error. Please contact your Hub Administrator to update licenses."
          : type === "permissions"
          ? "You do not have permissions to view this page."
          : "Error"}
      </div>
    </React.Fragment>
  );
}

export default React.memo(CustomError);
