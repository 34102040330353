import generator from "generate-password";

const defaultOptions = {
  length: 15,
  numbers: true,
  symbols: true,
  uppercase: true,
  excludeSimilarCharacters: true,
  // strict: true,
  exclude: "/",
};

export const generateRandomPassword = (options = defaultOptions) => {
  const newPassword = generator.generate(options);
  return newPassword;
};
