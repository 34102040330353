import React from "react";
import { Button, Card } from "antd";
import { useSetupHub } from "../SetupHubStore";

function TaskCard({ task, actions, handleTaskFinish, children, ...cardProps }) {
  const setup = useSetupHub();
  React.useEffect(() => {
    if (!task) {
      return;
    }
    if (task.finished) {
      handleTaskFinish && handleTaskFinish();
    }
  }, [task, handleTaskFinish]);

  const updatedActions = task.finished
    ? []
    : task.skipped
    ? [
        <Button
          key={"Mark"}
          type="link"
          onClick={() => {
            setup.updateTaskFinished(task.name);
          }}
        >
          Mark as Finished
        </Button>,
      ]
    : [
        <Button
          key={"Skip"}
          type="link"
          onClick={() => {
            setup.updateTaskSkipped(task.name);
          }}
        >
          Skip Task
        </Button>,
        <Button
          key={"Mark"}
          type="link"
          onClick={() => {
            setup.updateTaskFinished(task.name);
          }}
        >
          Mark as Finished
        </Button>,
      ];

  if (!task) {
    return;
  }

  return (
    <React.Fragment>
      <Card
        {...cardProps}
        type="inner"
        actions={task?.finished ? null : updatedActions} // Don't show actions for finished tasks
        extra={
          task ? (
            <span>
              {task.finished ? (
                <span className="color-success bold">Complete</span>
              ) : task.skipped ? (
                <span className="color-disabled bold">Skipped</span>
              ) : (
                <span className="color-warning bold">Pending</span>
              )}
            </span>
          ) : null
        }
      >
        {children}
        <div className="all-center mt-1">
          {actions && actions.length > 0 && !task.finished ? (
            <div>{actions}</div>
          ) : null}
        </div>
      </Card>
    </React.Fragment>
  );
}

export default TaskCard;
