import { ajax } from "./Common";

export async function SendAccountConfirmation(id) {
  return await ajax({
    url: `/hub/api/v4/Administration/Users/${id}/SendAccountConfirmation`,
    method: `POST`,
  });
}
export async function UpdateConfiguration(settingId, obj) {
  return await ajax({
    url: `/hub/api/v4/Administration/Configurations/${settingId}`,
    method: `PUT`,
    data: obj,
  });
}
export async function Force2FAConfiguration(data) {
  return await ajax({
    url: `/hub/api/v4/Administration/Force2FA`,
    method: `PUT`,
    data,
  });
}
export async function ValidateSysLogConnection(obj) {
  return await ajax({
    url: `/hub/api/v4/Administration/ValidateSysLogConnection`,
    method: `POST`,
    data: obj,
  });
}
export async function GetServerUsers(isActive = true, query = "") {
  return await ajax({
    url: `/hub/api/v4/Administration/Users?isActive=${isActive}&filter=${query}`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function GetGateKeeperUsersForLicense(query = ``) {
  return await ajax({
    url: `/hub/api/v4/Administration/GateKeeperUsers?filter=${query}`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function DeactivateGateKeeperUsersForLicense(obj) {
  return await ajax({
    url: `/hub/api/v4/Administration/GateKeeperUsers`,
    method: `PUT`,
    data: obj,
  });
}
export async function GetClientLogo() {
  return await ajax({
    url: `/hub/api/v4/Administration/ClientLogo`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function AddClientLogo(imageData) {
  return await ajax({
    url: `/hub/api/v4/Administration/ClientLogo`,
    method: `POST`,
    data: imageData,
    processData: false,
    contentType: false,
  });
}
export async function DeleteClientLogo() {
  return await ajax({
    url: `/hub/api/v4/Administration/ClientLogo`,
    method: `DELETE`,
  });
}
export async function GetSuperUsers() {
  return await ajax({
    url: `/hub/api/v4/Administration/SuperUsers`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function AddSuperUser(superUserObj) {
  return await ajax({
    url: `/hub/api/v4/Administration/SuperUsers`,
    method: `POST`,
    contentType: `application/json;charset=utf-8`,
    data: superUserObj,
  });
}
export async function DeleteSuperUser(superUserId) {
  return await ajax({
    url: `/hub/api/v4/Administration/SuperUsers/${superUserId}`,
    method: `DELETE`,
    contentType: `application/json;charset=utf-8`,
  });
}
export async function UpdateSuperUser(userId, superUserObj) {
  return await ajax({
    url: `/hub/api/v4/Administration/SuperUsers/${userId}`,
    method: `PUT`,
    contentType: `application/json;charset=utf-8`,
    data: superUserObj,
  });
}
export async function ActivateServerUser(userId) {
  return await ajax({
    url: `/hub/api/v4/Administration/Users/${userId}/Activate`,
    method: `Put`,
  });
}
export async function UpdateServerUser(userId, updateUserObj) {
  return await ajax({
    url: `/hub/api/v4/Administration/Users/${userId}`,
    method: `PUT`,
    contentType: `application/json;charset=utf-8`,
    data: updateUserObj,
  });
}
export async function UpdateHubAccounts(updateUserObj) {
  return await ajax({
    url: `/hub/api/v4/Administration/Users`,
    method: `PUT`,
    contentType: `application/json;charset=utf-8`,
    data: updateUserObj,
  });
}
export async function GetLicenses() {
  return await ajax({
    url: `/hub/api/v4/Administration/Licenses`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function AddLicense(license) {
  return await ajax({
    url: `/hub/api/v4/Administration/Licenses`,
    method: `POST`,
    contentType: `application/json;charset=utf-8`,
    data: license,
  });
}
export async function DeleteLicense(licenseId) {
  return await ajax({
    url: `/hub/api/v4/Administration/Licenses/${licenseId}`,
    method: `Delete`,
  });
}
export async function ActivateLicense(licenseId) {
  return await ajax({
    url: `/hub/api/v4/Administration/Licenses/${licenseId}/Activate`,
    method: `Put`,
  });
}
export async function SyncLicenses() {
  return await ajax({
    url: `/hub/api/v4/Administration/Licenses/Sync`,
    method: `Put`,
  });
}
export async function DeactivateLicense(licenseId) {
  return await ajax({
    url: `/hub/api/v4/Administration/Licenses/${licenseId}/Deactivate`,
    method: `Put`,
  });
}

export async function AddNewUser(user) {
  return await ajax({
    url: `/hub/api/v4/Administration/Users`,
    method: `POST`,
    contentType: `application/json;charset=utf-8`,
    dataType: `json`,
    data: user,
  });
}
export async function AddHubAccounts(accounts) {
  return await ajax({
    url: `/hub/api/v4/Administration/Users`,
    method: `POST`,
    contentType: `application/json;charset=utf-8`,
    data: accounts,
  });
}
export async function DeleteUser(userId) {
  return await ajax({
    url: `/hub/api/v4/Administration/Users/${userId}/Deactivate`,
    method: `PUT`,
  });
}
export async function SetServerSetting(settingObj) {
  return await ajax({
    url: `/hub/api/v4/Administration/Settings`,
    contentType: `application/json;charset=utf-8`,
    method: `PUT`,
    data: settingObj,
  });
}
export async function GetClientAPIKeys() {
  return await ajax({
    url: `/hub/api/v4/Administration/APIKeys`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function ShowClientAPIKeys(id, data) {
  return await ajax({
    url: `/hub/api/v4/Administration/APIKeys/${id}`,
    method: `POST`,
    dataType: `json`,
    data: data,
  });
}
export async function GetDatabaseInfo() {
  return await ajax({
    url: `/hub/api/v4/Administration/Database`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function GetAllRoles() {
  return await ajax({
    url: `/hub/api/v4/Administration/Roles`,
    method: `GET`,
  });
}
export async function GetRole(id) {
  return await ajax({
    url: `/hub/api/v4/Administration/Roles/${id}`,
    method: `GET`,
  });
}
export async function GetActiveDirectoryDomains() {
  return await ajax({
    url: `/hub/api/v4/Administration/ActiveDirectoryManagement/domains`,
    method: `GET`,
  });
}
export async function GetRegisteredActiveDirectoryDomains() {
  return await ajax({
    url: `/hub/api/v4/Administration/ActiveDirectory/domains`,
    method: `GET`,
  });
}
export async function CreateOnPremiseActiveDirectoryDomain(data) {
  const response = await ajax({
    method: `POST`,
    url: `/hub/api/v4/Administration/ActiveDirectoryManagement/domains/OnPremise`,
    data,
  });
  return response?.data;
}
export async function CreateActiveDirectoryConnector(data) {
  const response = await ajax({
    method: `POST`,
    url: `/hub/api/v4/Administration/ActiveDirectoryManagement/domains/OnPremiseConnector`,
    data,
  });
  return response;
}
export async function UpdateActiveDirectoryDomain(domainId, data) {
  const response = await ajax({
    method: `PUT`,
    url: `/hub/api/v4/Administration/ActiveDirectoryManagement/domains/${domainId}`,
    data,
  });
  return response?.data;
}
export async function CreateAzureActiveDirectoryDomain(data) {
  const response = await ajax({
    method: `POST`,
    url: `/hub/api/v4/Administration/ActiveDirectoryManagement/domains/Azure`,
    data,
  });
  return response?.data;
}
export async function CancelRegistrationAzureActiveDirectoryDomain(data) {
  const response = await ajax({
    method: `POST`,
    url: `/hub/api/v4/Administration/ActiveDirectoryManagement/domains/Azure/RegisterError`,
    data,
  });
  return response?.data;
}
export async function ValidateOnPremiseADDomain(data) {
  return await ajax({
    method: `POST`,
    url: `/hub/api/v4/Administration/ActiveDirectoryManagement/domains/OnPremise/ValidateConnection`,
    data,
  });
}
export async function ValidateAzureADDomain(data) {
  return await ajax({
    method: `POST`,
    url: `/hub/api/v4/Administration/ActiveDirectoryManagement/domains/Azure/ValidateConnection`,
    data,
  });
}
export async function DeleteActiveDirectoryDomain(domainId) {
  return await ajax({
    url: `/hub/api/v4/Administration/ActiveDirectoryManagement/domains/${domainId}`,
    method: `DELETE`,
  });
}
export async function GetResourcesForRoles() {
  return await ajax({
    url: `/hub/api/v4/Administration/Roles/Resources`,
    method: `GET`,
  });
}
export async function CreateRole(data) {
  return await ajax({
    url: `/hub/api/v4/Administration/Roles`,
    method: `POST`,
    contentType: `application/json;charset=utf-8`,
    data,
  });
}
export async function UpdateRole(id, data) {
  return await ajax({
    url: `/hub/api/v4/Administration/Roles/${id}`,
    method: `PUT`,
    contentType: `application/json;charset=utf-8`,
    data,
  });
}
export async function DeleteRole(id) {
  return await ajax({
    url: `/hub/api/v4/Administration/Roles/${id}`,
    method: `DELETE`,
  });
}
