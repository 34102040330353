import React from "react";
import { ForgotPasswordProvider } from "../../shared/ForgotPasswordStore";
import ForgotPasswordLayout from "./ForgotPasswordLayout";

function ForgotPassword() {
  return (
    <React.Fragment>
      <ForgotPasswordProvider>
        <ForgotPasswordLayout />
      </ForgotPasswordProvider>
    </React.Fragment>
  );
}

export default ForgotPassword;
