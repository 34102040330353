import React from "react";
import { Link } from "react-router-dom";
import TaskCard from "./TaskCard";

function AddAdminTask({ task }) {
  return (
    <React.Fragment>
      <TaskCard task={task} title={"Add a Hub User"}>
        <p>Hub users are administrators who manage GateKeeper Hub.</p>
        <p>
          On <Link to="/administration#activeHubUsers">Administration</Link>{" "}
          page, add a Hub admin user from{" "}
          <span className="bold">Active Hub Users</span> section.
        </p>
      </TaskCard>
    </React.Fragment>
  );
}

export default AddAdminTask;
