import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input } from "antd";
import {
  passwordRules,
  validateConfirmPassword,
  validateUsername,
  validatePassword,
} from "./first-user-form-validators";
import PasswordWithRequirements from "../../../shared/UI/PasswordWithRequirements";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

function FirstUserAdminProfileForm({
  username,
  setUsername,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
}) {
  const handleChangeUsername = (e) => {
    const value = e.target.value;
    const validatedUsername = validateUsername(value);
    setUsername({ value, ...validatedUsername });
  };

  const updateConfirmPassword = (passwordValue, confirmPasswordValue) => {
    const validatedPwd = validateConfirmPassword(
      passwordValue,
      confirmPasswordValue
    );
    setConfirmPassword({
      value: confirmPasswordValue,
      ...validatedPwd,
    });
  };

  const handleChangePassword = (e) => {
    const value = e.target.value;
    const validatedPassword = validatePassword(value, confirmPassword.value);
    setPassword({
      value,
      ...validatedPassword,
    });
    const validatedConfirmPwd = validateConfirmPassword(
      value,
      confirmPassword.value
    );
    setConfirmPassword({
      value: confirmPassword.value,
      ...validatedConfirmPwd,
    });
  };

  const handleChangeConfirmPassword = (e) => {
    const value = e.target.value;
    updateConfirmPassword(password.value, value);
  };

  return (
    <React.Fragment>
      <Form {...formItemLayout} className="w-100">
        <Form.Item
          label={
            <span>
              Username <span className="required-field"></span>
            </span>
          }
          htmlFor="username"
          help={username.help}
          validateStatus={username.validateStatus}
        >
          <Input
            autoComplete={"new-username"}
            autoFocus
            id="username"
            placeholder="Username"
            value={username.value}
            onChange={handleChangeUsername}
          />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Password <span className="required-field"></span>
            </span>
          }
          validateStatus={password.validateStatus}
          help={password.help}
          htmlFor="password"
        >
          <PasswordWithRequirements
            id="password"
            autoComplete={"new-password"}
            placeholder="Password"
            value={password.value || ""}
            onChange={handleChangePassword}
            rules={passwordRules}
          />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Confirm password <span className="required-field"></span>
            </span>
          }
          validateStatus={confirmPassword.validateStatus}
          help={confirmPassword.help}
          htmlFor={"confirmPassword"}
        >
          <Input.Password
            placeholder="Confirm password"
            autoComplete={"new-password"}
            id="confirmPassword"
            value={confirmPassword.value}
            onChange={handleChangeConfirmPassword}
          />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}

export default FirstUserAdminProfileForm;
