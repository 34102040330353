import React from "react";
import { Link } from "react-router-dom";

function DefaultAuthenticatorInfo({ dismissAnnouncement }) {
  return (
    <React.Fragment>
      <div>
        <p>
          GateKeeper Hub administrators can now set the default authentication
          mechanism for all GateKeeper-enabled computers connected to the Hub.
        </p>
        <p>
          The GateKeeper Client application must be updated to{" "}
          <strong>3.13.27</strong> or later version to choose the Default
          Authenticator.
        </p>
        <p>
          Go to your <Link to="/groups">Groups page</Link> and click on the{" "}
          <strong>Global Settings</strong> button and select your favored
          authenticator from the Default Authentication Method dropdown options.
        </p>
        <p>
          Make sure to enable the chosen authenticator in the{" "}
          <strong>Authenticators</strong> list in Global Settings.
        </p>
      </div>
    </React.Fragment>
  );
}

export default DefaultAuthenticatorInfo;
