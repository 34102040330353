export const cleanupBackendMessage = (msg) => {
  try {
    if (!msg || typeof msg !== "string") {
      return null;
    }

    let cleaned = msg;

    cleaned = cleaned[0] === '"' ? cleaned.slice(1) : cleaned;
    cleaned =
      cleaned[cleaned.length - 1] === '"'
        ? cleaned.slice(0, cleaned.length - 1)
        : cleaned;

    return cleaned;
  } catch (error) {
    console.log("cleanupBackendMessage error:", error);
    return null;
  }
};
