export function validatePortalLoginForm(values) {
  const errors = {};

  if (!values.email) {
    errors.email = "Cannot be empty.";
  }

  if (!values.password) {
    errors.password = "Cannot be empty.";
  }

  return errors;
}
