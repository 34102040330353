import React from "react";
import { UpdateLoggedInUser } from "../../DataService/Account";
import { useAuthentication } from "./AuthenticationStore";
import { useAsyncFunction } from "./hooks/useAsyncFunction";
import {
  enable as enableDarkMode,
  disable as disableDarkMode,
} from "darkreader";

/**
 * @typedef {Object} ChartColumns
 * @property {string[]} columnOne - Charts in left column.
 * @property {string[]} columnTwo - Charts in right column.
 *
 * @typedef {Object} UserPreferences
 * @property {ChartColumns} chartColumns - Chart Columns
 */

/**
 * @type {UserPreferences}
 */
export const defaultUserPreferences = {
  chartColumns: {
    columnOne: ["Status of Active Computers", "Lock and Unlock Events"],
    columnTwo: ["Top Active Users", "Sessions and Logged in Time"],
  },
  theme: "light",
};

function patchMissingPreferencesWithDefaults(
  savedUserPreferences,
  defaultUserPreferences
) {
  let newUserPreferences = { ...savedUserPreferences };
  Object.entries(defaultUserPreferences).forEach(
    ([preferenceName, defaultPreferenceValue]) => {
      if (!(preferenceName in newUserPreferences)) {
        newUserPreferences[preferenceName] = defaultPreferenceValue;
      }
    }
  );
  return newUserPreferences;
}

const LoggedInUserContext = React.createContext({});

function LoggedInUserProvider(props) {
  const { loggedInUser: user, getLoggedInUserDetails } = useAuthentication();

  const [userPreferences, setUserPreferences] = React.useState(
    defaultUserPreferences
  );

  const [theme, setTheme] = React.useState(userPreferences.theme ?? "light");

  const getUserPreferences = React.useCallback(() => {
    try {
      if (!user || !user.jsonUserPreference) {
        setUserPreferences(defaultUserPreferences);
        return;
      }
      const savedUserPreferences = JSON.parse(user.jsonUserPreference);
      const patchedUserPreferences = patchMissingPreferencesWithDefaults(
        savedUserPreferences,
        defaultUserPreferences
      );
      setUserPreferences(patchedUserPreferences);
      switchTheme(patchedUserPreferences.theme, false);
    } catch (error) {
      console.error("error:", error);
    }
  }, [user]);

  const updateUserPreferences = useAsyncFunction(
    async (newPreferences) => {
      try {
        if (!user) {
          return;
        }
        await UpdateLoggedInUser(user.id, {
          jsonUserPreference: JSON.stringify(newPreferences),
        });
        setUserPreferences(newPreferences);
        await getLoggedInUserDetails(user.id);
      } catch (error) {
        console.error("error:", error);
      }
    },
    [userPreferences, user, getLoggedInUserDetails]
  );

  const resetUserPreferences = React.useCallback(() => {
    updateUserPreferences(defaultUserPreferences);
  }, [updateUserPreferences]);

  const switchTheme = React.useCallback(
    (newTheme = "light", saveToDb = true) => {
      if (newTheme === "dark") {
        enableDarkMode(
          {
            brightness: 100,
            contrast: 90,
            sepia: 20,
          },
          {
            invert: ["i.ant-menu-submenu-arrow"],
          }
        );
      } else {
        disableDarkMode();
      }
      setTheme(newTheme);
      if (saveToDb) {
        updateUserPreferences({
          ...userPreferences,
          theme: newTheme,
        });
      }
    },
    [userPreferences, updateUserPreferences, theme]
  );

  React.useEffect(() => {
    getUserPreferences();
  }, [user]);

  const value = React.useMemo(
    () => ({
      user,
      userPreferences,
      updateUserPreferences,
      resetUserPreferences,
      theme,
      switchTheme,
    }),
    [
      user,
      userPreferences,
      updateUserPreferences,
      resetUserPreferences,
      theme,
      switchTheme,
    ]
  );
  return <LoggedInUserContext.Provider value={value} {...props} />;
}

function useLoggedInUser() {
  const context = React.useContext(LoggedInUserContext);

  if (!context) {
    throw new Error("useLoggedInUser must be used within LoggedInUserProvider");
  }

  return context;
}

export { LoggedInUserProvider, useLoggedInUser };
