import React from "react";
import { Link } from "react-router-dom";
import TaskCard from "./TaskCard";

function ChangeGroupSettingsTask({ task }) {
  return (
    <React.Fragment>
      <TaskCard task={task} title={"Change Groups & Settings"}>
        <p>
          Groups in GateKeeper Hub allows to set authorization rules for
          computers. You can define how a set of users can access a set of
          computers.
        </p>
        <p>
          <ol>
            <li className="mb-1">
              On <Link to="/groups">Groups</Link> page, hover on actions column
              on any row and click <span className="bold">Manage Settings</span>
            </li>
            <li>
              Review the LOCK and UNLOCK settings on the Organization group and
              choose the ones that best fit your workflow.
            </li>
          </ol>
        </p>
      </TaskCard>
    </React.Fragment>
  );
}

export default ChangeGroupSettingsTask;
