export const validateEmail = (email) => {
  if (!email) {
    return {
      validateStatus: "error",
      help: "Required",
    };
  }

  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email.toString().toLowerCase())) {
    return {
      validateStatus: "error",
      help: "Invalid Email",
    };
  }

  return {
    validateStatus: "success",
    help: "",
  };
};
