import React from "react";
import { validatePortalLoginForm } from "./validate-portal-login-form";
import PortalLoginForm from "./PortalLoginForm";

import { message } from "antd";
import { GetLicensesFromPortal } from "../../../DataService/Misc";
import PortalLicenseTable from "./PortalLicenseTable";

function PortalLoginFormContainer({
  licenseCode,
  setLicenseCode,
  portalLicenses,
  setPortalLicenses,
}) {
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = async (values) => {
    try {
      const licenses = await GetLicensesFromPortal(values);
      if (!licenses || licenses.length === 0) {
        message.error("You do not have any valid licenses");
        return;
      }
      setPortalLicenses(licenses);
    } catch (error) {
      console.error("error:", error);
      message.error("Invalid customer portal email or password.");
    }
  };

  const validate = (values) => {
    const errors = validatePortalLoginForm(values);
    return errors;
  };

  const formProps = {
    initialValues,
    validate,
    onSubmit,
  };
  return (
    <React.Fragment>
      {portalLicenses.length < 1 ? (
        <PortalLoginForm {...formProps} />
      ) : (
        <PortalLicenseTable
          licenses={portalLicenses}
          setLicenseCode={setLicenseCode}
        />
      )}
    </React.Fragment>
  );
}

export default PortalLoginFormContainer;
