import React from "react";
import Icon, { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import { GoSettings, GoSignOut } from "react-icons/go";
import { useAuthentication } from "../shared/AuthenticationStore";
import { LinkStyleButton } from "../StyledComponents";
// import DemoLogoutModal from "../GKDemoLayout/DemoLogoutModal";

function UserProfileDropDown() {
  const { logout, loggedInUser } = useAuthentication();
  // const [showModal, setShowModal] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({
    image: null,
    name: "",
  });

  const getUserProfile = async () => {
    if (!loggedInUser) {
      return;
    }
    const { userPhoto, firstName } = loggedInUser;
    const dataURI = userPhoto ? "data:image/bmp;base64," + userPhoto : null;
    setUserDetails({
      image: dataURI,
      name: firstName,
    });
  };

  const handleLogout = () => {
    logout();
  };

  React.useEffect(() => {
    getUserProfile();
  }, [loggedInUser]);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/profile">
          <Icon component={() => <GoSettings />} />
          <span className="ml-1">Profile Settings</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout}>
        <Link to="#">
          <Icon component={() => <GoSignOut />} />
          <span className="ml-1">Logout</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <React.Fragment>
      <Dropdown overlay={menu}>
        <LinkStyleButton style={{ lineHeight: "clamp(10px, 20px, 25px)" }}>
          {userDetails.image ? (
            <img
              height="30"
              width="30"
              style={{ borderRadius: "50%", objectFit: "contain" }}
              src={userDetails.image}
              alt="User profile"
            />
          ) : (
            <UserOutlined />
          )}
          <span className="mr-1"></span>
          {userDetails.name ?? ""}
          <DownOutlined className="ml-1" />
        </LinkStyleButton>
      </Dropdown>
      {/* <DemoLogoutModal showModal={showModal} setShowModal={setShowModal} /> */}
    </React.Fragment>
  );
}

export default UserProfileDropDown;
