import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Radio, message, Space } from "antd";
import { useForgotPassword } from "../../../shared/ForgotPasswordStore";
import { cleanupBackendMessage } from "../../../../Utils/ErrorMessages";
import useMountedState from "../../../shared/hooks/useMountedState";

function RecoveryCodeOptions({ next }) {
  const { forgotPasswordData, sendRecoveryCodeToUser, updateKey } =
    useForgotPassword();
  const [loading, setLoading] = React.useState(false);
  const [deliveryType, setDeliveryType] = React.useState(
    forgotPasswordData.deliveryType || "email"
  );
  const isMounted = useMountedState();

  const handleChangeTransportType = (e) => {
    const { value } = e.target;
    updateKey("deliveryType", value);
    setDeliveryType(value);
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      const data = {
        userId: forgotPasswordData.userId,
        viaEmail: deliveryType === "email",
        viaPhone: deliveryType === "phone",
      };
      await sendRecoveryCodeToUser(data);
      next();
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) || "Failed to send code."
      );
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleNext();
        }}
      >
        <Radio.Group
          className="mt-1 mb-3"
          onChange={handleChangeTransportType}
          value={deliveryType}
          optionType="button"
          buttonStyle="default"
          style={{ textAlign: "center", width: "100%" }}
        >
          <Space direction="vertical">
            {forgotPasswordData.email ? (
              <Radio.Button
                style={{
                  minWidth: "240px",
                  maxWidth: "360px",
                }}
                value={"email"}
                autoFocus
              >
                Email: {forgotPasswordData.email}
              </Radio.Button>
            ) : null}
            {forgotPasswordData.phoneNumber ? (
              <Radio.Button
                style={{
                  maxWidth: "360px",
                  minWidth: "240px",
                }}
                value={"phone"}
              >
                SMS: {forgotPasswordData.phoneNumber}
              </Radio.Button>
            ) : null}
          </Space>
        </Radio.Group>
        <div className="h-center">
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            style={{ width: "160px" }}
          >
            {loading ? "Sending Code" : "Next"}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default RecoveryCodeOptions;
