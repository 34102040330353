export const passwordRules = {
  minlength: {
    text: "Minimum 10 characters long.",
    validate: (password) => {
      let minLength = 10;
      return password.trim().length >= minLength;
    },
    passed: false,
  },
  containNumbers: {
    text: "Contain at least one number.",
    minLength: 1,

    validate: (password) => {
      const regex = new RegExp("[0-9]", "g");
      return regex.test(password);
    },
    passed: false,
  },
  containLowerCaseAlphabet: {
    text: "Contain at least one lowercase character.",
    minLength: 1,
    validate: (password) => {
      const regex = new RegExp("[a-z]", "g");
      return regex.test(password);
    },
    passed: false,
  },
  containUpperCaseAlphabet: {
    text: "Contain at least one uppercase character.",
    minLength: 1,
    validate: (password) => {
      const regex = new RegExp("[A-Z]", "g");
      return regex.test(password);
    },
    passed: false,
  },
  containSymbols: {
    text: "Contain at least one symbol.",
    minLength: 1,
    validate: (password) => {
      const regex = new RegExp(
        "[!,%,&,@,#,$,^,*,?,_,~,/,:,;,',\",{,},\\\\,+,=,(,),-,<,>,.,\\-,\\|]",
        "g"
      );
      return regex.test(password);
    },
    passed: false,
  },
};

export const validatePassword = (password) => {
  if (!password) {
    return {
      validateStatus: "error",
      help: "Cannot be empty.",
    };
  }

  let isPasswordValid = true;
  Object.values(passwordRules).forEach((rule) => {
    isPasswordValid &= rule.validate(password);
  });

  if (!isPasswordValid) {
    return {
      validateStatus: "error",
      help: "Doesn't meet password requirements.",
    };
  }

  return {
    validateStatus: "success",
    help: "",
  };
};

export const validateConfirmPassword = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return {
      validateStatus: "error",
      help: "Doesn't match password.",
    };
  }

  return {
    validateStatus: "success",
    help: "",
  };
};
