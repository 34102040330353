import React from "react";
import TaskCard from "./TaskCard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function UseGateKeeperTask({ task }) {
  return (
    <React.Fragment>
      <TaskCard task={task} title={"Use GateKeeper to Lock/Unlock"}>
        <p>
          Use your GateKeeper Halberd or Trident smartphone to lock and unlock a
          GateKeeper enabled computer connected to the Hub.
        </p>
        <p>
          You will need to install the GateKeeper Client application found{" "}
          <Link to="/downloads">here</Link> and connect it to the Hub.
        </p>
        <p>
          You will also need to add a{" "}
          <Link to="/users?open=addusers">New User</Link> and assign them a
          GateKeeper Token and a computer login credential.
        </p>
      </TaskCard>
    </React.Fragment>
  );
}

export default UseGateKeeperTask;
