import React from "react";
import { Steps } from "antd";
import RegistrationSuccessCongratulations from "./RegistrationSuccessCongratulations";
import RegistrationSuccessNewLicense from "./RegistrationSuccessNewLicense";
import RegistrationSuccessGoto from "./RegistrationSuccessGoto";

const { Step } = Steps;

function RegistrationSuccessSteps(props) {
  const [currentStep, setCurrentStep] = React.useState(0);

  const formProps = {};

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const resetNewUser = React.useCallback(() => {
    setCurrentStep(0);
  }, []);

  const goToStep = (step) => {
    if (typeof step !== "number" || step < 0) {
      return;
    }

    setCurrentStep(step);
  };

  const getStepTitle = (title = "", stepNumber) => {
    const shouldAllowClick = currentStep > stepNumber;
    return (
      <span
        style={{ cursor: shouldAllowClick ? "pointer" : "default" }}
        onClick={() => {
          if (shouldAllowClick) {
            goToStep(stepNumber);
          }
        }}
      >
        {title}
      </span>
    );
  };

  const stepProps = {
    ...props,
    next,
    prev,
    currentStep,
    resetNewUser,
    formProps,
  };

  const steps = [
    {
      title: getStepTitle("Success", 0),
      content: <RegistrationSuccessCongratulations {...stepProps} />,
    },
    {
      title: getStepTitle("Add License", 1),
      content: <RegistrationSuccessNewLicense {...stepProps} />,
    },
    {
      title: getStepTitle("Go To", 2),
      content: <RegistrationSuccessGoto {...stepProps} />,
    },
  ];

  return (
    <React.Fragment>
      <Steps progressDot current={currentStep}>
        {steps.map((item, stepIndex) => (
          <Step key={stepIndex} title={item.title} />
        ))}
      </Steps>
      <div className="p-2 steps-content" style={{ height: "95%" }}>
        {steps[currentStep].content}
      </div>
    </React.Fragment>
  );
}

export default RegistrationSuccessSteps;
