import { ajax } from "./Common";
export async function GetLicensesFromPortal(data) {
  return await ajax(
    {
      method: `POST`,
      contentType: `application/json;charset=utf-8`,
      url: `/hub/api/v4/Misc/CustomerLicenses`,
      data,
    },
    { needsToken: false }
  );
}
export async function GetActiveDirectoryConnectionStatus() {
  return await ajax({
    url: `/hub/api/v4/Misc/DirectoryConnection`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function GetAllDevices() {
  return await ajax({
    url: `/hub/api/v4/Misc/AllDevices`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function ValidateDevices(devicesObject) {
  return await ajax({
    url: `/hub/api/v4/Misc/ValidateDevices`,
    contentType: `application/json;charset=utf-8`,
    method: `POST`,
    data: devicesObject,
  });
}
export async function GetCurrentSoftwareDownloaded() {
  return await ajax({
    url: `/hub/api/v4/Misc/CurrentSoftwareDownloaded`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function GetWhatsNewFeatures() {
  return await ajax({
    url: `/hub/api/v4/Misc/WhatsNewFeatures`,
    method: `GET`,
  });
}
export async function DownloadClientSoftware() {
  return await ajax({
    url: `/hub/api/v4/Misc/DownloadClientSoftware`,
    method: `GET`,
  });
}
export async function ValidatePasswordStrength(passwordObject) {
  return await ajax({
    url: `/hub/api/v4/Misc/PasswordStrength`,
    contentType: `application/json;charset=utf-8`,
    method: `POST`,
    data: passwordObject,
  });
}

export async function KBSearch(query) {
  return await ajax({
    url: `/hub/api/v4/Misc/kbsearch?query=${query}`,
    method: `GET`,
  });
}
export async function KBArticle(id) {
  return await ajax({
    url: `/hub/api/v4/Misc/kbarticle/${id}`,
    method: `GET`,
  });
}
