import React, { Suspense } from "react";
import NotFound from "../NotFound";
import CustomError from "../CustomError";
import { Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { useLayout, defaultSettings } from "./LayoutStore";
import { useLoggedInUser } from "./LoggedInUserStore";
import LoaderWithText from "./UI/LoaderWithText";

const Dashboard = React.lazy(() => import("../Dashboard"));
const Groups = React.lazy(() => import("../Groups"));
const Computers = React.lazy(() => import("../Computers"));
const WMIComputers = React.lazy(() => import("../WMIComputers"));
const Users = React.lazy(() => import("../Users"));
const ADManagement = React.lazy(() => import("../ADManagement"));
const RadiusManagement = React.lazy(() => import("../RadiusManagement"));
const RadiusAccounting = React.lazy(() => import("../RadiusAccounting"));
const Alerts = React.lazy(() => import("../Alerts"));
const OnDemandReports = React.lazy(() => import("../Reports"));
const PreviousReports = React.lazy(() => import("../Reports/PreviousReports"));
const ScheduledReports = React.lazy(() =>
  import("../Reports/ScheduledReports")
);
const Downloads = React.lazy(() => import("../Downloads"));
const ClientLogs = React.lazy(() => import("../ClientLogs"));
const ServerLogs = React.lazy(() => import("../ServerLogs"));
const AlertLogs = React.lazy(() => import("../AlertLogs"));
const Scans = React.lazy(() => import("../Scans"));
const Administration = React.lazy(() => import("../Administration"));
const UserProfile = React.lazy(() => import("../UserProfile"));
const NewHubRegistration = React.lazy(() => import("../NewHubRegistration"));

const ROUTE_PATHS = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  GROUPS: "/groups",
  GK_COMPUTERS: "/computers",
  GK_USERS: "/users",
  DOWNLOADS: "/downloads",
  PROFILE: "/profile",
  ADMINISTRATION: "/administration",
  SCANS: "/scans",
  CLIENT_LOGS: "/logs/client-logs",
  SERVER_LOGS: "/logs/server-logs",
  ON_DEMAND_REPORTS: "/reports/on-demand-reports",
  PREVIOUS_REPORTS: "/reports/previous-reports",
  SCHEDULED_REPORTS: "/reports/scheduled-reports",
  ALERTS_MANAGE: "/alerts/manage",
  ALERTS_LOGS: "/alerts/logs",
  AD_MANAGAEMENT: "/ad-management",
  RADIUS_MANAGEMENT: "/radius/manage",
  RADIUS_ACCOUNTING: "/radius/accounting",
  REMOTE_MANAGEMENT: "/remote-management",
  CUSTOM_ERROR: "/error/:type",
};

/* 
 Keep a mapping of route path to the controller name defined in Roles
*/
export const PATH_ROLES_MAP = {
  [ROUTE_PATHS.HOME]: "all_roles",
  [ROUTE_PATHS.DASHBOARD]: "all_roles",
  [ROUTE_PATHS.GROUPS]: "Groups",
  [ROUTE_PATHS.GK_COMPUTERS]: "Computers",
  [ROUTE_PATHS.GK_USERS]: "GateKeeperUsers",
  [ROUTE_PATHS.DOWNLOADS]: "Downloads",
  [ROUTE_PATHS.PROFILE]: "all_roles",
  [ROUTE_PATHS.ADMINISTRATION]: "administrator",
  [ROUTE_PATHS.SCANS]: "Scans",
  [ROUTE_PATHS.CLIENT_LOGS]: "ClientLogs",
  [ROUTE_PATHS.SERVER_LOGS]: "HubLogs",
  [ROUTE_PATHS.ON_DEMAND_REPORTS]: "Reports",
  [ROUTE_PATHS.PREVIOUS_REPORTS]: "Reports",
  [ROUTE_PATHS.SCHEDULED_REPORTS]: "Reports",
  [ROUTE_PATHS.ALERTS_MANAGE]: "Alerts",
  [ROUTE_PATHS.ALERTS_LOGS]: "Alerts",
  [ROUTE_PATHS.AD_MANAGAEMENT]: "ActiveDirectory",
  [ROUTE_PATHS.RADIUS_MANAGEMENT]: "RADIUS",
  [ROUTE_PATHS.RADIUS_ACCOUNTING]: "RADIUS",
  [ROUTE_PATHS.REMOTE_MANAGEMENT]: "Computers",
  [ROUTE_PATHS.CUSTOM_ERROR]: "all_roles",
};

export const getHiddenRoutes = () => {
  const hiddenRoutes = [
    {
      exact: true,
      path: "/newhubregistration",
      component: NewHubRegistration,
      title: "New Hub Registration",
    },
  ];

  return hiddenRoutes;
};

export const filterAllowedRoutesByRoles = (routes, access) => {
  let allowedRoutes = [];

  if (!access) {
    return [];
  }
  routes.forEach((route) => {
    const path = route.path;
    const roleNameForPath = PATH_ROLES_MAP[path];
    if (!roleNameForPath || roleNameForPath === "all_roles") {
      allowedRoutes.push(route);
    } else {
      const accessForPath = access[PATH_ROLES_MAP[path]];
      if (accessForPath && (accessForPath.canView || accessForPath.canEdit)) {
        allowedRoutes.push(route);
      }
    }
  });

  return allowedRoutes;
};

const getRoutes = (
  settings = defaultSettings,
  user = {
    isInAdministratorRole: false,
    access: null,
    hasGlobalAccess: false,
  },
  requiresLicense = false
) => {
  const commonRoutes = [
    {
      exact: true,
      path: ROUTE_PATHS.HOME,
      component: () => <Redirect to="/dashboard" />,
      title: "Dashboard",
    },
    {
      exact: true,
      path: ROUTE_PATHS.DASHBOARD,
      component: Dashboard,
      title: "Dashboard",
    },
    {
      exact: true,
      path: ROUTE_PATHS.GROUPS,
      component: Groups,
      title: "Groups & Settings",
    },
    {
      exact: true,
      path: ROUTE_PATHS.GK_COMPUTERS,
      component: Computers,
      title: "Computers",
    },

    {
      exact: true,
      path: ROUTE_PATHS.GK_USERS,
      component: Users,
      title: "Users",
    },

    {
      exact: true,
      path: ROUTE_PATHS.DOWNLOADS,
      component: Downloads,
      title: "Downloads",
    },

    {
      exact: true,
      path: ROUTE_PATHS.PROFILE,
      component: UserProfile,
      title: "Hub User Profile",
    },
  ];

  const adminRoutes = user?.isInAdministratorRole
    ? [
        {
          exact: true,
          path: ROUTE_PATHS.ADMINISTRATION,
          component: Administration,
          title: "Administration",
        },
      ]
    : [];

  const recordScansRoutes = settings.RecordScans
    ? [
        {
          exact: true,
          path: ROUTE_PATHS.SCANS,
          component: Scans,
          title: "Scans",
        },
      ]
    : [];
  const recordLogsRoutes = settings.RecordLogs
    ? [
        {
          exact: true,
          path: ROUTE_PATHS.CLIENT_LOGS,
          component: ClientLogs,
          title: "Client Logs",
        },
      ]
    : [];
  const recordServerLogsRoutes = settings.RecordServerLogs
    ? [
        {
          exact: true,
          path: ROUTE_PATHS.SERVER_LOGS,
          component: ServerLogs,
          title: "Server Logs",
        },
      ]
    : [];
  const enableReportsRoutes = settings.EnableReports
    ? [
        {
          exact: false,
          path: ROUTE_PATHS.ON_DEMAND_REPORTS,
          component: OnDemandReports,
          title: "On-Demand Reports",
        },
        {
          exact: true,
          path: ROUTE_PATHS.PREVIOUS_REPORTS,
          component: PreviousReports,
          title: "Previous Reports",
        },
        {
          exact: true,
          path: ROUTE_PATHS.SCHEDULED_REPORTS,
          component: ScheduledReports,
          title: "Scheduled Reports",
        },
      ]
    : [];
  const enableAlertsRoutes = settings.EnableAlerts
    ? [
        {
          exact: true,
          path: ROUTE_PATHS.ALERTS_MANAGE,
          component: Alerts,
          title: "Alerts",
        },
        {
          exact: true,
          path: ROUTE_PATHS.ALERTS_LOGS,
          component: AlertLogs,
          title: "Alert Logs",
        },
      ]
    : [];
  const enableADManagementRoutes =
    settings.EnableADManagement &&
    user?.isInAdministratorRole &&
    user?.hasGlobalAccess
      ? [
          {
            exact: true,
            path: ROUTE_PATHS.AD_MANAGAEMENT,
            component: ADManagement,
            title: "AD Accounts In GateKeeper Hub",
          },
        ]
      : [];

  const enableWindowsManagementRoutes =
    settings.EnableWindowsManagement &&
    user?.isInAdministratorRole &&
    user?.hasGlobalAccess
      ? [
          {
            exact: true,
            path: ROUTE_PATHS.REMOTE_MANAGEMENT,
            component: WMIComputers,
            title: "Remote Management",
          },
        ]
      : [];
  const enableRadiusManagementRoutes =
    settings.EnableRADIUS && user?.isInAdministratorRole
      ? [
          {
            exact: true,
            path: ROUTE_PATHS.RADIUS_MANAGEMENT,
            component: RadiusManagement,
            title: "RADIUS User Management",
          },
          {
            exact: true,
            path: ROUTE_PATHS.RADIUS_ACCOUNTING,
            component: RadiusAccounting,
            title: "RADIUS Accounting",
          },
        ]
      : [];

  const customErrorRoutes = [
    {
      exact: true,
      path: ROUTE_PATHS.CUSTOM_ERROR,
      component: CustomError,
      title: "Error",
    },
  ];
  const notFoundRoutes = [
    {
      exact: false,
      path: "*",
      component: NotFound,
      title: "Not Found",
    },
  ];

  if (requiresLicense) {
    return [...adminRoutes, ...customErrorRoutes, ...notFoundRoutes];
  }

  if (user?.isInAdministratorRole) {
    return [
      ...commonRoutes,
      ...adminRoutes,
      ...recordScansRoutes,
      ...recordLogsRoutes,
      ...recordServerLogsRoutes,
      ...enableReportsRoutes,
      ...enableAlertsRoutes,
      ...enableADManagementRoutes,
      ...enableRadiusManagementRoutes,
      ...enableWindowsManagementRoutes,
      ...customErrorRoutes,
      ...notFoundRoutes,
    ];
  }

  const routes = [
    ...filterAllowedRoutesByRoles(commonRoutes, user?.access),
    ...filterAllowedRoutesByRoles(adminRoutes, user?.access),
    ...filterAllowedRoutesByRoles(recordScansRoutes, user?.access),
    ...filterAllowedRoutesByRoles(recordLogsRoutes, user?.access),
    ...filterAllowedRoutesByRoles(recordServerLogsRoutes, user?.access),
    ...filterAllowedRoutesByRoles(enableReportsRoutes, user?.access),
    ...filterAllowedRoutesByRoles(enableAlertsRoutes, user?.access),
    ...filterAllowedRoutesByRoles(enableADManagementRoutes, user?.access),
    ...filterAllowedRoutesByRoles(enableRadiusManagementRoutes, user?.access),
    ...filterAllowedRoutesByRoles(enableWindowsManagementRoutes, user?.access),
    ...filterAllowedRoutesByRoles(customErrorRoutes, user?.access),
    ...filterAllowedRoutesByRoles(notFoundRoutes, user?.access),
  ];

  // Don't forget to add route's path to sidebarRoutes array if the route is going to be accessed from sidebar.

  return routes;
};

export const getTitleForPath = (
  pagePath,
  settings = defaultSettings,
  user = { isInAdministratorRole: false }
) => {
  const routes = getRoutes(settings, user);
  return (
    routes.find((route) => route.path.toLowerCase() === pagePath.toLowerCase())
      ?.title ?? ""
  );
};

function Routes() {
  const { user } = useLoggedInUser();
  const { requiresLicense } = useLayout();
  const [routes, setRoutes] = React.useState(getRoutes(defaultSettings));
  const { settings } = useLayout();

  React.useEffect(() => {
    if (!settings) {
      return;
    }
    const newRoutes = getRoutes(settings, user, requiresLicense);
    setRoutes(newRoutes);
  }, [settings, user, requiresLicense]);

  if (!user) {
    return null;
  }

  return (
    <Suspense fallback={<LoaderWithText text="Loading page..." />}>
      <Switch>
        {routes.map((route) => (
          <PrivateRoute key={route.path} {...route}></PrivateRoute>
        ))}
      </Switch>
    </Suspense>
  );
}

export default Routes;
