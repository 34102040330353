import { GetLoggedInUserRoles } from "../../DataService/Account";

export const checkUserPageAccessDetails = (roles) => {
  if (!roles) {
    throw new Error("Pass roles to get access details.");
  }

  let accessByPage = {};
  roles.forEach(({ name, canView, canEdit, canAdmin, canDelete, canAdd }) => {
    let currentAccess = accessByPage[name];
    if (!currentAccess) {
      accessByPage[name] = {
        canView,
        canEdit,
        canAdmin,
        canDelete,
        canAdd,
      };
    } else {
      accessByPage[name] = {
        canView: accessByPage[name].canView || canView,
        canEdit: accessByPage[name].canEdit || canEdit,
        canAdmin: accessByPage[name].canAdmin || canAdmin,
        canDelete: accessByPage[name].canDelete || canDelete,
        canAdd: accessByPage[name].canAdd || canAdd,
      };
    }
  });

  return accessByPage;
};
export const checkUserAccess = async (page = "", roles = []) => {
  try {
    const roles = await GetLoggedInUserRoles();

    let canViewPage = false;
    let canEditPage = false;

    roles.forEach((role) => {
      const { roles } = role;
      const controllerForPage = roles.find(
        (x) => x.name.toLowerCase() === page.toLowerCase()
      );

      if (controllerForPage) {
        const { canView, canEdit } = controllerForPage;
        canViewPage = canViewPage || canView;
        canEditPage = canEditPage || canEdit;
      }
    });

    return {
      canView: canViewPage,
      canEdit: canEditPage,
    };
  } catch (error) {
    console.log("checkUserAccess error: ", error);
    throw new Error(error);
  }
};
