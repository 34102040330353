import { Button, Tooltip } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import TaskCard from "./TaskCard";

function FilterLogsTask({ task, settings }) {
  const history = useHistory();

  const isLogsEnabled = settings?.RecordLogs?.isAllowed;

  const handleAddButtonClick = (e) => {
    history.push("/logs/client-logs");
  };

  const handleTaskFinish = () => {
    // if (searchParams.has("open") && searchParams.get("open") === "addalert") {
    //   history.replace("/alerts/manage");
    // }
  };

  return (
    <React.Fragment>
      <TaskCard
        task={task}
        title={"Filter Audit Logs"}
        handleTaskFinish={handleTaskFinish}
        actions={[
          <Tooltip
            key="add"
            title={
              !isLogsEnabled ? "The Client Audit logs feature is disabled." : ""
            }
          >
            <Button onClick={handleAddButtonClick} disabled={!isLogsEnabled}>
              Client Audit Logs
            </Button>
          </Tooltip>,
        ]}
      >
        <p>
          Run various filters for audit logs collected from GateKeeper enabled
          computers.
        </p>
        {!isLogsEnabled ? (
          <p>
            You need to enable <span className="bold">Client audit logs</span>{" "}
            feature from{" "}
            <Link to="/administration#hubFeatures">Administration</Link> page
            before accessing the auditing information.
          </p>
        ) : null}
      </TaskCard>
    </React.Fragment>
  );
}

export default FilterLogsTask;
