import {
  clearInStorage,
  saveInStorage,
  getFromStorage,
} from "../components/shared/utils/storage";
import Axios from "axios";

export const modifyAxiosError = (axiosError = new Error()) => {
  if (!axiosError.response) {
    return axiosError;
  }

  let error = axiosError.response;
  error.responseText = error?.data ?? "";
  error.message = error?.data ?? "";
  return error;
};

let refreshTokenRequest = null;

/**
 * Old calls to jQuery used to receive stringified data object. Axios needs a JS object.
 * So re-create js object from stringified object to keep backward-compatibility until full refactor.
 *
 * @param {*} data
 * @returns
 */
export const parseBody = (data) => {
  try {
    // Parse if data is a json string
    const parsedData = JSON.parse(data);
    return parsedData;
  } catch (error) {
    // console.error("error:", error);
    // Already a JS object
    return data;
  }
};

export const clearStorageAndLogout = async () => {
  await clearInStorage("gk_tokens");
  await clearInStorage("gk_user");
  await clearInStorage("gk_hasFeatureModalBeenShown");
  await clearInStorage("zd_hasOpened");
  await clearInStorage("selectedOrganizationId");

  if (!window.location.pathname.toLowerCase().includes("/login")) {
    window.location.replace("/login");
  }
};

export const getNewTokens = async (tokens) => {
  try {
    const { token, refreshToken } = tokens;
    const bearerToken = token.jwt;

    if (!refreshTokenRequest) {
      refreshTokenRequest = Axios({
        url: "/refreshToken",
        method: "POST",
        data: {
          token: bearerToken,
          refreshToken: refreshToken,
        },
      });
    }

    const response = await refreshTokenRequest;
    if (!response || !response.data) {
      throw new Error("No reponse received for tokens request.");
    }
    const newTokens = response.data;
    console.log("Got new tokens");
    await saveInStorage("gk_tokens", newTokens);
    return newTokens;
  } catch (oldError) {
    const error = modifyAxiosError(oldError);
    console.error("error:", error);
    // If even refresh tokens fail, go to login.
    // Error 400 to fix "refresh token not found in the data store error"
    if (
      error.status === 401 ||
      error.status === 400 ||
      (error.status === 404 && error?.config?.url === "/refreshToken")
    ) {
      await clearStorageAndLogout();
    }
  } finally {
    refreshTokenRequest = null;
  }
};

export const getToken = async () => {
  let tokens = await getFromStorage("gk_tokens");

  // No tokens in storage, clear storage and log out
  if (!tokens) {
    await clearStorageAndLogout();
    return null;
  }

  return tokens;
};
