import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, message, Tooltip } from "antd";
import { useForgotPassword } from "../../../shared/ForgotPasswordStore";
import { cleanupBackendMessage } from "../../../../Utils/ErrorMessages";
import useMountedState from "../../../shared/hooks/useMountedState";
import { ReloadOutlined } from "@ant-design/icons";

function RecoveryCodeInputContainer({ next }) {
  const [loading, setLoading] = React.useState(false);
  const [sendingCode, setSendingCode] = React.useState(false);
  const { validateRecoveryCode, sendRecoveryCodeToUser, updateKey } =
    useForgotPassword();
  const isMounted = useMountedState();
  const [code, setCode] = React.useState("");

  const handleCodeChange = (e) => {
    const { value } = e.target;
    setCode(value);
    updateKey("code", value);
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      await validateRecoveryCode(code);
      next();
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Failed to verify recovery code."
      );
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  const handleResendCode = async () => {
    try {
      setSendingCode(true);
      await sendRecoveryCodeToUser();
    } catch (error) {
      console.error("error:", error);
    } finally {
      if (isMounted()) {
        setSendingCode(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleNext();
        }}
        layout="vertical"
        wrapperCol={{ span: 14, offset: 6 }}
      >
        <Form.Item
          label={<span className="bold all-center">Recovery Code</span>}
          htmlFor="codeEmail"
          className="mb-2"
        >
          <Input.Group size="large">
            <Input
              value={code}
              onChange={handleCodeChange}
              id="codeEmail"
              autoComplete={"new-username"}
              autoFocus
              style={{ width: "calc(100% - 40px)", textAlign: "center" }}
            />{" "}
            <Tooltip title="Resend">
              <Button
                icon={<ReloadOutlined />}
                size="large"
                onClick={handleResendCode}
              />
            </Tooltip>
          </Input.Group>
        </Form.Item>
      </Form>
      {/* <Col span={12} offset={6} className="mt-0 mb-0">
        <div className="d-flex justify-end">
          <Button type="link" onClick={handleResendCode} className="pr-0">
            Resend
          </Button>
        </div>
      </Col> */}
      <div className="h-center mt-1">
        <Button onClick={handleNext} type="primary" style={{ width: "160px" }}>
          {sendingCode ? "Sending Code" : loading ? "Validating" : "Next"}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default RecoveryCodeInputContainer;
