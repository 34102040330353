import React from "react";
import "antd/dist/antd.less";
import "./styles/base.scss";
import GKLayout from "./components/GKLayout";

const noop = () => {};

if (process.env.NODE_ENV === "production") {
  console.log("Disabled console.log in production.");
  console.log = noop;
}

function App() {
  return (
    <div className="App">
      <GKLayout />
    </div>
  );
}

export default App;
