import React from "react";
import { Button, Card, Col, Row } from "antd";
import { useApiCall } from "use-api-call";
import { GetServerLogos } from "../../DataService/Server";
import gkBlueHelmet from "../../assets/images/gklogo-helmet-blue.png";
import HubLoginFormStep1 from "./HubLoginFormStep1";
import TwoFactorInputStep3 from "./TwoFactorInputStep3";
import TwoFactorInputStep2 from "./TwoFactorInputStep2";

const LoginStepsContainer = ({ props }) => {
  const [smallLogoSrc, setSmallLogoSrc] = React.useState(null);

  const { currentStep, deliveryType } = props;
  const { data: logoData } = useApiCall(GetServerLogos, {
    invokeOnMount: true,
  });

  React.useEffect(() => {
    if (!logoData) {
      return;
    }

    const { smallLogo } = logoData;
    if (!smallLogo) {
      setSmallLogoSrc(gkBlueHelmet);
      return;
    }
    const _smallLogo = "data:image/bmp;base64," + smallLogo;

    setSmallLogoSrc(_smallLogo);
  }, [logoData]);

  const stepProps = {
    ...props,
  };

  const deliveryDescription = {
    authenticator:
      "Please enter the one-time passcode generated by your authenticator app.",
    phone: "Please  enter the one-time-passcode sent to your phone",
    backup: "Please enter the backup code for two-factor authentication.",
  };
  const steps = [
    {
      title: "Sign-In to GateKeeper Hub",
      description: "",
      content: <HubLoginFormStep1 {...stepProps} />,
    },
    {
      title: "Two-Factor Authentication Required",
      description:
        "Please choose the two-factor authenticator you would like to use.",
      content: <TwoFactorInputStep2 {...stepProps} />,
    },
    {
      title: "Enter your One-Time Passcode",
      description: deliveryDescription[deliveryType],
      content: <TwoFactorInputStep3 {...stepProps} />,
    },
  ];

  return (
    <React.Fragment>
      <Card
        title={null}
        style={{ maxWidth: "480px", minWidth: "300px" }}
        actions={
          currentStep === 0
            ? null
            : [
                <Button key="returnToSignIn" type="link" href="/login">
                  Return to Sign-In
                </Button>,
              ]
        }
      >
        <Row>
          <Col span={24}>
            <div className="all-center">
              <img src={smallLogoSrc} alt="GateKeeper mini logo" width={80} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="pb-1 mt-3 bold all-center">
              <span
                style={{
                  fontSize: "1.2em",
                }}
              >
                {steps[currentStep].title}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="pb-1 mt-1 mb-1">
              <span>{steps[currentStep].description}</span>
            </div>
          </Col>
        </Row>

        <div>{steps[currentStep].content}</div>
      </Card>
    </React.Fragment>
  );
};

export default LoginStepsContainer;
