import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Button, Collapse } from "antd";
import PropTypes from "prop-types";
import descriptions from "./descriptions";
import GKVideoPlayerModal from "../shared/UI/GKVideoPlayer";
const { Panel } = Collapse;
function PageDescription({ page }) {
  const [videoItem, setVideoItem] = React.useState(null);

  const description = descriptions[page];
  if (!description) {
    return null;
  }
  const { info, videoURL, title } = description;
  if (!info) {
    return null;
  }
  const showVideo = () => (
    <Button
      size="small"
      type="primary"
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        setVideoItem({ videoURL, title, info });
      }}
    >
      Watch a Video
    </Button>
  );
  return (
    <React.Fragment>
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            style={{ color: "#407CCA" }}
          />
        )}
      >
        <Panel
          key={"1"}
          header={
            <span className="bold align-center" style={{ color: "#407CCA" }}>
              About this page
            </span>
          }
          extra={videoURL ? showVideo() : null}
        >
          {info}
        </Panel>
      </Collapse>
      <GKVideoPlayerModal videoItem={videoItem} setVideoItem={setVideoItem} />
    </React.Fragment>
  );
}

PageDescription.propTypes = {
  page: PropTypes.string.isRequired,
};

export default PageDescription;
