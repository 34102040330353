import React from "react";
import useSWR from "swr";
import { getFetcher } from "../../DataService/Common";
import { useHubConfiguration } from "../shared/HubConfigurationStore";

export const SETUP_TASKS = {
  ADD_ADMIN: "ADD_ADMIN",
  ADD_LICENSE: "ADD_LICENSE",
  ADD_USER: "ADD_USER",
  INSTALL_CLIENT: "INSTALL_CLIENT",
  USE_GATEKEEPER: "USE_GATEKEEPER",
  CHANGE_FEATURES: "CHANGE_FEATURES",
  CHANGE_GROUP_SETTINGS: "CHANGE_GROUP_SETTINGS",
  USE_REPORTS: "USE_REPORTS",
  ADD_SCHEDULED_REPORT: "ADD_SCHEDULED_REPORT",
  ADD_ALERT: "ADD_ALERT",
  ADD_ACTIVE_DIRECTORY: "ADD_ACTIVE_DIRECTORY",
  FILTER_AUDIT_LOGS: "FILTER_AUDIT_LOGS",
};

const initialTasks = {
  INSTALL_CLIENT: {
    name: "INSTALL_CLIENT",
    finished: false,
    skipped: false,
  },
  ADD_USER: {
    name: "ADD_USER",
    finished: false,
    skipped: false,
  },
  USE_GATEKEEPER: {
    name: "USE_GATEKEEPER",
    finished: false,
    skipped: false,
  },
  CHANGE_GROUP_SETTINGS: {
    name: "CHANGE_GROUP_SETTINGS",
    finished: false,
    skipped: false,
  },
  CHANGE_FEATURES: {
    name: "CHANGE_FEATURES",
    finished: false,
    skipped: false,
  },
  ADD_ADMIN: {
    name: "ADD_ADMIN",
    finished: false,
    skipped: false,
  },
  ADD_LICENSE: {
    name: "ADD_LICENSE",
    finished: false,
    skipped: false,
  },
  FILTER_AUDIT_LOGS: {
    name: "FILTER_AUDIT_LOGS",
    finished: false,
    skipped: false,
  },
  ADD_ALERT: {
    name: "ADD_ALERT",
    finished: false,
    skipped: false,
  },
  USE_REPORTS: {
    name: "USE_REPORTS",
    finished: false,
    skipped: false,
  },
  ADD_SCHEDULED_REPORT: {
    name: "ADD_SCHEDULED_REPORT",
    finished: false,
    skipped: false,
  },
  ADD_ACTIVE_DIRECTORY: {
    name: "ADD_ACTIVE_DIRECTORY",
    finished: false,
    skipped: false,
  },
};

const SetupHubContext = React.createContext({
  task: initialTasks,
});

function SetupHubProvider(props) {
  const { config, updateConfig } = useHubConfiguration();
  const [remainingTasks, setRemainingTasks] = React.useState(0);
  const [tasks, setTasks] = React.useState(initialTasks);
  const [settings, setSettings] = React.useState({});

  const { data: settingsData, error: settingsError } = useSWR(
    `/hub/api/v4/Server/Settings`,
    getFetcher
  );

  const serverSettingConfig = config?.find((x) => x.name === "ServerSetup");

  React.useEffect(() => {
    if (!config || !settingsData) {
      return;
    }

    let tasks;
    const dbValue = config?.find((x) => x.name === "ServerSetup")?.value;
    if (!dbValue) {
      return;
    }
    try {
      tasks = JSON.parse(dbValue);
      setTasks(tasks);
    } catch (error) {
      console.error("error: ", error);
      tasks = dbValue;
    }

    setTasks(tasks);
  }, [config, settingsData]);

  const updateTaskFinished = (TASK_NAME) => {
    let updatedSetup = {
      ...tasks,
      [TASK_NAME]: {
        ...tasks[TASK_NAME],
        finished: true,
      },
    };
    updateConfig(serverSettingConfig.id, JSON.stringify(updatedSetup));
  };

  const updateTaskSkipped = (TASK_NAME) => {
    let updatedSetup = {
      ...tasks,
      [TASK_NAME]: {
        ...tasks[TASK_NAME],
        skipped: true,
      },
    };
    updateConfig(serverSettingConfig.id, JSON.stringify(updatedSetup));
  };

  React.useEffect(() => {
    if (!settingsData || settingsError) {
      return;
    }

    const SETTINGS_MAP = {};

    settingsData.forEach((setting) => {
      SETTINGS_MAP[setting.settingName] = { ...setting };
    });

    setSettings(SETTINGS_MAP);

    // const hasFeatures =
    //   settingsData?.filter(
    //     (feature) => feature.isFeatureEnabled && feature.isAllowed
    //   ).length > 0 ?? 0;

    // setTasks({
    //   ...tasks,
    //   [SETUP_TASKS.CHANGE_FEATURES]: {
    //     finished: hasFeatures,
    //     optional: false,
    //   },
    // });
  }, [settingsData, settingsError]);

  React.useEffect(() => {
    const unfinishedTaskCount = Object.values(tasks).reduce((count, task) => {
      const isDone = task.finished || task.skipped;

      return isDone ? count : count + 1;
    }, 0);

    setRemainingTasks(unfinishedTaskCount);
  }, [tasks]);

  const value = React.useMemo(
    () => ({
      remainingTasks,
      tasks,
      settings,
      updateTaskFinished,
      updateTaskSkipped,
    }),
    [remainingTasks, settings, tasks, updateTaskFinished, updateTaskSkipped]
  );

  return <SetupHubContext.Provider value={value} {...props} />;
}

function useSetupHub() {
  const context = React.useContext(SetupHubContext);

  if (!context) {
    throw new Error("useSetupHub must be used within SetupHubProvider");
  }

  return context;
}

export { SetupHubProvider, useSetupHub };
