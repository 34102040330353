import React from "react";
import { Card } from "antd";
import FirstUserSteps from "./FirstUserSteps/FirstUserSteps";
import { Route, Switch, useHistory } from "react-router-dom";
import RegistrationSuccessSteps from "./RegistrationSuccess/RegistrationSuccessSteps";
import { GetActiveUsesAndLicensesCount } from "./../../DataService/Server";
import { useApiCall } from "use-api-call";

function GKFirstUseLayout(props) {
  const { data, loading } = useApiCall(GetActiveUsesAndLicensesCount, {
    invokeOnMount: true,
  });

  const history = useHistory();

  React.useEffect(() => {
    if (!data) {
      return;
    }

    if (data.usersCount < 1) {
      history.push("/register");
    }
  }, [data]);

  if (loading) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        className="all-center login-background "
        style={{ height: "100vh", width: "100vw" }}
      >
        <Card
          className="w-50 overflow-auto"
          style={{ maxWidth: 1100 }}
          bodyStyle={{ height: "100%" }}
        >
          <Switch>
            <Route path="/register" exact component={FirstUserSteps} />
            <Route
              exact
              path="/registration-success"
              render={(routeProps) => (
                <RegistrationSuccessSteps {...{ ...routeProps, ...props }} />
              )}
            />
            <Route path="*" exact component={FirstUserSteps} />
          </Switch>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default GKFirstUseLayout;
