import React from "react";
import * as signalR from "@microsoft/signalr";
import {
  getToken,
  getNewTokens,
  clearStorageAndLogout,
} from "./../../DataService/data-service-utils";
import { notification, message } from "antd";
import dayjs from "../shared/utils/dayjsUtils";

const SignalRContext = React.createContext({});

const FLASH_NOTIFICATION_MAP = {
  18: "info",
  19: "warn",
  20: "error",
};

const FLASH_NOTIFICATION_STYLES = {
  18: {
    background: "#FAFAFA",
    color: "black",
    border: "1px solid #407CCA",
  },
  19: {
    background: "#FAFAFA",
    color: "black",
    border: "1px solid #FFC300",
  },
  20: {
    background: "#FAFAFA",
    color: "black",
    border: "1px solid #E53935",
  },
};

const getAccessToken = async () => {
  const tokens = await getToken();
  const { token } = tokens;
  const bearerToken = token?.jwt;
  const expiryTime = token?.exp;
  const expiryTimeUTC = dayjs.unix(expiryTime).utc();
  const currentTimeUTC = dayjs().utc();

  if (currentTimeUTC.isSameOrAfter(expiryTimeUTC)) {
    const { token } = await getNewTokens(tokens);
    const newBearerToken = token?.jwt;
    return newBearerToken;
  }
  return bearerToken;
};

function SignalRProvider(props) {
  const connection = React.useRef(null);
  const timer = React.useRef(null);
  React.useEffect(() => {
    connection.current = new signalR.HubConnectionBuilder()
      .withUrl(
        process.env.NODE_ENV === "production"
          ? "/UserHub"
          : "http://test1.local:54081/UserHub",
        {
          accessTokenFactory: getAccessToken,
        }
      )
      .configureLogging(signalR.LogLevel.Information)
      .build();

    if (!connection.current) {
      console.error("Failed to build SignalR hub connection.");
      return;
    }
    async function start() {
      try {
        await connection.current.start();
      } catch (error) {
        console.error("SignalR start error: ", error.message);
        if (timer.current) {
          clearTimeout(timer.current);
          timer.current = null;
        }
        timer.current = setTimeout(() => {
          start();
        }, 5000);
      }
    }

    connection.current.on("NotificationReceived", (hubNotification) => {
      const { type: Type, message: Message } = hubNotification;

      notification[FLASH_NOTIFICATION_MAP[Type] ?? "info"]({
        key: FLASH_NOTIFICATION_MAP[Type] ?? "info",
        message: Message,
        placement: "bottomRight",
        duration: 5,
        style: FLASH_NOTIFICATION_STYLES[Type],
      });
    });

    connection.current.on("Logout", (data) => {
      const reasonForLogout =
        data?.message ?? "GateKeeper Hub is going to log you out in 5 seconds.";
      message.info(reasonForLogout);
      let timeout = setTimeout(() => {
        clearTimeout(timeout);
        clearStorageAndLogout();
      }, 5000);
    });

    connection.current.onclose(async (error) => {
      console.log("%cSignalRConnection stopped", "color: red");
      await start();
      console.log(error);
    });
    connection.current.onreconnected(() => {
      console.log("%cSignalRConnection reconnected", "color: green");
    });

    start();

    return () => {
      console.log(
        "%SignalR Provider unmounting. Attempting connection close.",
        "color: red"
      );

      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }

      if (connection.current) {
        connection.current
          .stop()
          .then(() => {
            console.log("%cSignalR Connection stopped.", "color: orange");
          })
          .catch((err) => console.log("SignalR stop error: ", err.message));
      }
    };
  }, []);

  const value = React.useMemo(() => ({}), []);
  return <SignalRContext.Provider value={value} {...props} />;
}

function useSignalR() {
  const context = React.useContext(SignalRContext);

  if (!context) {
    throw new Error("useSignalR must be used within SignalRProvider");
  }

  return context;
}

export { SignalRProvider, useSignalR };
