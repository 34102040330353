import React from "react";
import { Badge, Button, Drawer, Tooltip } from "antd";
import { useSetupHub } from "./SetupHubStore";
import SetupHubTasks from "./SetupHubTasks";

const defaultDrawerData = {
  title: "",
  type: null,
  drawerType: "row",
  defaultOpenPage: "profile",
  records: [
    {
      id: "",
    },
  ],
};

function SetupHub({ sidebarCollapsed }) {
  const { remainingTasks } = useSetupHub();

  const [drawerData, setDrawerData] = React.useState(defaultDrawerData);

  const openDrawer = () => {
    setDrawerData({
      ...defaultDrawerData,
      type: "show-setup-tasks",
      title: "Setup GateKeeper Hub",
    });
  };

  const closeDrawer = () => {
    setDrawerData(defaultDrawerData);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    openDrawer();
  };

  if (remainingTasks === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        type="link"
        style={{
          color: "white",
          fontWeight: 600,
          marginTop: 10,
          textAlign: "left",
        }}
        onClick={handleClick}
        block
      >
        {sidebarCollapsed ? (
          <Tooltip title={"Setup GateKeeper Hub"} placement="right">
            <span className="w-100 all-center text-center">
              <Badge count={remainingTasks ?? 0} />
            </span>
          </Tooltip>
        ) : (
          <span className="w-100 d-flex flex-column">
            <span className="w-100 h-100 d-flex align-center">
              <Badge count={remainingTasks ?? 0} />
              <span className="ml-1">Setup GateKeeper Hub</span>
            </span>
            <span className="ml-3" style={{ color: "#999", fontSize: "0.9em" }}>
              {remainingTasks} {remainingTasks === 1 ? "task" : "tasks"}{" "}
              remaining
            </span>
          </span>
        )}
      </Button>

      <Drawer
        className="force-overflow-auto" // Because overflow disabled long time ago for drawers for some reasons. So force it to show.
        title={drawerData.title}
        visible={drawerData.type !== null}
        onClose={() => closeDrawer()}
        width={450}
        placement="left"
      >
        <SetupHubTasks />
      </Drawer>
    </React.Fragment>
  );
}

export default SetupHub;
