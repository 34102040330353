import React from "react";
import { Button } from "antd";
// eslint-disable-next-line import/no-unresolved
import gkBlueHelmet from "../../../assets/images/gklogo-helmet-blue.png";

function FirstUserWelcome({ next }) {
  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-between align-center text-center h-100">
        <div>
          <img alt="GateKeeper logo" src={gkBlueHelmet} width="60" />
          <div className="p-1"></div>
          <h2>WELCOME TO GATEKEEPER HUB</h2>
          <div>
            GateKeeper Hub is the centralized management console for your
            GateKeeper deployment.
          </div>
          <div className="p-1"></div>
          <div>Let&apos;s set up the Hub for your company.</div>
        </div>
        <div className="my-2">
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FirstUserWelcome;
