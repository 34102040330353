import React from "react";
import { Button, Typography, Row, Col, Divider, message } from "antd";
import { useHistory } from "react-router-dom";
import useMountedState from "../../shared/hooks/useMountedState";
import { cleanupBackendMessage } from "../../../Utils/ErrorMessages";
import { useAuthentication } from "../../shared/AuthenticationStore";
import { FirstTimeSetup } from "../../../DataService/Account";

function FirstUserSummary({ prev, formProps }) {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    username,
    password,
    confirmPassword,
  } = formProps;

  const isMounted = useMountedState();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { authenticate } = useAuthentication();

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const dataToBackend = {
        firstName: firstName.value,
        lastName: lastName.value,
        userName: username.value,
        password: password.value,
        confirmPassword: confirmPassword.value,
        phoneNumber: phoneNumber.value,
        email: email.value,
      };

      const response = await FirstTimeSetup(dataToBackend);
      if (response) {
        await authenticate(username.value, password.value);
        history.push("/registration-success");
      } else {
        throw new Error("Failed to get tokens after registration.");
      }
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ?? "Failed to register user."
      );
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  const getRow = (label, value, align = "left") => {
    return (
      <div>
        <Row gutter={8}>
          <Col span={10} style={{ textAlign: align }}>
            <span className="header-item">{label}:</span>
          </Col>
          <Col span={10}>{value}</Col>
        </Row>
        <div className="p-1"></div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-between align-center h-100">
        <div className="w-100">
          <div className="d-flex justify-center">
            <Typography.Title level={4}>
              Verify and Save Administrator
            </Typography.Title>
          </div>
          <div
            className="d-flex flex-column"
            style={{ marginLeft: "50%", transform: "translateX(-30%)" }}
          >
            {getRow("First name", firstName.value)}
            {getRow("Last name", lastName.value)}
            {getRow("Email", email.value)}
            {getRow("Phone number", phoneNumber.value)}
            {getRow("Username", username.value)}
          </div>
        </div>
        <div className="mb-2">
          <Button type="primary" onClick={() => prev()}>
            Previous
          </Button>
          <Divider type="vertical" />
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            Submit
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FirstUserSummary;
