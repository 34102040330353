import { ajax, requestWithNoAuth } from "./Common";

export async function AccountConfirmation(data) {
  return await ajax(
    {
      url: `/hub/api/v4/Accounts/AccountConfirmation`,
      contentType: `application/json;charset=utf-8`,
      method: `POST`,
      data,
    },
    { needsToken: false }
  );
}
export async function FirstTimeSetup(data) {
  return await ajax(
    {
      url: `/hub/api/v4/Accounts/Setup`,
      contentType: `application/json;charset=utf-8`,
      method: `POST`,
      data,
    },
    { needsToken: false }
  );
}
export async function VerifyForgotPasswordEmail(data) {
  return await ajax(
    {
      url: `/hub/api/v4/Accounts/VerifyIdentity`,
      method: `POST`,
      data,
    },
    { needsToken: false }
  );
}
export async function SendForgotPasswordCode(data) {
  return await ajax(
    {
      url: `/hub/api/v4/Accounts/SendForgotPasswordCode`,
      contentType: `application/json;charset=utf-8`,
      method: `POST`,
      data,
    },
    { needsToken: false }
  );
}
export async function VerifyResetPasswordCode(data) {
  return await ajax(
    {
      url: `/hub/api/v4/Accounts/ValidatePasswordResetCode`,
      contentType: `application/json;charset=utf-8`,
      method: `POST`,
      data,
    },
    { needsToken: false }
  );
}
export async function ResetPassword(data) {
  return await ajax(
    {
      url: `/hub/api/v4/Accounts/ResetPassword`,
      contentType: `application/json;charset=utf-8`,
      method: `POST`,
      data,
    },
    { needsToken: false }
  );
}
export async function Login(userCredentials) {
  return await requestWithNoAuth({
    url: `/token`,
    method: `POST`,
    data: userCredentials,
  });
}

export async function DemoLogin(userCredentials) {
  return await requestWithNoAuth({
    url: `/demotoken`,
    method: `POST`,
    data: userCredentials,
  });
}

export async function Send2FACode(data) {
  return await requestWithNoAuth({
    url: `/send2fa`,
    method: `POST`,
    data,
  });
}
export async function Login2FA(data) {
  return await requestWithNoAuth({
    url: `/2fatoken`,
    method: `POST`,
    data,
  });
}
export async function Logout(data) {
  return await ajax({
    url: `/logout`,
    method: `POST`,
    data,
  });
}
export async function RefreshToken(data) {
  return await ajax({
    url: `/refreshToken`,
    contentType: `application/json;charset=utf-8`,
    method: `POST`,
    data,
  });
}
export async function Setup2FALoginScreen(data) {
  return await requestWithNoAuth({
    url: `/force2FA/setup`,
    method: `POST`,
    data,
  });
}
export async function Verify2FALoginScreen(data) {
  return await requestWithNoAuth({
    url: `/force2FA/verify`,
    method: `POST`,
    data,
  });
}
export async function GetNotificationsByUser(userId) {
  return await ajax({
    url: `/hub/api/v4/My/Notifications`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function SetNotificationsSeen(userId, ids) {
  return await ajax({
    url: `/hub/api/v4/My/Notifications`,
    contentType: `application/json;charset=utf-8`,
    method: `Delete`,
    data: ids,
  });
}
export async function GetLoggedInUserDetails(userId) {
  return await ajax({
    url: `/hub/api/v4/My`,
    method: `GET`,
  });
}
export async function UpdateLoggedInUser(userId, obj) {
  return await ajax({
    url: `/hub/api/v4/My`,
    contentType: `application/json;charset=utf-8`,
    method: `PUT`,
    data: obj,
  });
}
export async function UpdateLoggedUserPassword(userId, oldAndNewPassword) {
  return await ajax({
    url: `/hub/api/v4/My/Password`,
    contentType: `application/json;charset=utf-8`,
    method: `PUT`,
    data: oldAndNewPassword,
  });
}

export async function GetLoggedInUserRoles() {
  return await ajax({
    url: `/hub/api/v4/My/Roles`,
    method: `GET`,
  });
}
export async function GetAllHelpKeys() {
  return [`groups`, `groups-computers`];
}
export async function GetHelpForKey(key) {
  return await ajax({
    url: `/hub/api/v4/Services/help/keys?key=${key}`,
    method: `GET`,
    dataType: `json`,
  });
}

export async function SetUp2FA(data) {
  return await ajax({
    url: `/hub/api/v4/My/2FA/setup`,
    contentType: `application/json;charset=utf-8`,
    method: `PUT`,
    data,
  });
}
export async function Enable2FA(data) {
  return await ajax({
    url: `/hub/api/v4/My/2FA/enable`,
    contentType: `application/json;charset=utf-8`,
    method: `PUT`,
    data,
  });
}
export async function Disable2FA(data) {
  return await ajax({
    url: `/hub/api/v4/My/2FA/disable`,
    contentType: `application/json;charset=utf-8`,
    method: `PUT`,
    data,
  });
}
export async function GenerateBackupCodes(data) {
  return await ajax({
    url: `/hub/api/v4/My/2FA/newbackupcodes`,
    contentType: `application/json;charset=utf-8`,
    method: `PUT`,
    data,
  });
}
export async function GetBackupCodes(data) {
  return await ajax({
    url: `/hub/api/v4/My/2FA/backupcodes`,
    contentType: `application/json;charset=utf-8`,
    method: `PUT`,
    data,
  });
}
