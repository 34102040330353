import { useRef, useEffect, useCallback } from "react";

export default function useMountedState() {
  const ref = useRef(false);
  const state = useCallback(() => ref.current, []);

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  });

  return state;
}
