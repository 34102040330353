import React from "react";
import { Button, Divider, message, Table, Tooltip } from "antd";
import { sortByAlphabet } from "../shared/utils/tableSorters";
import { SetNotificationsSeen } from "../../DataService/Account";
import { cleanupBackendMessage } from "../../Utils/ErrorMessages";
import { useNotifications } from "../shared/NotificationsStore";
import { useAuthentication } from "../shared/AuthenticationStore";

const EmptyActionButton = () => {
  return (
    <Button
      className="actionButton"
      type="link"
      style={{ visibility: "hidden" }}
    >
      Empty
    </Button>
  );
};

const gotoPage = (page) => {
  window.location.href = `${page}`;
};

const hasNotificationAction = (record) => {
  return (
    record.notificationType &&
    [
      "clientsoftwarerelated",
      "jwttokenrelated",
      "nondomaincomputer",
      "computersrelated",
      "computer403",
      "licenserelated",
      "clientalert",
      "hubalert",
      "systemalert",
      "lowbattery",
    ].includes(record.notificationType.toLowerCase())
  );
};

const getRelatedPageForNotification = (record) => {
  const { notificationType } = record;
  if (notificationType === "ClientSoftwareRelated") {
    return { title: "Downloads", route: "/downloads" };
  } else if (
    notificationType === "JwtTokenRelated" ||
    notificationType === "NonDomainComputer" ||
    notificationType === "ComputersRelated" ||
    notificationType === "Computer403"
  ) {
    return { title: "Computers", route: "/computers" };
  } else if (notificationType === "LicenseRelated") {
    return { title: "Administration", route: "/administration" };
  } else if (
    notificationType === "ClientAlert" ||
    notificationType === "HubAlert" ||
    notificationType === "SystemAlert"
  ) {
    return { title: "Alert Logs", route: "/alerts/logs" };
  } else if (notificationType === "LowBattery") {
    return { title: "GateKeeper Users", route: "/users" };
  }
  return null;
};

function NotificationsTable() {
  const { notifications, updateNotifications } = useNotifications();
  const [dataSource, setDataSource] = React.useState([]);
  const { loggedInUser } = useAuthentication();

  React.useEffect(() => {
    if (notifications.length === 0) {
      setDataSource([]);
    }
    const initNotifications = [...notifications];
    const unseenNotifications = initNotifications.filter((x) => !x.seen);
    setDataSource(unseenNotifications);
  }, [notifications]);

  const handleDeleteNotifications = async (records) => {
    try {
      const ids = records.map((x) => x.id);
      const dataToSend = JSON.stringify(ids);
      await SetNotificationsSeen(loggedInUser.id, dataToSend);
      updateNotifications();
      message.success("Notifications marked as read.");
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Error marking notifications as read."
      );
    }
  };

  const handleGoToPage = (record) => {
    const relatedPage = getRelatedPageForNotification(record);
    if (!relatedPage) {
      return;
    }

    const { route } = relatedPage;
    gotoPage(route);
  };

  const handleMarkAsRead = async (record) => {
    try {
      handleDeleteNotifications([record]);
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Error updating notification status."
      );
    }
  };

  const columns = [
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      sorter: (a, b) => sortByAlphabet(a, b, "message"),
      width: "60%",
      render: (text, record) => {
        const { message } = record;
        return <>{message}</>;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: "40%",
      render: (text, record) => {
        const relatedPage = getRelatedPageForNotification(record);
        return (
          <span className={"actionButtonsColumn"}>
            {hasNotificationAction ? (
              <>
                {relatedPage ? (
                  <Tooltip
                    title={`The action item for this notification exists in the ${relatedPage.title} page. Please use this link to directly go there.`}
                  >
                    <Button
                      className="actionButton"
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGoToPage(record);
                      }}
                    >
                      Go to related page
                    </Button>
                  </Tooltip>
                ) : (
                  <EmptyActionButton />
                )}
                <Divider type="vertical" />
              </>
            ) : null}
            <Button
              className="actionButton"
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                handleMarkAsRead(record);
              }}
            >
              Mark as read
            </Button>
          </span>
        );
      },
    },
  ];

  const tableProps = {
    pagination: {
      showSizeChanger: true,
      defaultPageSize: 10,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
    size: "small",
    showHeader: false,
    scroll: {
      y: 700,
    },
  };

  return (
    <>
      <div className="d-flex justify-end">
        {notifications?.length > 0 ? (
          <Button
            type="ghost"
            onClick={() => handleDeleteNotifications(dataSource)}
          >
            Mark all as read
          </Button>
        ) : (
          <EmptyActionButton />
        )}
      </div>
      <div style={{ paddingTop: 20 }}></div>
      <Table
        rowClassName={() => "actionButtonsRow"}
        dataSource={dataSource}
        columns={columns}
        {...tableProps}
      />
    </>
  );
}

export default NotificationsTable;
