import React from "react";
import { Link } from "react-router-dom";

function SmartcardAuthenticatorInfo({ dismissAnnouncement }) {
  return (
    <React.Fragment>
      <div>
        <p>
          Near-field-communication (NFC - 13.56MHz) and Radio-Frequency Identity
          (RFID - 125kHz) cards can be used as authenticators in the GateKeeper
          Enterprise platform. This feature allows users to scan their NFC/RFID
          cards on supported NFC/RFID readers to authenticate on to their
          computers.
        </p>
        <p>
          The GateKeeper Client application must be updated to{" "}
          <strong>3.13.31</strong> or later version to enable NFC and RFID card
          authentication.
        </p>
        <p>
          Go to your <Link to="/groups">Groups page</Link> and click on the{" "}
          <strong>Global Settings</strong> button to enable NFC/RFID card as
          allowed GateKeeper Authenticators for your platform.
        </p>
        <p>
          Currently NFC cards from the <strong>MIFARE Classic</strong> family
          (1k and 4k cards) as well as RFID cards such as HID Prox Cards can be
          used as valid GateKeeper authenticators. In the future, other NFC and
          RFID cards may be added to the authenticators list.
        </p>
        <p>
          USB NFC/RFID readers that support the{" "}
          <strong>Microsoft Windows CCID protocol</strong> can be used as NFC
          readers with the GateKeeper Client application. We recommend the
          following NFC readers for use with GateKeeper:
        </p>
        <ul>
          <li>NFC: ACR122U</li>
          <li>NFC: HID Global Omnikey 5022 CL</li>
          <li>NFC & RFID: HID Global Omnikey 5427 CL</li>
          <li>NFC & RFID: Elatec TWN4 MultiTech</li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default SmartcardAuthenticatorInfo;
