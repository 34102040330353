import React from "react";
import { Link } from "react-router-dom";
function RDPLauncherInfo({ dismissAnnouncement }) {
  return (
    <React.Fragment>
      <div>
        <p>
          Launch RDP sessions and automatically log in using saved domain
          credentials from your GateKeeper profile. You can set up a RDP server
          to connect to and log in automatically once you have authenticated
          into your local computer using your GateKeeper token. The utility will
          fetch the appropriate domain credentials from your GateKeeper user
          profile, validate them against your active directory, and then launch
          the remote desktop session to authenticate you.
        </p>
        <p>
          Download the RDP Launcher utility from the{" "}
          <Link to="/downloads">Downloads</Link> page.
        </p>
      </div>
    </React.Fragment>
  );
}

export default RDPLauncherInfo;
