export const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return {
      validateStatus: "error",
      help: "Required",
    };
  }

  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  if (!re.test(phoneNumber.toString().toLowerCase())) {
    return {
      validateStatus: "error",
      help: "Invalid Phone Number",
    };
  }

  return {
    validateStatus: "success",
    help: "",
  };
};
