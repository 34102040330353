import React from "react";
import {
  GetHubUsers,
  GetRequiresLicense,
  GetServerLogos,
  GetServerSettings,
} from "../../DataService/Server";
import { useHistory } from "react-router-dom";
import { useApiCall } from "use-api-call";
import useMountedState from "./hooks/useMountedState";
import { useLoggedInUser } from "./LoggedInUserStore";
import { message } from "antd";

export const defaultSettings = {
  RecordScans: false, // Real-time scans
  RecordLogs: false, // Client Audit Logs
  RecordServerLogs: false, // Server Logs
  EnableReports: false, // Enable Reports
  EnableCustomApps: false, // Enable custom apps
  EnableAlerts: false, // Enable Alerts
  EnableADManagement: false, //Enable AD Management
  EnableWindowsManagement: false, //Enable WMI
  EnableRADIUS: false, //Enable RADIUS
};

// Use layout context for stuff that should apply for whole hub after login
const LayoutContext = React.createContext({});

function LayoutProvider(props) {
  const [bigLogoSrc, setBigLogoSrc] = React.useState(null);

  const [smallLogoSrc, setSmallLogoSrc] = React.useState(null);
  const { user } = useLoggedInUser();

  const { data: logoData } = useApiCall(GetServerLogos, {
    invokeOnMount: true,
  });
  const history = useHistory();
  const isMounted = useMountedState();

  const [requiresLicense, setRequiresLicense] = React.useState(null);
  const [settingsLoading, setSettingsLoading] = React.useState(false);
  const [settings, setSettings] = React.useState(defaultSettings);

  const updateSettings = async () => {
    try {
      setSettingsLoading(true);
      const settingsFromDb = await GetServerSettings();
      const settingsObject = defaultSettings;
      if (settingsFromDb && settingsFromDb.length > 0) {
        settingsFromDb.map((setting) => {
          settingsObject[setting.settingName] = setting.isAllowed;
        });
      }

      if (isMounted()) {
        setSettings({ ...settingsObject });
      }
    } catch (error) {
      console.error("error:", error);
    } finally {
      if (isMounted()) {
        setSettingsLoading(false);
      }
    }
  };
  const getLicenseRelatedData = async () => {
    try {
      const [requiresLicenseData] = await Promise.all([GetRequiresLicense()]);

      setRequiresLicense(requiresLicenseData?.requiresLicense ?? false);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getHubUsers = async (
    isActive = true,
    query = "",
    organizationId = ""
  ) => {
    try {
      const data = await GetHubUsers(isActive, query, organizationId);
      const dataSource =
        data?.map?.((x) => {
          const { id } = x;
          return {
            key: id,
            ...x,
          };
        }) ?? [];

      return dataSource;
    } catch (error) {
      console.log("getHubUsers error: ", error);
    }
  };

  React.useEffect(() => {
    if (!logoData) {
      return;
    }

    const { smallLogo, bigLogo } = logoData;
    const _smallLogo = "data:image/bmp;base64," + smallLogo;
    const _bigLogo = "data:image/bmp;base64," + bigLogo;

    setBigLogoSrc(_bigLogo);
    setSmallLogoSrc(_smallLogo);
  }, [logoData]);

  React.useEffect(() => {
    updateSettings();
    getLicenseRelatedData();
  }, []);

  React.useEffect(() => {
    if (requiresLicense === null || requiresLicense === false) {
      return;
    }

    if (user?.isInAdministratorRole) {
      history.push("/administration#licenses");
    } else {
      history.push("/error/license");
      message.error(
        "Your license has expired. Please contact your administrator to renew."
      );
    }
  }, [requiresLicense]);

  const value = React.useMemo(
    () => ({
      bigLogoSrc,
      setBigLogoSrc,
      smallLogoSrc,
      setSmallLogoSrc,
      requiresLicense,
      settings,
      setSettings,
      updateSettings,
      settingsLoading,
      getHubUsers,
    }),
    [
      bigLogoSrc,
      setBigLogoSrc,
      smallLogoSrc,
      setSmallLogoSrc,
      requiresLicense,
      settings,
      setSettings,
      updateSettings,
      settingsLoading,
      getHubUsers,
    ]
  );

  return <LayoutContext.Provider {...props} value={value} />;
}

const useLayout = () => {
  const context = React.useContext(LayoutContext);
  if (!context) {
    throw new Error("useLayout must be used within LayoutProvider");
  }

  return context;
};

export { LayoutProvider, useLayout };
