import React from "react";
import { Layout } from "antd";
import Sidebar from "../Sidebar/Sidebar";
import GKFooter from "../GKFooter";
import GKHeader from "../GKHeader";
import Main from "../Main/Main";
import { LayoutProvider } from "../shared/LayoutStore";
import { NotificationsProvider } from "../shared/NotificationsStore";
import { SWRConfig } from "swr";
import { getFetcher } from "../../DataService/Common";
import { SignalRProvider } from "../shared/SignalRStore";
import Timeout from "../Timeout";
import { OrganizationsProvider } from "../shared/OrganizationsStore";

const { Content, Footer, Header } = Layout;

function GKRegularLayout() {
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState(false);
  const sidebarProps = {
    sidebarCollapsed,
    setSidebarCollapsed,
  };

  return (
    <React.Fragment>
      <SWRConfig value={{ fetcher: getFetcher }}>
        <div>
          <Layout>
            <LayoutProvider>
              <OrganizationsProvider>
                <Sidebar {...sidebarProps} />
                <Layout
                  style={{
                    marginLeft: sidebarCollapsed ? 85 : 266,
                    minHeight: "100vh",
                    transition: "margin 0.2s",
                    overflow: "auto",
                  }}
                >
                  <Header
                    style={{
                      position: "fixed",
                      zIndex: 3,
                      left: sidebarCollapsed ? 80 : 266,
                      right: 0,
                      paddingLeft: 10,
                      paddingRight: 10,
                      background: "#f4f4f4",
                      borderBottom: "1px solid #ddd",
                      transition: "left 0.2s",
                      maxWidth: "100%",
                      overflowX: "auto",
                      overflowY: "hidden",
                    }}
                  >
                    <NotificationsProvider>
                      <SignalRProvider>
                        <GKHeader {...sidebarProps}></GKHeader>
                      </SignalRProvider>
                    </NotificationsProvider>
                  </Header>

                  <Content style={{ marginTop: 64 }}>
                    <Main />{" "}
                  </Content>
                  <Footer>
                    <GKFooter />{" "}
                  </Footer>
                </Layout>
              </OrganizationsProvider>
            </LayoutProvider>
            <Timeout />
          </Layout>
        </div>
      </SWRConfig>
    </React.Fragment>
  );
}
export default GKRegularLayout;
