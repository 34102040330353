import React from "react";
import Icon, {
  DashboardOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Layout, Menu } from "antd";
import {
  FaCogs,
  FaBell,
  FaWifi,
  FaDownload,
  FaLaptop,
  FaUsers,
  FaTable,
  FaRegClock,
  FaEdit,
  FaList,
  FaRegFileAlt,
  FaWindows,
  FaEthernet,
  FaBuilding,
  FaServer,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useLayout } from "../shared/LayoutStore";
import { useLoggedInUser } from "../shared/LoggedInUserStore";
import classNames from "classnames";
import SetupHub from "../SetupHub/SetupHub";
import { SetupHubProvider } from "../SetupHub/SetupHubStore";
import { loadZendeskChat, openZendeskChat } from "../shared/utils/zendeskUtils";
import SelectOrganization from "./SelectOrganization";
import { useOrganizations } from "../shared/OrganizationsStore";

const { Sider } = Layout;
const { SubMenu } = Menu;

const nonSidebarPaths = ["/administration", "/profile"];

const getSidebarKeyFromLocation = (location) => {
  const path = location?.pathname?.toLowerCase();

  if (!path || nonSidebarPaths.includes(path)) {
    return null;
  }

  if (path === "/") {
    return "/dashboard";
  }

  return path.substring(path);
};

const getDefaultOpenKeys = (location) => {
  const path = location?.pathname?.toLowerCase();

  if (!path || nonSidebarPaths.includes(path)) {
    return [];
  }

  if (path === "/") {
    return ["/dashboard"];
  }

  const submenuKeys = ["/users", "/computers", "/alerts", "/reports", "/logs"];

  let matchedSubmenuKey = null;

  for (const submenuKey of submenuKeys) {
    if (path.includes(submenuKey)) {
      matchedSubmenuKey = submenuKey;
      break;
    }
  }

  return matchedSubmenuKey ? [matchedSubmenuKey] : [path];
};

const shouldIncludeMenuItem = (access, onlyAdmin) => {
  if (!access) {
    return true;
  }

  if (onlyAdmin) {
    return access?.canAdmin;
  }

  return access?.canView || access?.canEdit;
};

function Sidebar({ sidebarCollapsed, setSidebarCollapsed }) {
  const { requiresLicense, bigLogoSrc, smallLogoSrc, settings } = useLayout();
  const location = useLocation();
  const { theme, user } = useLoggedInUser();
  const access = user?.access ?? {};
  const isGlobalAdmin = user?.hasGlobalAccess ?? false;
  const { organizations, selectedOrganizationId } = useOrganizations();
  const [showSelectOrganization, setShowSelectOrganization] =
    React.useState(false);

  const [selectedOrganization, setSelectedOrganization] = React.useState(null);

  React.useEffect(() => {
    const selectedOrg = organizations.find(
      (o) => o.id === selectedOrganizationId
    );
    setSelectedOrganization(selectedOrg ? selectedOrg : null);
  }, [organizations, selectedOrganizationId]);

  React.useEffect(() => {
    const selectedOrg = organizations.find(
      (o) => o.id === selectedOrganizationId
    );
    setSelectedOrganization(selectedOrg ? selectedOrg : null);
  }, []);

  const [selectedKey, setSelectedKey] = React.useState("dashboard");
  const [loadingWidget, setLoadingWidget] = React.useState(false);

  const enterLoading = () => {
    setLoadingWidget(true);
    setTimeout(() => {
      setLoadingWidget(false);
    }, 3000);
  };
  React.useEffect(() => {
    if (!location) {
      return;
    }

    const key = getSidebarKeyFromLocation(location);
    setSelectedKey(key);
  }, [location]);

  const [openKeys, setOpenKeys] = React.useState(["organizations"]);
  const rootSubmenuKeys = ["/alerts", "/reports", "/logs", "/radius"];
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <React.Fragment>
      <Sider
        breakpoint="lg"
        collapsible
        collapsed={sidebarCollapsed}
        trigger={null}
        style={{
          height: "100vh",
          position: "fixed",
          overflow: "hidden",
          left: 0,
        }}
        width={260}
        className={classNames(
          { "gk-dark-bg": theme === "light" },
          { "sidebar-dark-bg": theme === "dark" }
        )}
      >
        <div
          style={{ maxHeight: 100, margin: 16 }}
          className="d-flex justify-center"
        >
          <Link
            disabled={requiresLicense}
            to="/dashboard"
            onClick={() => setSelectedKey("dashboard")}
            className="w-100 h-100 text-center"
          >
            {smallLogoSrc && bigLogoSrc ? (
              <img
                src={sidebarCollapsed ? smallLogoSrc : bigLogoSrc}
                alt="GateKeeper Logo"
                width={"100%"}
                height={"auto"}
                style={{ objectFit: "contain" }}
              />
            ) : null}
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="vertical"
          className={classNames(
            { "gk-dark-bg": theme === "light" },
            { "sidebar-dark-bg": theme === "dark" }
          )}
          style={{
            width: "100%",
          }}
          disabled={organizations.length < 2}
        >
          <SubMenu
            key="organizations"
            mode="vertical"
            onTitleClick={() => {
              setShowSelectOrganization(!showSelectOrganization);
            }}
            title={
              <span
                style={
                  sidebarCollapsed
                    ? { marginLeft: "0px" }
                    : {
                        marginLeft: "10px",
                        fontWeight: "bold",
                        color: "#fff",
                      }
                }
              >
                <Icon component={() => <FaBuilding />} />
                {selectedOrganization ? (
                  <span>Site: {selectedOrganization.name} </span>
                ) : (
                  <span>Site</span>
                )}
              </span>
            }
          ></SubMenu>
        </Menu>
        <Menu
          theme="dark"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={[selectedKey]}
          defaultOpenKeys={getDefaultOpenKeys(location)}
          onClick={(selects) => {
            setSelectedKey(selects.key);
          }}
          className={classNames(
            { "gk-dark-bg": theme === "light" },
            { "sidebar-dark-bg": theme === "dark" }
          )}
          style={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            maxHeight: "calc(100% - 200px)",
          }}
        >
          <Menu.Item disabled={requiresLicense} key="/dashboard">
            <Link to="/dashboard">
              <DashboardOutlined />
              <span>Dashboard</span>
            </Link>
          </Menu.Item>
          {shouldIncludeMenuItem(access.Groups) && (
            <Menu.Item disabled={requiresLicense} key="/groups">
              <Link to="/groups">
                <Icon component={() => <FaCogs />} />
                <span>Groups &amp; Settings</span>
              </Link>
            </Menu.Item>
          )}
          {shouldIncludeMenuItem(access.Computers) && (
            <Menu.Item disabled={requiresLicense} key="/computers">
              <Link to="/computers" className="d-flex align-center">
                <Icon component={() => <FaLaptop />} />
                <span>Computers</span>
              </Link>
            </Menu.Item>
          )}
          {shouldIncludeMenuItem(access.GateKeeperUsers) && (
            <Menu.Item disabled={requiresLicense} key="/users">
              <Link to="/users" className="d-flex align-center">
                <Icon component={() => <FaUsers />} />
                <span> Users</span>
              </Link>
            </Menu.Item>
          )}
          {settings?.EnableADManagement &&
            isGlobalAdmin &&
            shouldIncludeMenuItem(access.GateKeeperUsers) &&
            shouldIncludeMenuItem(access.ActiveDirectory, true) && (
              <Menu.Item disabled={requiresLicense} key="/ad-management">
                <Link to="/ad-management" className="d-flex align-center">
                  <Icon component={() => <FaWindows />} />
                  <span>Active Directory</span>
                </Link>
              </Menu.Item>
            )}
          {settings?.EnableRADIUS &&
            isGlobalAdmin &&
            shouldIncludeMenuItem(access.GateKeeperUsers) &&
            shouldIncludeMenuItem(access.Radius, true) && (
              <SubMenu
                disabled={requiresLicense}
                key="/radius"
                title={
                  <span>
                    <Icon component={() => <FaServer />} />
                    <span>RADIUS</span>
                  </span>
                }
              >
                <Menu.Item disabled={requiresLicense} key="/radius/manage">
                  <Link to="/radius/manage" className="d-flex align-center">
                    <Icon component={() => <FaUsers />} />
                    <span>User Management</span>
                  </Link>
                </Menu.Item>

                <Menu.Item disabled={requiresLicense} key="/radius/accounting">
                  <Link to="/radius/accounting" className="d-flex align-center">
                    <Icon component={() => <FaList />} />
                    <span>Accounting</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}
          {settings?.EnableWindowsManagement &&
            isGlobalAdmin &&
            shouldIncludeMenuItem(access.Computers, true) && (
              <Menu.Item disabled={requiresLicense} key="/remote-management">
                <Link to="/remote-management" className="d-flex align-center">
                  <Icon component={() => <FaEthernet />} />
                  <span>Remote Management</span>
                </Link>
              </Menu.Item>
            )}
          {settings?.EnableAlerts && shouldIncludeMenuItem(access.Alerts) && (
            <SubMenu
              disabled={requiresLicense}
              key="/alerts"
              title={
                <span>
                  <Icon component={() => <FaBell />} />
                  <span>Alerts</span>
                </span>
              }
            >
              <Menu.Item disabled={requiresLicense} key="/alerts/manage">
                <Link to="/alerts/manage" className="d-flex align-center">
                  <Icon component={() => <FaEdit />} />
                  <span>Manage Alerts</span>
                </Link>
              </Menu.Item>

              <Menu.Item disabled={requiresLicense} key="/alerts/logs">
                <Link to="/alerts/logs" className="d-flex align-center">
                  <Icon component={() => <FaList />} />
                  <span>Alerts Audit</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {settings.EnableReports && shouldIncludeMenuItem(access.Reports) && (
            <SubMenu
              disabled={requiresLicense}
              key="/reports"
              title={
                <span>
                  <Icon component={() => <FaRegFileAlt />} />
                  <span>Reports</span>
                </span>
              }
            >
              <Menu.Item
                disabled={requiresLicense}
                key="/reports/on-demand-reports"
              >
                <Link
                  to="/reports/on-demand-reports"
                  className="d-flex align-center"
                >
                  <Icon component={() => <FaEdit />} />
                  <span>Create Report</span>
                </Link>
              </Menu.Item>
              <Menu.Item
                disabled={requiresLicense}
                key="/reports/scheduled-reports"
              >
                <Link to="/reports/scheduled-reports">
                  <Icon component={() => <FaRegClock />} />
                  <span>Schedule Report</span>
                </Link>
              </Menu.Item>
              <Menu.Item
                disabled={requiresLicense}
                key="/reports/previous-reports"
              >
                <Link to="/reports/previous-reports">
                  <Icon component={() => <FaList />} />
                  <span>Reports History</span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
          {(!settings?.RecordLogs && !settings?.RecordServerLogs) ||
          (!shouldIncludeMenuItem(access.ClientLogs) &&
            !shouldIncludeMenuItem(access.HubLogs)) ? null : (
            <SubMenu
              disabled={requiresLicense}
              key="/logs"
              title={
                <span>
                  <Icon component={() => <FaTable />} />
                  <span>Audit Logs</span>
                </span>
              }
            >
              {settings?.RecordLogs &&
                shouldIncludeMenuItem(access.ClientLogs) && (
                  <Menu.Item disabled={requiresLicense} key="/logs/client-logs">
                    <Link
                      to="/logs/client-logs"
                      className="d-flex align-center"
                    >
                      <Icon component={() => <FaList />} />
                      <span>Client Audit</span>
                    </Link>
                  </Menu.Item>
                )}
              {settings?.RecordServerLogs &&
                shouldIncludeMenuItem(access.HubLogs) && (
                  <Menu.Item disabled={requiresLicense} key="/logs/server-logs">
                    <Link
                      to="/logs/server-logs"
                      className="d-flex align-center"
                    >
                      <Icon component={() => <FaList />} />
                      <span>Server Audit</span>
                    </Link>
                  </Menu.Item>
                )}
            </SubMenu>
          )}
          {settings?.RecordScans && shouldIncludeMenuItem(access.Scans) && (
            <Menu.Item disabled={requiresLicense} key="/scans">
              <Link to="/scans">
                <Icon component={() => <FaWifi />} />
                <span>Scans</span>
              </Link>
            </Menu.Item>
          )}
          {shouldIncludeMenuItem(access.Downloads) && (
            <Menu.Item disabled={requiresLicense} key="/downloads">
              <Link to="/downloads">
                <Icon component={() => <FaDownload />} />
                <span>Downloads</span>
              </Link>
            </Menu.Item>
          )}
          {user?.isInAdministratorRole && !requiresLicense && (
            <SetupHubProvider>
              <SetupHub sidebarCollapsed={sidebarCollapsed} />
            </SetupHubProvider>
          )}
        </Menu>
        <div className="d-flex justify-center py-1">
          <Button
            className="zendesk-button"
            type="primary"
            shape="round"
            size="large"
            color="#ffffff"
            loading={loadingWidget}
            icon={<QuestionCircleOutlined />}
            onClick={() => {
              enterLoading();
              loadZendeskChat(openZendeskChat);
            }}
          >
            Knowledge Base
          </Button>
        </div>
      </Sider>
      <Drawer
        title="Select Site"
        placement="left"
        closable={true}
        onClose={() => setShowSelectOrganization(false)}
        visible={showSelectOrganization}
        width={600}
        offset={260}
      >
        <SelectOrganization
          closeDrawer={() => setShowSelectOrganization(false)}
        />
      </Drawer>
    </React.Fragment>
  );
}

export default Sidebar;
