import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, message } from "antd";
import {
  passwordRules,
  validateConfirmPassword,
  validatePassword,
} from "./account-user-setup-form-validators";
import PasswordWithRequirements from "../../shared/UI/PasswordWithRequirements";

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: "vertical",
};
function AccountSetupForm({
  email,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  handleAccountSetup,
  accountSetupLoading,
  disableSubmitButton,
}) {
  const updateConfirmPassword = (passwordValue, confirmPasswordValue) => {
    const validatedPwd = validateConfirmPassword(
      passwordValue,
      confirmPasswordValue
    );
    setConfirmPassword({
      value: confirmPasswordValue,
      ...validatedPwd,
    });
  };

  const handleChangePassword = (e) => {
    const value = e.target.value;
    const validatedPassword = validatePassword(value, confirmPassword.value);
    setPassword({
      value,
      ...validatedPassword,
    });
    const validatedConfirmPwd = validateConfirmPassword(
      value,
      confirmPassword.value
    );
    setConfirmPassword({
      value: confirmPassword.value,
      ...validatedConfirmPwd,
    });
  };

  const handleChangeConfirmPassword = (e) => {
    const value = e.target.value;
    updateConfirmPassword(password.value, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!confirmPassword.value || !password.value) {
      message.error("Password and confirm password are required.");
      return;
    }
    handleAccountSetup();
  };

  return (
    <React.Fragment>
      <Form {...formItemLayout} className="w-100">
        <Form.Item label={<span className="bold">Email</span>} htmlFor="email">
          <Input
            id="email"
            disabled
            autoComplete={"new-password"}
            value={email}
          />
        </Form.Item>
        <Form.Item
          label={
            <span className="bold">
              Password <span className="required-field"></span>
            </span>
          }
          validateStatus={password.validateStatus}
          help={password.help}
          htmlFor="password"
        >
          <PasswordWithRequirements
            id="password"
            autoFocus
            autoComplete={"new-password"}
            placeholder="Password"
            value={password.value || ""}
            onChange={handleChangePassword}
            rules={passwordRules}
          />
        </Form.Item>
        <Form.Item
          label={
            <span className="bold">
              Confirm password <span className="required-field"></span>
            </span>
          }
          validateStatus={confirmPassword.validateStatus}
          help={confirmPassword.help}
          htmlFor={"confirmPassword"}
        >
          <Input.Password
            placeholder="Confirm password"
            autoComplete={"new-password"}
            id="confirmPassword"
            value={confirmPassword.value}
            onChange={handleChangeConfirmPassword}
          />
        </Form.Item>
        <div className="all-center">
          <Button
            type="primary"
            onClick={handleSubmit}
            htmlType="submit"
            size="large"
            loading={accountSetupLoading}
            style={{ width: "160px" }}
            disabled={disableSubmitButton}
          >
            Submit
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default AccountSetupForm;
