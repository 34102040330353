import { ThunderboltOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import FeatureInfoModal from "./FeaturesModal/FeatureInfoModal";

function NewFeatureAnnouncement({ user }) {
  const [showModal, setShowModal] = React.useState(false);
  const { hasSeenFeatures } = user;

  return (
    <React.Fragment>
      <Button
        type={!hasSeenFeatures ? "primary" : "link"}
        icon={<ThunderboltOutlined />}
        onClick={() => setShowModal(true)}
        style={{
          backgroundColor: !hasSeenFeatures ? "green" : "transparent",
        }}
      >
        New Features
      </Button>
      <FeatureInfoModal
        user={user}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </React.Fragment>
  );
}

export default NewFeatureAnnouncement;
