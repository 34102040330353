import React from "react";
import { Button, Divider, message } from "antd";
import { cleanupBackendMessage } from "./../../../Utils/ErrorMessages";
import LicenseUploadUI from "../../Administration/PortalLicense/LicenseUploadUI";
import { AddLicense } from "../../../DataService/Administration";

function RegistrationSuccessNewLicense({ next, prev }) {
  const [fileList, setFileList] = React.useState([]);
  const [validating, setValidating] = React.useState(false);
  const [licenseType, setLicenseType] = React.useState("file");
  const [licenseCode, setLicenseCode] = React.useState("");
  const [portalLicenses, setPortalLicenses] = React.useState([]);

  const licenseProps = {
    name: "file",
    multiple: false,
    accept: ".json",
    fileList,
    onRemove: () => {
      // If we don't set empty array, multiple files will be shown on uploaded list. So making it controlled
      setFileList([]);
      return false;
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  };

  const uploadLicense = async () => {
    try {
      setValidating(true);
      const licenseFile = fileList[0];
      if (!fileList) {
        throw new Error("Invalid license file uploaded.");
      }
      const fileReader = new FileReader();

      fileReader.onload = async (e) => {
        try {
          const licenseContent = e.target.result;
          await AddLicense(licenseContent);
          setValidating(false);
        } catch (error) {
          message.error(
            cleanupBackendMessage(error.responseText) ??
              "Failed to validate license."
          );
          setValidating(false);
          throw error;
        }
      };

      fileReader.readAsText(licenseFile);
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ??
          "Failed to validate and upload license."
      );
      setValidating(false);
      throw error;
    }
  };

  const uploadLicenseCode = async () => {
    try {
      setValidating(true);
      const licenseWithOnlyCode = {
        licenseId: licenseCode,
      };
      await AddLicense(licenseWithOnlyCode);
      setValidating(false);
      setLicenseCode("");

      message.success("Successfully added license.");
    } catch (error) {
      console.log("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Failed to upload license code."
      );
      throw error;
    } finally {
      setValidating(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if (licenseType === "file") {
        await uploadLicense();
      } else if (licenseType === "code") {
        await uploadLicenseCode();
      } else if (licenseType === "portal") {
        await uploadLicenseCode();
        setPortalLicenses([]);
      }
      next();
    } catch (error) {
      console.log("license submit error:", error);
    }
  };

  const shouldDisableUpload = () => {
    switch (licenseType) {
      case "file":
        return fileList.length === 0;
      case "code":
        return !licenseCode;
      case "portal":
        return !licenseCode;

      default:
        return true;
    }
  };

  const handleNext = async () => {
    await handleSubmit();
  };

  const licenseUIProps = {
    licenseType,
    setLicenseCode,
    setLicenseType,
    licenseProps,
    portalLicenses,
    setPortalLicenses,
    licenseCode,
    source: "registration",
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-between align-center h-100">
        <div className="text-center">
          {/* <img src={gkBlueHelmet} width="60" alt="GateKeeper logo" /> */}
          <LicenseUploadUI {...licenseUIProps} />
        </div>
        <div className="my-2">
          <Button
            type="primary"
            onClick={async () => await handleNext()}
            disabled={shouldDisableUpload()}
            loading={validating}
          >
            {validating ? "Uploading" : "Upload & Proceed"}
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={() => next()}
            className="pl-0"
            disabled={validating}
          >
            Skip & Add Later
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegistrationSuccessNewLicense;
