import React from "react";
import LoginLayoutNew from "./LoginLayoutNew";

function Login() {
  return (
    <React.Fragment>
      <LoginLayoutNew />
    </React.Fragment>
  );
}

export default Login;
