import React, { useCallback } from "react";
import { Table, Row, Col, Input } from "antd";
import styled from "styled-components";
import debounce from "lodash-es/debounce";
import useMountedState from "./hooks/useMountedState";
import classNames from "classnames";
const { Search } = Input;

const SearchBoxContainer = styled.div`
  margin: 10px 0 10px 10px;
`;

const TableButtonsContainer = styled.div`
  margin: 10px 10px 10px 0;
`;

const SearchableTable = (props) => {
  const {
    searchboxSize,
    dataSource,
    query,
    setQuery,
    searchFunction,
    ...tableProps
  } = props;

  const initialDataSource =
    dataSource && Array.isArray(dataSource) ? [...dataSource] : [];
  const [filteredDataSource, setFilteredDataSource] = React.useState(
    initialDataSource || []
  );
  const [loadingSearch, setLoadingSearch] = React.useState(false);

  const isMounted = useMountedState();

  React.useEffect(() => {
    if (isMounted()) {
      setFilteredDataSource(dataSource);
    }
  }, [dataSource]);

  const searchTable = React.useCallback(
    debounce(
      (searchQuery = "") => {
        const updateWithSearch = async () => {
          try {
            setLoadingSearch(true);
            const newResults = await searchFunction(searchQuery);
            if (newResults && isMounted()) {
              setFilteredDataSource(newResults);
            }
          } catch (error) {
            if (isMounted()) {
              setFilteredDataSource(initialDataSource);
            }
          }
          setLoadingSearch(false);
        };
        updateWithSearch();
      },
      500,
      { leading: false, trailing: true }
    ),
    [searchFunction]
  );

  const searchboxSpan =
    searchboxSize === "small" ? 6 : searchboxSize === "big" ? 12 : 8;
  const multiSelectButtonsSpan = 24 - searchboxSpan;

  const debouncedSetQuery = useCallback(
    debounce((query) => {
      if (isMounted() && setQuery) {
        setQuery(query);
        searchTable(query);
      }
    }, 200),
    [searchFunction]
  );

  const handleSearch = (value) => {
    const searchQuery = value;
    debouncedSetQuery(searchQuery);
  };

  const updatedTableProps = tableProps.expandable
    ? tableProps
    : {
        ...tableProps,
        scroll: {
          x: tableProps.inDrawer ? 800 : 1024,
          ...tableProps.scroll,
        },
      };

  // To hide scrollbar on empty tables in ant4
  const isEmptyTable = (dataSource ?? []).length === 0;

  return (
    <div
      className={classNames({
        "empty-table": isEmptyTable,
      })}
    >
      <Row>
        <Col span={multiSelectButtonsSpan}>
          <TableButtonsContainer>
            {props.multiSelectButtons || null}
          </TableButtonsContainer>
        </Col>
        <Col span={searchboxSpan}>
          <SearchBoxContainer>
            <Search
              style={{ width: "100%" }}
              placeholder="Search"
              onSearch={handleSearch}
              allowClear
              loading={loadingSearch}
            />
          </SearchBoxContainer>
        </Col>
      </Row>
      <Table {...updatedTableProps} dataSource={filteredDataSource} />
    </div>
  );
};

export default SearchableTable;
