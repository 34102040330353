import React from "react";
import { Modal, Statistic, Button } from "antd";
import { useAuthentication } from "../shared/AuthenticationStore";
import debounce from "lodash-es/debounce";
import useMountedState from "../shared/hooks/useMountedState";

const { Countdown } = Statistic;

const SHOW_WARNING_INTERVAL = 13 * 60 * 1000;
const LOGOUT_ACTION_INTERVAL = 2 * 60 * 1000;

function Timeout() {
  const isMounted = useMountedState();
  const logoutTimer = React.useRef(); // Timer that starts after warning modal is shown.
  const warningTimer = React.useRef(); // Timer after which warning modal is shown.
  const [visible, setVisible] = React.useState(false);
  const { logout } = useAuthentication();

  const clearWarningTimer = () => {
    if (warningTimer.current) {
      clearTimeout(warningTimer.current);
    }
  };

  const clearLogoutTimer = () => {
    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current);
    }
  };

  const startTimers = () => {
    warningTimer.current = setTimeout(() => {
      if (isMounted()) {
        setVisible(true);
      }
    }, SHOW_WARNING_INTERVAL);
  };

  const logoutUser = () => {
    clearLogoutTimer();
    if (isMounted()) {
      setVisible(false);
    }

    logout();
  };

  const resetTimers = () => {
    clearLogoutTimer();
    clearWarningTimer();
    startTimers();
  };

  const handleOk = () => {
    if (isMounted()) {
      setVisible(false);
    }
    resetTimers();
  };

  const movementListener = React.useCallback(
    debounce(() => {
      clearWarningTimer();
      startTimers();
    }, 1000),
    []
  );

  const listenForActivity = () => {
    const events = [
      "load",
      "keypress",
      "mousemove",
      "keydown",
      "scroll",
      "resize",
      "click",
      "dblclick",
      "mousedown",
      "mouseup",
      "mousemove",
      "mouseover",
      "mouseout",
      "mouseenter",
    ];
    events.forEach((event) => {
      document.removeEventListener(event, movementListener);
      document.addEventListener(event, movementListener);
    });
  };

  React.useEffect(() => {
    startTimers();
    listenForActivity();
  }, []);

  React.useEffect(() => {
    if (visible) {
      // Start Timer
      logoutTimer.current = setTimeout(logoutUser, LOGOUT_ACTION_INTERVAL);
      clearWarningTimer();
    }
  }, [visible]);

  return (
    <React.Fragment>
      <Modal
        title="Logout due to inactivity"
        visible={visible}
        closable={false}
        onCancel={handleOk}
        destroyOnClose
        maskClosable={false}
        footer={null}
      >
        <div className="all-center flex-column">
          <p>
            You are being timed out due to inactivity. Please choose to stay
            signed in or you will be logged off automatically.
          </p>
          <div className="p-1"></div>
          <h3>You will be logged out in </h3>
          <Countdown
            format="mm:ss"
            value={Date.now() + LOGOUT_ACTION_INTERVAL}
            onFinish={() => {}}
            valueStyle={{ color: "red", fontSize: "3em" }}
          />
          <div className="p-1"></div>
          <Button type="primary" size="large" onClick={handleOk}>
            I&apos;m still here
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default Timeout;
