import React, { useState } from "react";
import { Button, Card, Form, Input, message } from "antd";
import { validateEmail } from "../shared/utils/emailUtils";
import { validatePhoneNumber } from "../shared/utils/phoneUtils";
// eslint-disable-next-line import/no-unresolved

function DemoUserWelcome({ submitDemoForm, loginLoading }) {
  const [validated, setValidated] = useState(false);

  const submitForm = async () => {
    if (!validated) {
      message.error("Please fill out all fields.");
      return;
    }

    const dataToBackend = {
      name: name.value,
      company: company.value,
      phone: phone.value,
      email: email.value,
    };

    await submitDemoForm(dataToBackend);
  };

  const [name, setName] = useState({ value: "", help: "", validateStatus: "" });
  const [company, setCompany] = useState({
    value: "",
    help: "",
    validateStatus: "",
  });
  const [email, setEmail] = useState({
    value: "",
    help: "",
    validateStatus: "",
  });
  const [phone, setPhone] = useState({
    value: "",
    help: "",
    validateStatus: "",
  });

  const handleChangeName = (e) => {
    const value = e.target.value;
    setName({ ...name, value });
  };

  const handleChangeCompany = (e) => {
    const value = e.target.value;
    setCompany({ ...company, value });
  };

  const handleChangeEmail = (e) => {
    const value = e.target.value;
    const validatedValue = validateEmail(value);

    setEmail({ value, ...validatedValue });
  };

  const handleChangePhoneNumber = (e) => {
    const value = e.target.value;
    const validatedValue = validatePhoneNumber(value);
    setPhone({ value, ...validatedValue });
  };

  React.useEffect(() => {
    let validated =
      name.validateStatus !== "error" &&
      company.validateStatus !== "error" &&
      email.validateStatus !== "error" &&
      phone.validateStatus !== "error" &&
      name.value !== "" &&
      company.value !== "" &&
      email.value !== "" &&
      phone.value !== "";
    setValidated(validated);
  }, [name, company, email, phone]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-center align-center vh-100 vw-100 all-center demo-login-background">
        <Card
          style={{
            width: "60vw",
            minWidth: 300,
            maxWidth: 650,
          }}
        >
          <div>
            <h2 className="text-center">WELCOME TO A DEMO OF GATEKEEPER HUB</h2>
            <div className="text-center">
              GateKeeper Hub is the centralized management console for your
              GateKeeper deployment.
            </div>
            <div className="text-center p-1">
              Please fill out the form below to view a demo of GateKeeper Hub.
            </div>
          </div>

          <Form {...formItemLayout} className="w-100 my-2">
            <Form.Item
              label="Name"
              htmlFor="name"
              help={name.help}
              validateStatus={name.validateStatus}
            >
              <Input
                autoComplete={"new-username"}
                autoFocus
                id="name"
                value={name.value}
                onChange={handleChangeName}
              />
            </Form.Item>
            <Form.Item
              label="Company"
              htmlFor="company"
              help={company.help}
              validateStatus={company.validateStatus}
            >
              <Input
                autoComplete={"new-username"}
                id="company"
                value={company.value}
                onChange={handleChangeCompany}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              htmlFor="email"
              help={email.help}
              validateStatus={email.validateStatus}
            >
              <Input
                autoComplete={"new-username"}
                id="email"
                value={email.value}
                onChange={handleChangeEmail}
              />
            </Form.Item>
            <Form.Item
              label="Phone number"
              htmlFor="phoneNumber"
              help={phone.help}
              validateStatus={phone.validateStatus}
            >
              <Input
                autoComplete={"new-username"}
                id="phone"
                value={phone.value}
                onChange={handleChangePhoneNumber}
              />
            </Form.Item>
          </Form>
          <div className="d-flex flex-column justify-between text-center align-center">
            <Button
              type="primary"
              onClick={async () => await submitForm()}
              disabled={loginLoading || !validated}
            >
              Submit
            </Button>
          </div>
        </Card>
        <div className="d-flex justify-between mt-1">
          <a
            key="company"
            target="_blank"
            rel="noopener noreferrer"
            href="https://gkaccess.com"
            className="color-white"
          >
            Untethered Labs, Inc.
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DemoUserWelcome;
