import React from "react";
function RadiusFeatureInfo() {
  return (
    <React.Fragment>
      <div>
        Add 2FA to your applications using GateKeeper Enterprise. GateKeeper
        Enterprise now provides a RADIUS (Remote Authentication Dial-In User
        Service) server that can be integrated with your GateKeeper Enterprise
        solution to provide 2FA for applications such as VPNs (Virtual Private
        Networks). The RADIUS server will first authenticate the user with their
        Active Directory credential, and then request a 2nd factor (TOTP via
        Google Authenticator or another authenticator app, SMS, Email, or Push
        notification), when accessing applications such as VPNs.
        <br />
        To learn more about RADIUS server integration with GateKeeper Hub for
        2FA, please refer to the article{" "}
        <a
          href="https://gatekeeperhelp.zendesk.com/hc/en-us/articles/19400979694227"
          target="blank"
          rel="noreferrer noopener"
        >
          <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
            What is GateKeeper Enterprise RADIUS Integration
          </span>
        </a>
        .
      </div>
    </React.Fragment>
  );
}

export default RadiusFeatureInfo;
