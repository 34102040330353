import React from "react";
import { Drawer } from "antd";
import NotificationsTable from "./NotificationsTable";
import { useNotifications } from "../shared/NotificationsStore";

function Notifications() {
  const {
    updateNotifications,
    notificationSidebarOpen,
    setNotificationSidebarOpen,
  } = useNotifications();

  const handleDrawerClose = () => {
    updateNotifications();
    setNotificationSidebarOpen(false);
  };

  return (
    <>
      <Drawer
        title="Notifications"
        width={900}
        visible={notificationSidebarOpen}
        onClose={() => handleDrawerClose()}
        maskClosable={true}
      >
        <NotificationsTable />
      </Drawer>
    </>
  );
}

export default Notifications;
