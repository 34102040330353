import React from "react";
import {
  AddNewOrganization,
  GetAllOrganizations,
} from "../../DataService/Organization";
import { getFromStorage, saveInStorage } from "./utils/storage";
import { sortByAlphabet } from "./utils/tableSorters";
import { useAuthentication } from "./AuthenticationStore";

const OrganizationsContext = React.createContext({});

const OrganizationsProvider = (props) => {
  const { user, getLoggedInUserDetails } = useAuthentication();
  const [organizations, setOrganizations] = React.useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] =
    React.useState("");
  const [loadingOrganizations, setLoadingOrganizations] = React.useState(false);

  const saveSelectedOrganizationId = async (id) => {
    if (!id) return;
    setSelectedOrganizationId(id);
    await saveInStorage("selectedOrganizationId", id);
  };

  // eslint-disable-next-line consistent-return
  const getOrganizations = async () => {
    const cmp = await GetAllOrganizations();
    return cmp;
  };

  const updateOrganizations = async () => {
    try {
      setLoadingOrganizations(true);
      const data = await getOrganizations();
      const organizationsData = data.map((x) => ({
        key: x.id,
        ...x,
      }));

      const defaultOrganization = organizationsData.find(
        (organization) => organization.isDefault
      );
      const otherOrganizations = organizationsData.filter(
        (organization) => !organization.isDefault
      );

      const sortedOrganizations = [...otherOrganizations].sort((a, b) =>
        sortByAlphabet(a, b, "name")
      );

      setOrganizations(
        defaultOrganization
          ? [defaultOrganization, ...sortedOrganizations]
          : sortedOrganizations
      );

      await getLoggedInUserDetails(user?.id);
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoadingOrganizations(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const addNewOrganization = async (name, note) => {
    try {
      const response = await AddNewOrganization({
        name,
        note,
      });
      await updateOrganizations();
      return response;
    } catch (error) {
      console.log(error);
      updateOrganizations();
    }
  };

  const getSelectedOrganization = () => {
    if (!selectedOrganizationId) {
      const selectedOrgId = getFromStorage("selectedOrganizationId");
      if (selectedOrgId) {
        setSelectedOrganizationId(selectedOrgId);
        return organizations.find((x) => x.id === selectedOrgId);
      }
    } else return organizations.find((x) => x.id === selectedOrganizationId);
  };

  React.useEffect(() => {
    updateOrganizations();
  }, []);

  React.useEffect(() => {
    const getOrganizationId = async () => {
      const selectedOrganizationIdFromStorage = getFromStorage(
        "selectedOrganizationId"
      );
      return selectedOrganizationIdFromStorage;
    };

    const getOrganizations = async () => {
      await updateOrganizations();
    };
    getOrganizationId().then((id) => {
      if (id) {
        setSelectedOrganizationId(id);
      } else {
        if (organizations.length > 0) {
          setSelectedOrganizationId(organizations[0].id);
        } else {
          getOrganizations().then(() => {
            setSelectedOrganizationId(organizations[0].id);
          });
        }
      }
    });
  }, []);

  const value = React.useMemo(
    () => ({
      organizations,
      updateOrganizations,
      addNewOrganization,
      selectedOrganizationId,
      setSelectedOrganizationId,
      saveSelectedOrganizationId,
      loadingOrganizations,
      setLoadingOrganizations,
      getSelectedOrganization,
    }),
    [
      organizations,
      updateOrganizations,
      addNewOrganization,
      selectedOrganizationId,
      loadingOrganizations,
      setSelectedOrganizationId,
      loadingOrganizations,
      setLoadingOrganizations,
      saveSelectedOrganizationId,
      getSelectedOrganization,
    ]
  );

  return <OrganizationsContext.Provider value={value} {...props} />;
};

const useOrganizations = () => {
  const context = React.useContext(OrganizationsContext);

  if (!context) {
    throw new Error(
      "useOrganizations must be used within OrganizationsProvider"
    );
  }

  return context;
};

export { OrganizationsProvider, useOrganizations };
