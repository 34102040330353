import dayjs from "../../shared/utils/dayjsUtils";

export const sortByDate = (a, b, columnKey) => {
  const dateA = dayjs.utc(a[columnKey]).local().toDate();
  const dateB = dayjs.utc(b[columnKey]).local().toDate();

  return dateB - dateA;
};

export const sortByVersion = (a, b, columnKey) => {
  try {
    const verA = a[columnKey];
    const verB = b[columnKey];

    const verAParts = verA.split(".").map(Number);
    const verBParts = verB.split(".").map(Number);

    if (verAParts[0] < verBParts[0]) {
      return -1;
    } else if (verAParts[0] > verBParts[0]) {
      return 1;
    }

    if (verAParts[1] < verBParts[1]) {
      return -1;
    } else if (verAParts[1] > verBParts[1]) {
      return 1;
    }

    if (verAParts[2] < verBParts[2]) {
      return -1;
    } else if (verAParts[2] > verBParts[2]) {
      return 1;
    }

    if (verAParts[3] < verBParts[3]) {
      return -1;
    } else if (verAParts[3] > verBParts[3]) {
      return 1;
    }
    return 0;
  } catch (error) {
    console.error("error:", error);
  }
};

export const sortByAlphabet = (a, b, columnKey) => {
  const strA = (a[columnKey] || "").toString().toLowerCase();
  const strB = (b[columnKey] || "").toString().toLowerCase();

  if (strA < strB) {
    return -1;
  }

  if (strA > strB) {
    return 1;
  }

  return 0;
};

export const sortByNumbers = (a, b, columnKey) => {
  const numA = Number((a[columnKey] || "0").toString().replace(",", ""));
  const numB = Number((b[columnKey] || "0").toString().replace(",", ""));

  return numA - numB;
};
