import React from "react";
import { Button, Table } from "antd";
import { sortByAlphabet, sortByDate } from "../../shared/utils/tableSorters";
import dayjs from "../../shared/utils/dayjsUtils";
import { v4 as uuid } from "uuid";
import { CheckOutlined } from "@ant-design/icons";
// import useSWR from "swr";
// import { getFetcherNoToken } from "../../../DataService/Common";

function PortalLicenseTable({ licenses, handleSubmit, setLicenseCode }) {
  // const [version, setVersion] = React.useState({
  //   isOnPremise: false,
  // });

  // const {
  //   data: versionData,
  //   isValidating: isVersionLoading,
  //   error: versionError,
  // } = useSWR(`/hub/api/v4/Server/Version`, getFetcherNoToken);

  const [dataSource, setDataSource] = React.useState([]);
  const [selectedRowKey, setSelectedRowKey] = React.useState("");

  // React.useEffect(() => {
  //   if (!versionData || versionError || isVersionLoading) {
  //     return;
  //   }

  //   setVersion(versionData);
  // }, [versionData, versionError, isVersionLoading]);
  React.useEffect(() => {
    if (!licenses || licenses.length === 0) {
      setDataSource([]);
      return;
    }

    const _dataSource = licenses.reverse();
    const dataSource = _dataSource.map((license) => {
      return {
        ...license,
        key: uuid(),
      };
    });
    setDataSource(dataSource);
  }, [licenses]);

  const columns = [
    {
      title: "Assigned To",
      dataIndex: "name",
      key: "name",
      width: "40%",
      align: "left",
      sorter: (a, b) => sortByAlphabet(a, b, "name"),
    },
    {
      title: "Valid From",
      dataIndex: "validfrom",
      key: "validfrom",
      width: "20%",
      align: "left",
      sorter: (a, b) => sortByDate(a, b, "validfrom"),
      render: (text, record) => {
        const { validfrom } = record;
        let date = dayjs.utc(validfrom);
        let dateOutput = date.format("L");
        let isAfterToday = date.isAfter(dayjs());
        return (
          <span
            className={isAfterToday ? "text-danger" : ""}
            title={isAfterToday ? `License is valid from ${dateOutput}.` : ""}
          >
            {dateOutput}
          </span>
        );
      },
    },
    {
      title: "Valid Until",
      dataIndex: "validto",
      key: "validto",
      width: "20%",
      align: "left",
      sorter: (a, b) => sortByDate(a, b, "validto"),
      render: (text, record) => {
        const { validto } = record;
        let date = dayjs.utc(validto);
        let dateOutput = date.format("L");
        return <span>{dateOutput}</span>;
      },
    },
    {
      title: "Users",
      dataIndex: "nodes",
      key: "nodes",
      width: "10%",
      align: "left",
      sorter: (a, b) => sortByAlphabet(a, b, "nodes"),
      render: (text, record) => {
        const { nodes } = record;
        const users = Number(nodes).toLocaleString(
          navigator.language || "en-US"
        );
        return <span>{users}</span>;
      },
    },
    // {
    //   title: "Off-site Access",
    //   dataIndex: "hasExternalLoginSupport",
    //   key: "hasExternalLoginSupport",
    //   width: "10%",
    //   align: "center",
    //   hidden: version?.isOnPremise,
    //   render: (text, record) => {
    //     const { hasExternalLoginSupport } = record;
    //     return (
    //       <>
    //         {hasExternalLoginSupport ? (
    //           <CheckCircleFilled
    //             style={{ color: "#00bd56", fontSize: "1em" }}
    //           />
    //         ) : (
    //           <CloseCircleFilled
    //             style={{ color: "#ef5350", fontSize: "1em" }}
    //           />
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      fixed: "right",
      sorter: (a, b) => sortByAlphabet(a, b, "actions"),
      render: (text, record) => {
        return (
          <span className="">
            {record.key === selectedRowKey ? (
              <div className="all-center">
                <CheckOutlined style={{ color: "#407CCA", fontSize: "1em" }} />
              </div>
            ) : (
              <Button
                className="actionButton"
                type="link"
                onClick={(e) => {
                  e.stopPropagation();
                  setLicenseCode(record.externalLicenseId);
                  setSelectedRowKey(record.key);
                }}
              >
                Select
              </Button>
            )}
          </span>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const tableProps = {
    pagination: false,
    size: "small",
    className: "no-word-break fixedWidthTable",
    scroll: {
      y: 300,
      x: 500,
    },
    rowClassName: (record, index) =>
      record.key === selectedRowKey ? "dark-selected-row" : "actionButtonsRow",
  };
  return (
    <React.Fragment>
      <div style={{ maxWidth: "100%" }} className="all-center">
        <Table
          dataSource={dataSource}
          columns={columns}
          {...tableProps}
          rowKey="id"
        />
      </div>
    </React.Fragment>
  );
}

export default PortalLicenseTable;
