import React from "react";
import { Button, Divider } from "antd";
import FirstUserAdminProfileForm from "./FirstUserForms/FirstUserAdminProfileForm";

function FirstUserAdminProfile({ next, prev, formProps }) {
  const [nextDisabled, setNextDisabled] = React.useState(false);
  const { firstName, lastName, email, phoneNumber } = formProps;

  React.useEffect(() => {
    if (
      [
        firstName.validateStatus,
        lastName.validateStatus,
        email.validateStatus,
        phoneNumber.validateStatus,
      ].some((status) => status === "error") ||
      [firstName.value, lastName.value, email.value, phoneNumber.value].some(
        (value) => value === ""
      )
    ) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [firstName, lastName, email, phoneNumber]);

  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-between align-center h-100">
        <div className="text-center mb-1">
          <h3>STEP 1: SET UP AN ADMINISTRATOR PROFILE</h3>
          The administrator will have full control over the GateKeeper Hub
          application.
        </div>

        <FirstUserAdminProfileForm {...formProps} />

        <div className="mb-2">
          <Button type="primary" onClick={() => prev()}>
            Previous
          </Button>
          <Divider type="vertical" />
          <Button type="primary" onClick={() => next()} disabled={nextDisabled}>
            Next
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FirstUserAdminProfile;
