import React from "react";
import styled from "styled-components";
import { Button, Row, Col } from "antd";
import PropTypes from "prop-types";

export const SaveButtonContainer = styled.div`
  padding: 20px;
`;

export const StepsButtonContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardBottomContainer = ({
  children,
  style,
  hasBackgroundColor,
}) => {
  const commonStyle = {
    borderTop: "1px solid #ddd",
    width: "100%",
    ...style,
  };

  const updatedStyle = hasBackgroundColor
    ? {
        ...commonStyle,
        background: "#e9e9e9",
      }
    : commonStyle;

  return (
    <React.Fragment>
      <div className="p-4"></div>
      <div
        style={{ position: "absolute", bottom: 0, width: "100%" }}
        className="card-bottom-container"
      >
        <div style={updatedStyle}>{children}</div>
      </div>
    </React.Fragment>
  );
};

CardBottomContainer.defaultProps = {
  hasBackgroundColor: true,
};

export const SummaryContainer = styled.div`
  padding: 50px;
`;

export const FormContainer = styled.div`
  padding: 20px;
`;

export const SettingRowsContainer = styled.div`
  margin-bottom: 20px;
`;

export const AddNewSidebarContainer = styled.div`
  & > .steps-header {
    padding: 10px;
  }

  & > .steps-footer {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
`;

export const EmptyDivider = styled.div`
  padding-top: ${(props) => (props.height ? props.height : 10)}px;
`;

export const EmptyActionButton = () => {
  return (
    <Button
      className="actionButton"
      type="link"
      style={{ visibility: "hidden" }}
    >
      Empty
    </Button>
  );
};

export const FormRequiredInfo = () => (
  <Row>
    <Col xs={24} sm={8}></Col>
    <Col xs={24} sm={12}>
      <span className="required-field"></span> indicates a{" "}
      <span className="bold">required</span> field.
    </Col>
  </Row>
);

export const FormFieldLabel = ({ required, bold, children }) => (
  <span>
    <span className={bold ? "bold" : null}>{children}</span>{" "}
    {required ? <span className="required-field"></span> : null}
  </span>
);

FormFieldLabel.propTypes = {
  required: PropTypes.bool,
  bold: PropTypes.bool,
};

FormFieldLabel.defaultProps = {
  required: false,
  bold: false,
};

export const LinkStyleButton = React.forwardRef((props, ref) => {
  const { style, ...otherProps } = props;
  return (
    <button
      ref={ref}
      style={{
        textAlign: "left",
        background: "none",
        border: "none",
        padding: "0",
        color: "#407CCA",
        cursor: "pointer",
        outline: "none",
        ...style,
      }}
      {...otherProps}
    />
  );
});
