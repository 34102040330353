import { ajax } from "./Common";

export async function GetAllOrganizations() {
  return await ajax({
    url: `/hub/api/v4/organizations`,
    method: `GET`,
    dataType: `json`,
  });
}

export async function AddNewOrganization(newOrganizationObject) {
  return await ajax({
    url: `/hub/api/v4/organizations`,
    method: `POST`,
    dataType: `json`,
    contentType: `application/json;charset=utf-8`,
    data: newOrganizationObject,
  });
}

export async function UpdateOrganization(
  organizationId,
  updatedOrganizationObject
) {
  return await ajax({
    url: `/hub/api/v4/organizations/${organizationId}`,
    method: `PUT`,
    dataType: `json`,
    contentType: `application/json;charset=utf-8`,
    data: updatedOrganizationObject,
  });
}

export async function DeleteOrganization(organizationId) {
  return await ajax({
    url: `/hub/api/v4/organizations/${organizationId}`,
    method: `DELETE`,
    dataType: `json`,
  });
}
export async function AddOrganizationMembers(
  organizationId,
  updatedOrganizationObject
) {
  return await ajax({
    url: `/hub/api/v4/organizations/${organizationId}/addusers`,
    method: `PUT`,
    dataType: `json`,
    contentType: `application/json;charset=utf-8`,
    data: updatedOrganizationObject,
  });
}
export async function RemoveOrganizationMembers(
  organizationId,
  updatedOrganizationObject
) {
  return await ajax({
    url: `/hub/api/v4/organizations/${organizationId}/removeusers`,
    method: `PUT`,
    dataType: `json`,
    contentType: `application/json;charset=utf-8`,
    data: updatedOrganizationObject,
  });
}
