import React from "react";
import Icon, {
  NotificationOutlined,
  SettingOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { Button, Badge } from "antd";
import { Link, useLocation } from "react-router-dom";
import { getTitleForPath } from "../shared/Routes";
import { useNotifications } from "../shared/NotificationsStore";
import Notifications from "../Notifications/Notifications";
import UserProfileDropDown from "./UserProfileDropDown";
import { useLoggedInUser } from "../shared/LoggedInUserStore";
import { FiSun, FiMoon } from "react-icons/fi";
import { useLayout } from "../shared/LayoutStore";
import NewFeatureAnnouncement from "../NewFeature/NewFeatureAnnouncement";

function GKHeader({ sidebarCollapsed, setSidebarCollapsed }) {
  const location = useLocation();
  const { requiresLicense } = useLayout();
  const { unseenNotificationCount, setNotificationSidebarOpen } =
    useNotifications();

  const { settings } = useLayout();

  const { theme, switchTheme, user } = useLoggedInUser();

  const toggleMenuCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const getPageTitle = () => {
    const currentPagePath = location?.pathname ?? "";
    return getTitleForPath(currentPagePath, settings, user);
  };

  return (
    <React.Fragment>
      <div className="d-flex align-center h-100 justify-between pr-1">
        <div className="d-flex align-center justify-center">
          <span className="sidebar-trigger" style={{ marginTop: 3 }}>
            {sidebarCollapsed ? (
              <MenuUnfoldOutlined
                className="trigger"
                onClick={toggleMenuCollapse}
              />
            ) : (
              <MenuFoldOutlined
                className="trigger"
                onClick={toggleMenuCollapse}
              />
            )}
          </span>
          <div className="p-1"></div>
          <h2 style={{ marginTop: 6, lineHeight: "clamp(10px, 20px, 25px)" }}>
            {getPageTitle()}
          </h2>
        </div>
        <div className="d-flex align-center">
          <NewFeatureAnnouncement user={user} />
          <Button
            onClick={() => {
              if (theme === "dark") {
                switchTheme("light");
              } else {
                switchTheme("dark");
              }
            }}
            icon={
              <Icon
                component={
                  theme === "dark" ? () => <FiSun /> : () => <FiMoon />
                }
              />
            }
            type="link"
            title={
              theme === "dark"
                ? "Switch to light theme"
                : "Switch to dark theme"
            }
          >
            {theme === "dark" ? "Light" : "Dark"}
          </Button>
          <Button
            type="link"
            hidden={requiresLicense}
            onClick={() => {
              setNotificationSidebarOpen(true);
            }}
          >
            <Badge count={unseenNotificationCount ?? 0}>
              <NotificationOutlined />
            </Badge>
            <span className="pl-1"></span>
            Notifications
          </Button>
          {user?.isInAdministratorRole && (
            <Link to="/administration">
              <Button type="link">
                <SettingOutlined />
                Administration
              </Button>
            </Link>
          )}
          <UserProfileDropDown />
        </div>
      </div>
      <Notifications />
    </React.Fragment>
  );
}

export default GKHeader;
