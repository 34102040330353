import { Alert, Button } from "antd";
import React from "react";
import { EmptyDivider } from "../StyledComponents";
import { useOrganizations } from "../shared/OrganizationsStore";
import SearchableTable from "../shared/SearchableTable";

function SelectOrganization({ closeDrawer }) {
  const { organizations, selectedOrganizationId, saveSelectedOrganizationId } =
    useOrganizations();
  const [dataSource, setDataSource] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [selectedOrganization, setSelectedOrganization] = React.useState(null);

  React.useEffect(() => {
    setSelectedOrganization(
      organizations.find((x) => x.id === selectedOrganizationId)
    );
    const otherOrganizations = organizations.filter(
      (x) => x.id !== selectedOrganizationId
    );
    setDataSource(otherOrganizations);
    setQuery("");
  }, [organizations, selectedOrganizationId]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Description",
      dataIndex: "note",
      key: "note",
      width: "50%",
    },
    {
      title: "Action",
      dataIndex: "",
      render: (text, record) => (
        <span className="actionButtonsColumn">
          <Button
            type="link"
            disabled={record.id === selectedOrganizationId}
            onClick={() => {
              saveSelectedOrganizationId(record.id);
              closeDrawer();
            }}
          >
            Select
          </Button>
        </span>
      ),
    },
  ];
  const tableProps = {
    size: "small",
    scroll: {
      y: 700,
      x: 500,
    },
    pagination: false,
  };

  const handleSearch = (value) => {
    const searchQuery = value;
    const newData = dataSource.filter((item) => {
      const itemData = `${item.name.toUpperCase()} ${item.note.toUpperCase()}`;
      const queryData = searchQuery.toUpperCase();
      return itemData.indexOf(queryData) > -1;
    });
    return newData;
  };
  return (
    <React.Fragment>
      <div className="p-0 card-content-no-scroll">
        <div
          style={{
            fontSize: 20,
            fontWeight: 400,
            display: "flex",
          }}
          className="justify-center mb-2 "
        >
          <span className="text-muted">Current Site:</span>
          <span className="ml-1 bold">
            {selectedOrganization ? selectedOrganization.name : ""}
          </span>
        </div>
        <Alert
          type="info"
          message="Choose the Site you want to manage."
          showIcon
        />
        <EmptyDivider height={10} />

        <SearchableTable
          {...tableProps}
          columns={columns}
          searchboxSize="big"
          dataSource={dataSource}
          query={query}
          setQuery={setQuery}
          searchFunction={handleSearch}
        />
      </div>
    </React.Fragment>
  );
}

export default SelectOrganization;
