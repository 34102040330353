export const validatePhoneNumber = (
  phoneNumber
  // usersInTable = [],
  // usersInDb = []
) => {
  if (!phoneNumber) {
    return {
      validateStatus: "error",
      help: "Cannot be empty.",
    };
  }

  // eslint-disable-next-line no-useless-escape
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  if (!re.test(phoneNumber.toString().toLowerCase())) {
    return {
      validateStatus: "error",
      help: "Invalid Phone Number.",
    };
  }

  return {
    validateStatus: "success",
    help: "",
  };
};

export const validateUsername = (username) => {
  if (!username) {
    return {
      validateStatus: "error",
      help: "Cannot be empty.",
    };
  }

  return {
    validateStatus: "success",
    help: "",
  };
};

export const validateEmail = (email) => {
  if (!email) {
    return {
      validateStatus: "error",
      help: "Cannot be empty.",
    };
  }

  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email.toString().toLowerCase())) {
    return {
      validateStatus: "error",
      help: "Invalid email address.",
    };
  }

  return {
    validateStatus: "success",
    help: "",
  };
};

export const passwordRules = {
  minlength: {
    text: "Minimum 10 characters long.",
    validate: (password) => {
      let minLength = 10;
      return password.trim().length >= minLength;
    },
    passed: false,
  },
  containNumbers: {
    text: "Contain at least one number.",
    minLength: 1,

    validate: (password) => {
      const regex = new RegExp("[0-9]", "g");
      return regex.test(password);
    },
    passed: false,
  },
  containLowerCaseAlphabet: {
    text: "Contain at least one lowercase character.",
    minLength: 1,
    validate: (password) => {
      const regex = new RegExp("[a-z]", "g");
      return regex.test(password);
    },
    passed: false,
  },
  containUpperCaseAlphabet: {
    text: "Contain at least one uppercase character.",
    minLength: 1,
    validate: (password) => {
      const regex = new RegExp("[A-Z]", "g");
      return regex.test(password);
    },
    passed: false,
  },
  containSymbols: {
    text: "Contain at least one symbol.",
    minLength: 1,
    validate: (password) => {
      const regex = new RegExp(
        "[!,%,&,@,#,$,^,*,?,_,~,/,:,;,',\",{,},\\\\,+,=,(,),-,<,>,.,\\-,\\|]",
        "g"
      );
      return regex.test(password);
    },
    passed: false,
  },
};

export const validatePassword = (password) => {
  if (!password) {
    return {
      validateStatus: "error",
      help: "Cannot be empty.",
    };
  }

  let isPasswordValid = true;
  Object.values(passwordRules).forEach((rule) => {
    isPasswordValid &= rule.validate(password);
  });

  if (!isPasswordValid) {
    return {
      validateStatus: "error",
      help: "Doesn't meet password requirements.",
    };
  }

  return {
    validateStatus: "success",
    help: "",
  };
};

export const validateConfirmPassword = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return {
      validateStatus: "error",
      help: "Doesn't match password.",
    };
  }

  return {
    validateStatus: "success",
    help: "",
  };
};
