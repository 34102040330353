import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, message, Radio, Space } from "antd";
import { cleanupBackendMessage } from "../../Utils/ErrorMessages";
import { Send2FACode } from "../../DataService/Account";

function TwoFactorInputStep2({
  twoFactorData,
  deliveryType,
  setDeliveryType,
  next,
  stepLoading,
}) {
  const [sendingCode, setSendingCode] = React.useState(false);

  const handleChangeTransportType = async (e) => {
    const { value } = e.target;
    setDeliveryType(value);
  };

  const handleSendTwoFactorCode = async () => {
    try {
      setSendingCode(true);
      if (deliveryType === "email" || deliveryType === "phone") {
        const data = {
          stamp: twoFactorData.stamp,
          type: deliveryType === "email" ? 2 : 1,
        };
        await Send2FACode(data);
      }
      setSendingCode(false);
      next();
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) || "Failed to send code."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!deliveryType) {
      message.error("Choose your 2FA method");
      return;
    }
    await handleSendTwoFactorCode();
  };
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Radio.Group
          className="mt-1 mb-3"
          onChange={handleChangeTransportType}
          value={deliveryType}
          optionType="button"
          buttonStyle="default"
          style={{ textAlign: "center", width: "100%" }}
        >
          <Space direction="vertical">
            {twoFactorData?.phoneEnabled ? (
              <Radio.Button
                style={{
                  maxWidth: "360px",
                  minWidth: "240px",
                }}
                value={"phone"}
              >
                SMS: {twoFactorData?.maskedPhone}
              </Radio.Button>
            ) : null}
            {twoFactorData?.authenticatorEnabled ? (
              <Radio.Button
                style={{
                  maxWidth: "360px",
                  minWidth: "240px",
                }}
                value={"authenticator"}
              >
                Authenticator App
              </Radio.Button>
            ) : null}
            <Radio.Button
              style={{
                maxWidth: "360px",
                minWidth: "240px",
              }}
              value={"backup"}
            >
              Backup Codes
            </Radio.Button>
          </Space>
        </Radio.Group>
        <div className="h-center">
          <Button
            htmlType="submit"
            type="primary"
            loading={sendingCode || stepLoading}
            style={{ width: "160px" }}
            onClick={handleSubmit}
          >
            {sendingCode ? "Sending Code" : "Next"}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default TwoFactorInputStep2;
