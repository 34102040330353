import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import useSearchParams from "../../shared/hooks/useSearchParams";
import TaskCard from "./TaskCard";

function AddUserTask({ task }) {
  const history = useHistory();
  const searchParams = useSearchParams();

  const handleAddButtonClick = () => {
    history.push("/users?open=addusers");
  };

  const handleTaskFinish = () => {
    // Clear the addusers from search params so that on refreshing it doesn't open Add Users sidebar again.
    if (searchParams.has("open") && searchParams.get("open") === "addusers") {
      history.replace("/users");
    }
  };

  return (
    <React.Fragment>
      <TaskCard
        title={"Add a User"}
        task={task}
        handleTaskFinish={handleTaskFinish}
        actions={[
          <Button key="add" onClick={handleAddButtonClick}>
            Add a User
          </Button>,
        ]}
      >
        Add a user to GateKeeper Hub and assign a token and a password for that
        user.
      </TaskCard>
    </React.Fragment>
  );
}

export default AddUserTask;
