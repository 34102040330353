import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, Typography, message } from "antd";
import { Setup2FALoginScreen } from "../../../DataService/Account";
import { cleanupBackendMessage } from "../../../Utils/ErrorMessages";
import { validatePhoneNumber } from "../../shared/utils/phoneUtils";

function Setup2FAStep2({
  deliveryType,
  stepLoading,
  next,
  phoneNumber,
  setPhoneNumber,
  qrBase64Image,
  otpSecret,
  stamp,
  prev,
}) {
  const [phone, setPhone] = React.useState(phoneNumber);
  const handleCodeChange = (e) => {
    const { value } = e.target;
    setPhone(value);
  };
  const handleSendSMS = async () => {
    const validate = validatePhoneNumber(phone);
    if (validate.validateStatus === "error") {
      message.error(validate.help);
      return;
    }

    setPhoneNumber(phone);
    try {
      const data = { type: 1, stamp, phoneNumber: phone };
      const response = await Setup2FALoginScreen(data);
      message.success(response);
      next();
    } catch (error) {
      console.log("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Error sending OTP code via SMS."
      );
    }
  };

  const handleSubmitForPhone = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await handleSendSMS();
  };
  const handleSubmitForAuthenticator = (e) => {
    e.stopPropagation();
    e.preventDefault();
    next();
  };
  return (
    <React.Fragment>
      {deliveryType === "phone" ? (
        <Form
          wrapperCol={{ span: 10 }}
          labelCol={{ span: 7 }}
          labelAlign="left"
          onSubmit={handleSubmitForPhone}
        >
          <Form.Item
            htmlFor="phoneNumber"
            className="mb-2"
            label={<span style={{ textAlign: "center" }}>Phone Number</span>}
            required
          >
            <Input
              value={phone}
              onChange={handleCodeChange}
              id="phoneNumber"
              autoComplete={"new-username"}
              autoFocus
              allowClear
            />
          </Form.Item>
          <div className="h-center">
            <Button
              htmlType="submit"
              type="primary"
              disabled={!phone}
              loading={stepLoading}
              style={{ width: "160px" }}
              onClick={handleSubmitForPhone}
            >
              Next
            </Button>
          </div>
        </Form>
      ) : (
        <Form
          layout="vertical"
          wrapperCol={{ span: 14, offset: 6 }}
          onSubmit={handleSubmitForAuthenticator}
        >
          <div className="flex-1 d-flex flex-column align-center mb-2">
            {qrBase64Image ? (
              <img
                alt="QR Code"
                src={"data:image/bmp;base64," + qrBase64Image}
                height="160"
                width="160"
              />
            ) : (
              <div>No profile picture</div>
            )}
            <p>
              If you are unable to scan the QR code, please enter the SECRET Key
              shown below into your authenticator app.
            </p>
            <Typography.Text strong copyable type="danger">
              {otpSecret}
            </Typography.Text>
          </div>
          <div className="h-center">
            <Button
              htmlType="submit"
              type="primary"
              loading={stepLoading}
              style={{ width: "160px" }}
              onClick={handleSubmitForAuthenticator}
            >
              {"Next"}
            </Button>
          </div>
        </Form>
      )}
      <div className="h-center mt-1">
        <Button onClick={prev} type="link" style={{ width: "160px" }}>
          Go Back
        </Button>
      </div>
    </React.Fragment>
  );
}

export default Setup2FAStep2;
