import React from "react";
function SitesFeatureInfo() {
  return (
    <React.Fragment>
      <div>
        Sites are sub-divisions in your GateKeeper Hub that can be used to
        manage Role-Based-Access to computers, users, and other aspects of the
        Hub for your organization. You can assign team members as
        Administrators, Managers or Limited Users to one or more site. Global
        Access rights can be granted to certain team members who will have
        access to all sites. Computers and users can be placed in a single site
        and only the team members who have access to the site will be able to
        manage the users and computers in that site. For more details, please
        refer to the article linked{" "}
        <a
          href="https://gatekeeperhelp.zendesk.com/hc/en-us/articles/19153622820243"
          target="_blank"
          rel="noreferrer"
        >
          <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
            here
          </span>
        </a>
        .
      </div>
    </React.Fragment>
  );
}

export default SitesFeatureInfo;
