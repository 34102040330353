import React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Button, Form, message } from "antd";
import { Link } from "react-router-dom";

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: "vertical",
};

function HubLoginFormStep1({
  username,
  setUsername,
  password,
  setPassword,
  handleLogin,
  loginLoading,
  stepLoading,
}) {
  const handleChangeUsername = (e) => {
    const value = e.target.value;
    setUsername({ ...username, value });
  };

  const handleChangePassword = (e) => {
    const value = e.target.value;
    setPassword({ ...password, value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username.value || !password.value) {
      message.error("Username and password are required.");
      return;
    }
    handleLogin();
  };

  return (
    <React.Fragment>
      <Form
        {...formItemLayout}
        onSubmit={handleSubmit}
        style={{ width: "100%" }}
        colon={false}
      >
        <Form.Item
          label={<span className="bold">Username</span>}
          htmlFor="username"
          help={username.help}
          validateStatus={username.validateStatus}
        >
          <Input
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            id="username"
            autoFocus
            placeholder="Username"
            value={username.value}
            onChange={handleChangeUsername}
          />
        </Form.Item>
        <Form.Item
          className="login-password-form-item-lable"
          label={
            <div className="d-flex justify-between align-center w-100">
              <span className="bold">Password</span>
              <span>
                <Button type="link" className="p-0" tabIndex="-1">
                  <Link tabIndex="-1" to="/forgotpassword">
                    Forgot password?
                  </Link>
                </Button>
              </span>
            </div>
          }
          htmlFor="password"
          help={password.help}
          validateStatus={password.validateStatus}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            id="password"
            placeholder="Password"
            value={password.value}
            onChange={handleChangePassword}
          />
        </Form.Item>
        <div className="all-center">
          <Button
            type="primary"
            onClick={handleSubmit}
            htmlType="submit"
            size="large"
            loading={loginLoading || stepLoading}
            style={{ width: "160px" }}
          >
            Sign In
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default HubLoginFormStep1;
