import React from "react";
import { Link } from "react-router-dom";

function AlwaysConnectedModeInfo({ dismissAnnouncement }) {
  return (
    <React.Fragment>
      <div>
        <p>
          GateKeeper Hub administrators can now enable Always Connected Mode for
          specific groups of computers in the Hub. This feature increases the
          security of the GateKeeper Authentication platform by only
          synchronizing user credentials from the Hub to the client machines
          when the user is actively logged into the machine with their
          GateKeeper token. When the user locks or logs out of the computer, the
          entire cache of user credentials is deleted from the client machine.
        </p>
        <p>
          The GateKeeper Client application must be updated to{" "}
          <strong>3.13.27</strong> or later version to apply the Always
          Connected Mode setting.
        </p>
        <p>
          Go to your <Link to="/groups">Groups page</Link> and click on the{" "}
          <strong>Manage Settings {">>"} Update Settings</strong> button for a
          group and navigate to the <strong>Advanced Settings</strong> section
          to enable or disable the Always Connected Mode.
        </p>
        <p>
          <span className="bold color-danger">
            Warning: By choosing to enable the Always Connected Mode for a group
            of computers, the GateKeeper users{" "}
            <strong>
              <i>
                <u>WILL NOT</u>
              </i>
            </strong>{" "}
            get access to their credentials if the client machine is not
            connected to the GateKeeper Hub.
          </span>
        </p>
      </div>
    </React.Fragment>
  );
}

export default AlwaysConnectedModeInfo;
