import Axios from "axios";
import {
  parseBody,
  modifyAxiosError,
  getToken,
  getNewTokens,
} from "./data-service-utils";
import { isDayjs } from "dayjs";
// import { message } from "antd";
// import debounce from "lodash-es/debounce";
// For instances where no login required. E.x, forgot password, login token
const axiosNoAuth = Axios.create();
// After login.
const axiosAuth = Axios.create();

axiosNoAuth.interceptors.request.use(
  function (config) {
    config.data = parseBody(config.data);
    return config;
  },
  function (error) {
    return Promise.reject(modifyAxiosError(error));
  }
);
axiosNoAuth.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(modifyAxiosError(error));
  }
);
axiosAuth.interceptors.request.use(
  function (config) {
    config.data = parseBody(config.data);
    return config;
  },
  function (error) {
    return Promise.reject(modifyAxiosError(error));
  }
);
axiosAuth.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(modifyAxiosError(error));
  }
);
export async function requestWithNoAuth(options) {
  const response = await axiosNoAuth(options);
  return response.data;
}
// const showDebouncedMessage = debounce(
//   (msg, level = `error`) => {
//     message[level] && message[level](msg);
//   },
//   2000,
//   { leading: true, trailing: false }
// );
export async function ajax(options, callOptions = { needsToken: true }) {
  if (!callOptions.needsToken) {
    const response = await requestWithNoAuth(options);
    return response;
  }
  const tokens = await getToken();
  const { token } = tokens;
  const bearerToken = token?.jwt;

  try {
    const newOptions = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${bearerToken}`,
      },
    };
    const response = await axiosAuth(newOptions);
    return response.data;
  } catch (error) {
    console.error(options.url + ` -> status:` + error.status);
    if (Axios.isCancel(error)) {
      throw new Error("cancelled");
    } else if (error.status === 401) {
      // Get refresh token to get new token and try again.
      const newTokens = await getNewTokens(tokens);
      if (!newTokens) {
        throw new Error(`Did not get tokens.`);
      }
      const { token } = newTokens;
      const newBearerToken = token?.jwt;
      const newOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${newBearerToken}`,
        },
      };
      try {
        const response = await axiosAuth(newOptions);

        return response.data;
      } catch (error) {
        console.error(`error:`, error);
        // Request failed even with refresh token. Log out
        // if (error.status === 401) {
        //   await clearStorageAndLogout();
        // }
      }
    } else if (error.status === 403) {
      // Forbidden
      // error.responseText = `You are not allowed to perform this action.`;
      // showDebouncedMessage(
      //   `You are not allowed to perform this action.`,
      //   `error`
      // );
      throw error;
    } else if (error.status === 716) {
      console.error(`SQL Server timeout: `, error);
      throw error;
    } else {
      throw error;
    }
  }
}

export const getFetcher = (url) =>
  ajax({
    method: `GET`,
    url,
  });
export const getFetcherNoToken = (url) =>
  ajax(
    {
      method: `GET`,
      url,
    },
    {
      needsToken: false,
    }
  );
export const getCancellableFetcher = () => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();
  const fetcher = (url) =>
    ajax({
      method: `GET`,
      url,
      cancelToken: source.token,
    }).catch((e) => {
      if (Axios.isCancel(e)) {
        console.info(`Request to ${url} cancelled.`);
      } else {
        throw e;
      }
    });

  const cancel = () => {
    source.cancel();
  };

  return { fetcher, cancel };
};
export const getParamsFromObject = (paramsObject) => {
  const query = Object.entries(paramsObject)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, paramValue]) => !!paramValue)
    .map(([paramKey, paramValue]) => {
      if (isDayjs(paramValue)) {
        return (
          encodeURIComponent(paramKey) +
          `=` +
          encodeURIComponent(paramValue.utc().format())
        );
      }

      return (
        encodeURIComponent(paramKey) + `=` + encodeURIComponent(paramValue)
      );
    })
    .join(`&`);
  return query;
};
export const CreateQueryWithIsActiveOrganizationAndFilter = (
  url,
  isActive = "true",
  filter = "",
  organizationId = ""
) => {
  url += `?isActive=${isActive}`;

  if (filter !== "") {
    url += `&filter=${filter}`;
  }

  if (organizationId !== "") {
    url += `&organizationId=${organizationId}`;
  }

  return url;
};
export const CreateQueryWithOrganizationAndFilter = (
  url,
  filter = "",
  organizationId = ""
) => {
  if (filter !== "") {
    url += `?filter=${filter}`;
  }

  if (organizationId !== "") {
    url += `${filter === "" ? "?" : "&"}organizationId=${organizationId}`;
  }

  return url;
};
export const CreateQueryWithFilter = (url, filter = "") => {
  if (filter !== "") {
    url += `?filter=${filter}`;
  }
  return url;
};

export const CreateQueryWithOrganization = (url, organizationId = "") => {
  if (organizationId !== "") {
    url += `?organizationId=${organizationId}`;
  }
  return url;
};

export const CreateReportQueryWithParameterUrlAndOrganization = (
  endpoint,
  parameterUrl = "",
  organizationId = ""
) => {
  let newUrl = "";
  if (parameterUrl === "") {
    newUrl = `organizationId=${organizationId}`;
  } else {
    newUrl = `organizationId=${organizationId}&${parameterUrl}`;
  }
  return endpoint + "?" + newUrl;
};

export const CreatePreviousReportsWithQueryAndOrganiation = (
  url,
  query = "",
  organizationId = ""
) => {
  if (query !== "") {
    url += `?query=${query}`;
  }

  if (organizationId !== "") {
    url += `${query === "" ? "?" : "&"}organizationId=${organizationId}`;
  }

  return url;
};
