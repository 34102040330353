import React from "react";
import { useApiCall } from "use-api-call";
import { GetServerLogos } from "../../../DataService/Server";
import AccountSetupForm from "./AccountSetupForm";
import gkBlueHelmet from "../../../assets/images/gklogo-helmet-blue.png";
import { Button, Card, Col, message, Row } from "antd";
import { useSafeState } from "../../shared/hooks/useSafeState";
import { cleanupBackendMessage } from "../../../Utils/ErrorMessages";
import { AccountConfirmation } from "../../../DataService/Account";
import { useHistory } from "react-router-dom";

function AccountSetupLayout({ token, email }) {
  const history = useHistory();

  const [smallLogoSrc, setSmallLogoSrc] = React.useState(null);

  const { data: logoData } = useApiCall(GetServerLogos, {
    invokeOnMount: true,
  });

  React.useEffect(() => {
    if (!logoData) {
      return;
    }

    const { smallLogo } = logoData;
    if (!smallLogo) {
      setSmallLogoSrc(gkBlueHelmet);
      return;
    }
    const _smallLogo = "data:image/bmp;base64," + smallLogo;

    setSmallLogoSrc(_smallLogo);
  }, [logoData]);

  const [password, setPassword] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const [confirmPassword, setConfirmPassword] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });
  const [accountSetupLoading, setAccountSetupLoading] = useSafeState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useSafeState(true);

  const handleAccountSetup = async () => {
    try {
      setAccountSetupLoading(true);

      const data = { token, email, password: password.value };

      await AccountConfirmation(data);

      setAccountSetupLoading(false);

      message.success({
        content:
          "Successfully set up your GateKeeper Hub login. Login with your new password.",
        duration: 5,
        onClose: RedirectToLogin(),
      });
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Failed to set account password."
      );
    }
    setAccountSetupLoading(false);
  };

  const RedirectToLogin = () => {
    history.push("/login");
  };

  React.useEffect(() => {
    if (
      [password.validateStatus, confirmPassword.validateStatus].some(
        (status) => status === "error"
      ) ||
      [password.value, confirmPassword.value].some((value) => value === "")
    ) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }, [password, confirmPassword]);

  const formProps = {
    email,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    handleAccountSetup,
    accountSetupLoading,
    disableSubmitButton,
  };
  return (
    <React.Fragment>
      <Card
        title={null}
        style={{ maxWidth: "480px", minWidth: "300px" }}
        actions={[
          <Button key="returnToSignIn" type="link" href="/login">
            Return to Sign-In
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <div className="all-center">
              <img src={smallLogoSrc} alt="GateKeeper mini logo" width={80} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="pb-1 mt-3 bold all-center">
              <span
                style={{
                  fontSize: "1.2em",
                }}
              >
                Welcome to GateKeeper Hub Registration
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="pb-1 mt-1 mb-1">
              <span>
                Please choose a new password to access your GateKeeper Hub
                account.
              </span>
            </div>
          </Col>
        </Row>

        <div>
          <AccountSetupForm {...formProps} />
        </div>
      </Card>
    </React.Fragment>
  );
}
export default AccountSetupLayout;
