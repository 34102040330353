import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, message } from "antd";
import { useForgotPassword } from "../../../shared/ForgotPasswordStore";
import { cleanupBackendMessage } from "../../../../Utils/ErrorMessages";
import useMountedState from "../../../shared/hooks/useMountedState";

const formLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

function ForgotPasswordUsernameEmail({ next }) {
  const { forgotPasswordData, verifyUsernameEmail, updateKey } =
    useForgotPassword();
  const [usernameOrEmail, setUsernameOrEmail] = React.useState({
    value: forgotPasswordData.identity || "",
    validateStatus: "",
    help: "",
  });
  const [loading, setLoading] = React.useState(false);
  const isMounted = useMountedState();

  const handleUsernameChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      setUsernameOrEmail({
        ...usernameOrEmail,
        value,
        validateStatus: "error",
        help: "Cannot be empty.",
      });
    } else {
      setUsernameOrEmail({
        ...usernameOrEmail,
        value,
        validateStatus: "",
        help: "",
      });
    }
    updateKey("identity", value);
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      const identity = usernameOrEmail.value;
      if (!identity) {
        throw { responseText: "Username/Email cannot be empty" };
      }
      await verifyUsernameEmail(identity);
      next();
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) || "Validation Failed."
      );
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleNext();
        }}
        {...formLayout}
      >
        <Form.Item
          label={<span className="bold">Username/Email</span>}
          htmlFor="usernameEmail"
        >
          <Input
            value={usernameOrEmail.value}
            onChange={handleUsernameChange}
            id="usernameEmail"
            autoFocus
          />
        </Form.Item>
        <div className="h-center">
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            style={{ width: "160px" }}
          >
            {loading ? "Validating" : "Next"}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default ForgotPasswordUsernameEmail;
