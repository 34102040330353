import React from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { getFetcherNoToken } from "../../DataService/Common";

function GKFooter() {
  const { data: versionData } = useSWR(
    `/hub/api/v4/Server/Version`,
    getFetcherNoToken
  );
  return (
    <React.Fragment>
      <div className="d-flex justify-between">
        <div className="left">
          <Link to="/">DASHBOARD</Link>
          <a
            className="ml-1"
            href="https://portal.gkchain.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            CUSTOMER PORTAL
          </a>
          <a
            className="ml-1"
            href="https://gkaccess.com/support"
            target="_blank"
            rel="noopener noreferrer"
          >
            SUPPORT
          </a>
        </div>
        <div className="right">
          <span>
            Version:{" "}
            <span className="bold">{versionData?.serverVersion ?? "--"}</span>
          </span>
          <span className="ml-1">&copy; {new Date().getFullYear()}</span>
          <span className="ml-1">
            <a
              href="https://gkaccess.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Untethered Labs, Inc.
            </a>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GKFooter;
