import React from "react";
import { Link } from "react-router-dom";

function ActiveDirectoryAutoUpdateInfo({ dismissAnnouncement }) {
  return (
    <React.Fragment>
      <div>
        <p>
          GateKeeper Hub administrators can schedule automatic updates of Azure
          and On-Premise Active Directory accounts associated with GateKeeper
          Users or computers. Administrators can choose password complexity
          rules and recycle schedule to automatically change AD passwords to
          randomized values.
        </p>

        <p>
          <span className="bold color-danger">
            Warning: By choosing to set up auto-update you will lose knowledge
            of the AD passwords, which can lead to complications and errors if
            your users are using these credentials for purposes other than
            logging into their computers.
          </span>
        </p>
        <p>
          Administrators can go to the{" "}
          <Link to="/ad-management">AD Management page</Link> to set up
          automatic password updates for AD accounts connected to GateKeeper
          users and/or computers.
        </p>
      </div>
    </React.Fragment>
  );
}

export default ActiveDirectoryAutoUpdateInfo;
