import { Button, Tooltip } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import useSearchParams from "../../shared/hooks/useSearchParams";
import TaskCard from "./TaskCard";

function AddAlertTask({ task, settings }) {
  const history = useHistory();
  const searchParams = useSearchParams();

  const isAlertsEnabled = settings?.EnableAlerts?.isAllowed;

  const handleAddButtonClick = (e) => {
    history.push("/alerts/manage?open=addalert");
  };

  const handleTaskFinish = () => {
    // Clear the addusers from search params so that on refreshing it doesn't open Add Users sidebar again.
    if (searchParams.has("open") && searchParams.get("open") === "addalert") {
      history.replace("/alerts/manage");
    }
  };

  return (
    <React.Fragment>
      <TaskCard
        task={task}
        title={"Add an Alert"}
        handleTaskFinish={handleTaskFinish}
        actions={[
          <Tooltip
            key="add"
            title={!isAlertsEnabled ? "Alerts feature is not enabled." : ""}
          >
            <Button onClick={handleAddButtonClick} disabled={!isAlertsEnabled}>
              Add an Alert
            </Button>
          </Tooltip>,
        ]}
      >
        <p>
          Alerts can send emails, SMS to Hub administrators when events happen.
        </p>
        {!isAlertsEnabled ? (
          <p>
            You need to enable Alerts feature from{" "}
            <Link to="/administration#hubFeatures">Administration</Link> page
            before creating an alert.
          </p>
        ) : null}
      </TaskCard>
    </React.Fragment>
  );
}

export default AddAlertTask;
