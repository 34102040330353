import React from "react";
import { Card } from "antd";
import ReactPlayer from "react-player/vimeo";
import Modal from "antd/lib/modal/Modal";
const { Meta } = Card;
const GKVideoPlayerModal = ({ videoItem, setVideoItem }) => {
  const closeModal = () => {
    setVideoItem(null);
  };
  if (!videoItem) return null;
  const { videoURL, title, info } = videoItem;
  console.log("videoItem:", videoItem);
  return (
    <React.Fragment>
      <Modal
        visible={!!videoURL}
        width={850}
        height={1200}
        onCancel={() => {
          closeModal();
        }}
        bodyStyle={{ paddingTop: 60 }}
        footer={null}
        destroyOnClose
      >
        <Card cover={<ReactPlayer url={videoURL} playing={true} width={800} />}>
          <Meta title={title} description={info} />
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default GKVideoPlayerModal;
