import React from "react";
import { Redirect } from "react-router-dom";
import { useAuthentication } from "../shared/AuthenticationStore";
// import LoginForm from "./LoginForm";

// eslint-disable-next-line import/no-unresolved
import LoginStepsContainer from "./LoginStepsContainer";
import { cleanupBackendMessage } from "../../Utils/ErrorMessages";
import { message } from "antd";
import DemoUserWelcome from "../GKDemoLayout/DemoUserWelcome";
const TwoFAType = { phone: 1, email: 2, authenticator: 3, backup: 4 };

function LoginLayoutNew({ location }) {
  const {
    login,
    loginLoading,
    loginFromURL,
    loginWith2FA,
    isAuthenticated,
    demoLogin,
  } = useAuthentication();

  const [username, setUsername] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });
  const [password, setPassword] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });
  const [deliveryType, setDeliveryType] = React.useState(null);
  const [twoFactorData, setTwoFactorData] = React.useState(null);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [stepLoading, setStepLoading] = React.useState(false);

  const [force2FA, setForce2FA] = React.useState(false);
  const [redirectTo2FAPage, setRedirectTo2FAPage] = React.useState(null);

  let isDemoLocation = window.location.href
    .toLowerCase()
    .includes("demo.gatekeeperhub.com");

  React.useEffect(() => {
    setCurrentStep(0);
  }, []);

  React.useEffect(() => {
    if (window.location.search) {
      loginFromURL();
    }
  }, []);

  const next = () => {
    setStepLoading(true);
    setTimeout(() => {
      setStepLoading(false);
      setCurrentStep(currentStep + 1);
    }, 1000);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleLogin = async () => {
    try {
      if (!username.value || !password.value) {
        message.error("Username and Password required");
        return;
      }

      const response = await login(username.value, password.value);

      if (!response) {
        message.error("Error while logging in.");
        return;
      }
      if (response.token) {
        return;
      }
      if (response.twoFA && response.twoFA.stamp && response.twoFA.force2FA) {
        await update2FARedirect(response.twoFA.stamp);
        return;
      }

      if (response.twoFA && response.twoFA.stamp) {
        setTwoFactorData(response.twoFA);
        if (response.twoFA.phoneEnabled) setDeliveryType("phone");
        else if (response.twoFA.authenticatorEnabled)
          setDeliveryType("authenticator");

        next();
      } else {
        message.error("Error while logging in.");
        return;
      }
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) || "Error logging in."
      );
    }
  };

  const handle2FALogin = async (code) => {
    try {
      const response = await loginWith2FA(
        twoFactorData.stamp,
        TwoFAType[deliveryType],
        code
      );
      if (response && response.token) {
        return <Redirect to="/dashboard" />;
      }
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Failed to verify One-Time-Passcode."
      );
    }
  };

  const update2FARedirect = async (stamp) => {
    setStepLoading(true);
    setRedirectTo2FAPage(`/setup2FA?stamp=${stamp}`);
    setTimeout(() => {
      setStepLoading(false);
      setForce2FA(true);
    }, 1000);
  };

  if (isAuthenticated) {
    const redirectPage = location ?? "/dashboard";
    return <Redirect to={redirectPage} />;
  }
  if (force2FA) {
    return <Redirect to={redirectTo2FAPage} />;
  }

  const submitDemoForm = async (data) => {
    try {
      const response = await demoLogin(data);
      if (!response) {
        message.error("Failed to authenticate demo user.");
        return;
      }
      if (response.token) return;
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) || "Error logging in."
      );
    }
  };

  const loginFormProps = {
    username,
    setUsername,
    password,
    setPassword,
    handleLogin,
    loginLoading,
    handle2FALogin,
    twoFactorData,
    deliveryType,
    setDeliveryType,
    currentStep,
    prev,
    next,
    stepLoading,
  };

  return (
    <React.Fragment>
      {isDemoLocation ? (
        <DemoUserWelcome
          submitDemoForm={submitDemoForm}
          loginLoading={loginLoading}
        />
      ) : (
        <div className="d-flex flex-column justify-center align-center vh-100 vw-100 login-background hub-login-form">
          <div
            style={{
              width: "60vw",
              minWidth: 300,
              maxWidth: 450,
            }}
          >
            <LoginStepsContainer props={loginFormProps} />
            <div className="d-flex justify-between mt-1">
              <a
                key="support"
                target="_blank"
                rel="noopener noreferrer"
                href="https://gkaccess.com/support"
                className="color-white"
              >
                Support
              </a>
              <a
                key="company"
                target="_blank"
                rel="noopener noreferrer"
                href="https://gkaccess.com"
                className="color-white"
              >
                Untethered Labs, Inc.
              </a>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default LoginLayoutNew;
