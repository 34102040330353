import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import GKFirstUseLayout from "../GKFirstUseLayout";
import GKGeneralLayout from "./GKGeneralLayout";
import { AuthenticationProvider } from "../shared/AuthenticationStore";
import { LoggedInUserProvider } from "../shared/LoggedInUserStore";
import { GetActiveUsesAndLicensesCount } from "../../DataService/Server";
import { useApiCall } from "use-api-call";

function GKLayout() {
  const [isFirstUse, setIsFirstUse] = React.useState(false);
  const { data: firstTimedata, invoke: firstTimeInvoke } = useApiCall(
    GetActiveUsesAndLicensesCount
  );

  React.useEffect(() => {
    firstTimeInvoke();
  }, []);

  React.useEffect(() => {
    if (!firstTimedata) {
      return;
    }
    if (firstTimedata.usersCount < 1) {
      setIsFirstUse(true);
    }
  }, [firstTimedata]);

  const props = {
    isFirstUse,
    setIsFirstUse,
  };

  return (
    <React.Fragment>
      <AuthenticationProvider>
        <LoggedInUserProvider>
          <Router>
            {isFirstUse ? <GKFirstUseLayout {...props} /> : <GKGeneralLayout />}
          </Router>
        </LoggedInUserProvider>
      </AuthenticationProvider>
    </React.Fragment>
  );
}

export default GKLayout;
