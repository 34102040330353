import React from "react";
import { Link } from "react-router-dom";

function TwoFactorAuthenticationInfo({ dismissAnnouncement }) {
  return (
    <React.Fragment>
      <div>
        <p>
          Two-Factor Authentication has been added to GateKeeper Hub to enhance
          the security of administrator access. Hub admins and team members can
          set up 2FA for their accounts using a SMS-based or an Authenticator
          App based OTP for authentication.
        </p>
        <p>
          Go to your <Link to="/dashboard">Profile page</Link> to enable 2FA for
          your GateKeeper Hub login.
        </p>
        <p>
          Administrators can also mandate that all team members who have access
          to the GateKeeper Hub must set up at least one 2FA method. Go to the{" "}
          <Link to="/administration#security">Security</Link> section on the
          Administration page to enforce 2FA for all Hub team members.
        </p>
      </div>
    </React.Fragment>
  );
}

export default TwoFactorAuthenticationInfo;
