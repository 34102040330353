import React from "react";
import Setup2FALayout from "./Setup2FALayout";
import { Redirect } from "react-router-dom";

function Setup2FA() {
  const params = window.location.search?.replace("?", "").split("&") ?? [];

  if (!params || params?.length === 0) {
    return <Redirect to="/login" />;
  }
  const paramsObject = {};
  params.forEach((param) => {
    const [key, value] = param.split("=");
    if (key === "stamp") {
      paramsObject[key] = value;
    }
  });

  const stamp = paramsObject["stamp"];

  if (!stamp) return <Redirect to="/login" />;

  return (
    <React.Fragment>
      <Setup2FALayout stamp={stamp} />
    </React.Fragment>
  );
}

export default Setup2FA;
