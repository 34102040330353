import React from "react";
import { Button, Card, Col, Row, Typography } from "antd";
import { useApiCall } from "use-api-call";
import { GetServerLogos } from "../../../DataService/Server";
import gkBlueHelmet from "../../../assets/images/gklogo-helmet-blue.png";
import Setup2FAStep1 from "./Setup2FAStep1";
import Setup2FAStep2 from "./Setup2FAStep2";
import Setup2FAStep3 from "./Setup2FAStep3";
import { useSafeState } from "../../shared/hooks/useSafeState";

const Setup2FAStepContainer = ({ props }) => {
  const [smallLogoSrc, setSmallLogoSrc] = React.useState(null);
  const [currentStep, setCurrentStep] = useSafeState(0);
  const { deliveryType } = props;

  const { data: logoData } = useApiCall(GetServerLogos, {
    invokeOnMount: true,
  });

  React.useEffect(() => {
    if (!logoData) {
      return;
    }

    const { smallLogo } = logoData;
    if (!smallLogo) {
      setSmallLogoSrc(gkBlueHelmet);
      return;
    }
    const _smallLogo = "data:image/bmp;base64," + smallLogo;

    setSmallLogoSrc(_smallLogo);
  }, [logoData]);

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };
  const stepProps = {
    ...props,
    prev,
    next,
  };

  const steps = [
    {
      title: "Two-Factor Authentication Required",
      description:
        "The GateKeeper Hub administrator has mandated 2-Factor Authentication for your login. Please choose the two-factor authenticator you would like to use.",
      content: <Setup2FAStep1 {...stepProps} />,
    },
    {
      title:
        deliveryType === "phone"
          ? "Enter your Phone Number"
          : "Scan the QR code into your Authenticator App",
      description:
        deliveryType === "phone" ? (
          <span>
            Enter the phone number where you will receive the text message with
            the one-time-passcode.{" "}
            <Typography.Text type="danger">
              Warning: This phone number will be updated in your GateKeeper Hub
              profile.
            </Typography.Text>
          </span>
        ) : (
          "Use any time-based one-time-passcode (TOTP) authenticator app such as Google Authenticator."
        ),
      content: <Setup2FAStep2 {...stepProps} />,
    },
    {
      title: "Enter your One-Time Passcode",
      description:
        deliveryType === "phone"
          ? "Enter the one-time-passcode that was sent to your phone"
          : "Enter the one-time-passcode generated by your Authenticator app",
      content: <Setup2FAStep3 {...stepProps} />,
    },
  ];

  return (
    <React.Fragment>
      <Card
        title={null}
        style={{ maxWidth: "480px", minWidth: "300px" }}
        actions={[
          <Button key="returnToSignIn" type="link" href="/login">
            Return to Sign-In
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <div className="all-center">
              <img src={smallLogoSrc} alt="GateKeeper mini logo" width={80} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="pb-1 mt-3 bold all-center">
              <span
                style={{
                  fontSize: "1.2em",
                }}
              >
                {steps[currentStep].title}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="pb-1 mt-1">
              <span>{steps[currentStep].description}</span>
            </div>
          </Col>
        </Row>

        <div>{steps[currentStep].content}</div>
      </Card>
    </React.Fragment>
  );
};

export default Setup2FAStepContainer;
