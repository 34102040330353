import { Button, Card } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useRef } from "react";
import ActiveDirectoryAutoUpdateInfo from "./features/ActiveDirectoryAutoUpdateInfo";
import PasswordHistoryInfo from "./features/PasswordHistoryInfo";
import TokenHistoryInfo from "./features/TokenHistoryInfo";
import TransferAccountInfo from "./features/TransferAccountInfo";
import TwoFactorAuthenticationInfo from "./features/TwoFactorAuthenticationInfo";
import APMFeatureInfo from "./features/APMFeatureInfo";
import OTPFeatureInfo from "./features/OTPFeatureInfo";
import SIEMFeatureInfo from "./features/SIEMFeatureInfo";
import StatusOfActiveComputersFeatureInfo from "./features/StatusOfActiveComputersFeatureInfo";
import SmartcardAuthenticatorInfo from "./features/SmartcardAuthenticatorInfo";
import DefaultAuthenticatorInfo from "./features/DefaultAuthenticatorInfo";
import AlwaysConnectedModeInfo from "./features/AlwaysConnectedModeInfo";
import OktaAccessInfo from "./features/OktaAccessInfo";
import RDPLauncherInfo from "./features/RDPLauncherInfo";
import { UpdateLoggedInUser } from "../../../DataService/Account";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import SitesFeatureInfo from "./features/SitesFeatureInfo";
import RadiusFeatureInfo from "./features/RadiusFeatureInfo";

function FeatureInfoModal({ user, showModal, setShowModal }) {
  const printRef = useRef();

  const [dismissAnnouncementLoading, setDismissAnnouncementLoading] =
    React.useState(false);
  const { hasSeenFeatures } = user;
  const dismissAnnouncement = async () => {
    try {
      if (user !== undefined && hasSeenFeatures === false) {
        setDismissAnnouncementLoading(true);
        await UpdateLoggedInUser(user.id, { hasSeenFeatures: true });
        setDismissAnnouncementLoading(false);
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setShowModal(false);
    }
  };

  const showAPM = false;
  const showOTP = false;
  const showSIEM = false;
  const showStatusOfActiveComputers = false;
  const showPasswordHistoryFeature = false;
  const showTokenHistoryFeature = false;
  const showTwoFactorAuthentication = true;
  const showActiveDirectoryAutoUpdateFeature = true;
  const showTransferAccountFeature = false;
  const showSmartcardAuthenticatorFeature = true;
  const showDefaultAuthenticatorFeature = true;
  const showAlwaysConnectedModeFeature = true;
  const showOktaAccessFeature = true;
  const showRDPLauncherFeature = true;
  const showSitesFeature = true;
  const showRadiusFeature = true;

  return (
    <React.Fragment>
      <Modal
        title={
          <div className="justify-between d-flex mx-2">
            <span>New Features</span>
            <ReactToPrint
              trigger={() => <Button icon={<PrinterOutlined />}>Print</Button>}
              content={() => printRef.current}
            />
          </div>
        }
        visible={showModal}
        width={1000}
        bodyStyle={{ height: 600, overflow: "auto" }}
        onCancel={() => setShowModal(false)}
        footer={
          <div className="all-center">
            <Button
              type="link"
              onClick={() => dismissAnnouncement()}
              loading={dismissAnnouncementLoading}
            >
              {hasSeenFeatures ? "Close" : "Dismiss"}
            </Button>
          </div>
        }
      >
        <div ref={printRef}>
          {showSitesFeature && (
            <Card
              title="Sites and Role-Based Access Control"
              type="inner"
              className="mb-1"
              extra={<span>August 2023</span>}
            >
              <SitesFeatureInfo />
            </Card>
          )}
          {showRadiusFeature && (
            <Card
              title="GateKeeper RADIUS Server for 2FA"
              type="inner"
              className="mb-1"
              extra={<span>August 2023</span>}
            >
              <RadiusFeatureInfo />
            </Card>
          )}
          {showSmartcardAuthenticatorFeature && (
            <Card
              title="NFC/RFID Cards for GateKeeper Authentication"
              type="inner"
              className="mb-1"
              extra={<span>April 2023</span>}
            >
              <SmartcardAuthenticatorInfo />
            </Card>
          )}
          {showDefaultAuthenticatorFeature && (
            <Card
              title="Default Authenticator for GateKeeper"
              type="inner"
              className="mb-1"
              extra={<span>April 2023</span>}
            >
              <DefaultAuthenticatorInfo />
            </Card>
          )}
          {showAlwaysConnectedModeFeature && (
            <Card
              title="Always Connected Mode for GateKeeper"
              type="inner"
              className="mb-1"
              extra={<span>April 2023</span>}
            >
              <AlwaysConnectedModeInfo />
            </Card>
          )}
          {showOktaAccessFeature && (
            <Card
              title="Okta Access Utility for GateKeeper"
              type="inner"
              className="mb-1"
              extra={<span>April 2023</span>}
            >
              <OktaAccessInfo />
            </Card>
          )}
          {showRDPLauncherFeature && (
            <Card
              title="RDP Launcher for GateKeeper"
              type="inner"
              className="mb-1"
              extra={<span>April 2023</span>}
            >
              <RDPLauncherInfo />
            </Card>
          )}
          {showTwoFactorAuthentication && (
            <Card
              title="Two-Factor Authentication (2FA) for GateKeeper Hub"
              type="inner"
              className="mb-1"
              extra={<span>May 2022</span>}
            >
              <TwoFactorAuthenticationInfo />
            </Card>
          )}
          {showActiveDirectoryAutoUpdateFeature && (
            <Card
              title="Automatically update AD passwords on a schedule"
              type="inner"
              className="mb-1"
              extra={<span>May 2022</span>}
            >
              <ActiveDirectoryAutoUpdateInfo />
            </Card>
          )}
          {showTransferAccountFeature && (
            <Card
              title="Transfer account data from one GateKeeper user to another"
              type="inner"
              className="mb-1"
              extra={<span>May 2022</span>}
            >
              <TransferAccountInfo />
            </Card>
          )}
          {showPasswordHistoryFeature && (
            <Card
              title="History of passwords assigned to a GateKeeper user"
              type="inner"
              className="mb-1"
              extra={<span>May 2022</span>}
            >
              <PasswordHistoryInfo />
            </Card>
          )}
          {showTokenHistoryFeature && (
            <Card
              title="History of tokens assigned to a GateKeeper user"
              type="inner"
              className="mb-1"
              extra={<span>May 2022</span>}
            >
              <TokenHistoryInfo />
            </Card>
          )}
          {showAPM && (
            <Card
              title="Application Password Manager"
              type="inner"
              className="mb-1"
              extra={<span>Sep 2021</span>}
            >
              <APMFeatureInfo />
            </Card>
          )}
          {showOTP && (
            <Card
              title="Alternate login using one-time passcodes"
              type="inner"
              className="mb-1"
              extra={<span>Sep 2021</span>}
            >
              <OTPFeatureInfo />
            </Card>
          )}
          {showSIEM && (
            <Card
              title="SIEM"
              type="inner"
              className="mb-1"
              extra={<span>Sep 2021</span>}
            >
              <SIEMFeatureInfo />
            </Card>
          )}
          {showStatusOfActiveComputers && (
            <Card
              title="Real-time state of active computers"
              type="inner"
              className="mb-1"
              extra={<span>Sep 2021</span>}
            >
              <StatusOfActiveComputersFeatureInfo />
            </Card>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default FeatureInfoModal;
