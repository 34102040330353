// select the button
let zendeskButton = document.querySelector(".zendesk-button");
localStorage.setItem("zd_hasOpened", "false");

window.zESettings = {
  webWidget: {
    color: {
      launcher: "#407CCA",
      launcherText: "#ffffff",
      header: "#34475a",
      button: "#34475a",
    },
    launcher: {
      label: {
        "*": "Knowledge Base",
      },
    },
    position: { horizontal: "left" },
    zIndex: 999999,
    chat: {
      suppress: true,
    },
    helpCenter: {
      title: {
        "*": "Ask a question",
      },
      searchPlaceholder: { "*": "How to add users?" },
      filter: {
        category: "360000953033",
      },
      messageButton: {
        "*": "Leave us a message",
      },
    },
  },
};

// add the script to the page
const loadZendeskScript = () => {
  const zenDeskScript = document.createElement("script");
  zenDeskScript.id = "ze-snippet";
  zenDeskScript.src =
    "https://static.zdassets.com/ekr/snippet.js?key=21abb62b-756f-48ae-9a81-63dd148971e3";
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0] ||
    document.getElementsByTagName("script")[0].parentNode
  ).insertBefore(zenDeskScript, null);
};

// a poller that waits for a condition and executes a callback
const poller = (comparison, callback, timerStep = 250, maxTime = 5000) => {
  // why setTimeout instead of setInterval
  // https://stackoverflow.com/questions/8682622/using-setinterval-to-do-simplistic-continuous-polling
  let currTime = 0;
  const checkCondition = () => {
    // `comparison` is a function so the condition is always up-to-date
    if (comparison() === true) {
      callback();
    } else if (currTime <= maxTime) {
      currTime += timerStep;
      setTimeout(checkCondition, timerStep);
    }
  };
  checkCondition(); // calling the function
};

// load the script and execute a callback if provided
const loadZendeskChat = (callback) => {
  zendeskButton = document.querySelector(".zendesk-button");
  loadZendeskScript();
  if (callback) {
    callback();
  }
};

// this function opens the chat
const openZendeskChat = () => {
  poller(
    () => {
      // check that zendesk-related functions are available
      return typeof zE !== "undefined";
    },
    () => {
      // open the widget
      window.zE("webWidget", "open");
      poller(
        () => {
          // check that the elements exist and that the opacity is already set to "1"
          const launcher = document.querySelector("#launcher");
          const webWidget = document.querySelector("#webWidget");
          return (
            launcher !== null &&
            webWidget !== null &&
            webWidget.style.opacity === "1"
          );
        },
        () => {
          // save in localStorage
          zendeskButton.style.visibility = "hidden";
          localStorage.setItem("zd_hasOpened", "true");
        }
      );
    }
  );
};

//zendesk management
if (
  localStorage.getItem("zd_hasOpened") &&
  localStorage.getItem("zd_hasOpened") === "true"
) {
  // load the zendesk widget if we find that it was opened
  loadZendeskChat();
} else {
  // show the fake button if it's the first time it shows
  //document.querySelector(".zendesk-button").style.visibility = "visible";
}

export { loadZendeskChat, openZendeskChat };
