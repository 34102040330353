import React from "react";
import { WarningOutlined } from "@ant-design/icons";

function NotFound() {
  return (
    <React.Fragment>
      <div
        className="h-100 all-center bold"
        style={{ fontSize: "2em", minHeight: "calc(100vh - 200px)" }}
      >
        <WarningOutlined style={{ color: "red" }} />
        <div className="p-1"></div>
        404 Not Found...
      </div>
    </React.Fragment>
  );
}

export default NotFound;
