import React from "react";
import { Button, Divider } from "antd";
import FirstUserAdminCredentialForm from "./FirstUserForms/FirstUserAdminCredentialForm";

function FirstUserAdminCredential({ next, prev, formProps }) {
  const [nextDisabled, setNextDisabled] = React.useState(false);
  const { username, password, confirmPassword } = formProps;

  React.useEffect(() => {
    if (
      [
        username.validateStatus,
        password.validateStatus,
        confirmPassword.validateStatus,
      ].some((status) => status === "error") ||
      [username.value, password.value, confirmPassword.value].some(
        (value) => value === ""
      )
    ) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [username, password, confirmPassword]);

  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-between align-center h-100">
        <div className="text-center" style={{ padding: 20 }}>
          <h3>STEP 2: SET UP ADMINISTRATOR ACCOUNT</h3>
          This username and password will be used to log on to the GateKeeper
          Hub web application.
        </div>

        <FirstUserAdminCredentialForm {...formProps} />

        <div className="mb-2">
          <Button type="primary" onClick={() => prev()}>
            Previous
          </Button>
          <Divider type="vertical" />
          <Button type="primary" onClick={() => next()} disabled={nextDisabled}>
            Next
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FirstUserAdminCredential;
