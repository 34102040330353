import React from "react";
import useSWR from "swr";
import { getFetcher } from "../../DataService/Common";
import { UpdateConfiguration } from "../../DataService/Administration";

const HubConfigurationContext = React.createContext({});

function HubConfigurationProvider(props) {
  const { data, error, mutate } = useSWR(
    `/hub/api/v4/Server/Configurations`,
    getFetcher
  );

  const updateConfig = async (settingId, value) => {
    await UpdateConfiguration(settingId, { value });

    await mutate();
  };

  const value = React.useMemo(
    () => ({ config: data, error, mutate, updateConfig }),
    [data, error, mutate, updateConfig]
  );
  return <HubConfigurationContext.Provider value={value} {...props} />;
}

function useHubConfiguration() {
  const context = React.useContext(HubConfigurationContext);

  if (!context) {
    throw new Error(
      "useHubConfiguration must be used within HubConfigurationProvider"
    );
  }

  return context;
}

export { HubConfigurationProvider, useHubConfiguration };
