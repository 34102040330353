import React from "react";
import { GetNotificationsByUser } from "../../DataService/Account";
import { useAuthentication } from "./AuthenticationStore";

const NotificationsContext = React.createContext({});

const NotificationsProvider = (props) => {
  const { loggedInUser } = useAuthentication();
  const [notifications, setNotifications] = React.useState([]);
  const [unseenNotificationCount, setUnseenNotificationCount] =
    React.useState(0);
  const [notificationSidebarOpen, setNotificationSidebarOpen] =
    React.useState(false);

  const getNotifications = async () => {
    try {
      const response = await GetNotificationsByUser(loggedInUser?.id);
      const data = response.map((x) => {
        const { id } = x;
        return {
          ...x,
          id: id,
          key: id,
        };
      });

      return data;
    } catch (error) {
      console.log("getNotifications error: ", error);
    }
  };

  const updateNotifications = async () => {
    try {
      if (!loggedInUser) {
        return;
      }
      const notifications = await getNotifications();
      setNotifications(notifications ?? []);
      if (notifications.length > 0) {
        const unseenNotificationCount = notifications.reduce(
          (total, notification) => (!notification.seen ? total + 1 : total),
          0
        );
        setUnseenNotificationCount(unseenNotificationCount);
      } else {
        setUnseenNotificationCount(0);
      }
    } catch (error) {
      console.log("updateNotifications error: ", error);
    }
  };

  React.useEffect(() => {
    updateNotifications();
  }, [loggedInUser]);

  const value = React.useMemo(
    () => ({
      notifications,
      updateNotifications,
      unseenNotificationCount,
      notificationSidebarOpen,
      setNotificationSidebarOpen,
    }),
    [
      notifications,
      updateNotifications,
      unseenNotificationCount,
      notificationSidebarOpen,
      setNotificationSidebarOpen,
    ]
  );

  return <NotificationsContext.Provider value={value} {...props} />;
};

const useNotifications = () => {
  const context = React.useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      "useNotifications must be used within NotificationsProvider"
    );
  }

  return context;
};

export { NotificationsProvider, useNotifications };
