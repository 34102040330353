import React, { useState } from "react";
import Icon, {
  DashboardOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
// eslint-disable-next-line import/no-unresolved
import gkBlueHelmet from "../../../assets/images/gklogo-helmet-blue.png";
// import { FaUsers } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import descriptions from "../../PageDescription/descriptions";
import { Card } from "antd";
import ReactPlayer from "react-player/vimeo";
import Modal from "antd/lib/modal/Modal";
const { Meta } = Card;

function RegistrationSuccessGoto({ setIsFirstUse }) {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const description = descriptions["firstTimeUse"];

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsFirstUse(false);
    history.push("/dashboard");
  };
  const goToDashboard = () => {
    setIsFirstUse(false);
    history.push("/dashboard");
  };

  const watchVideo = () => {
    console.log("description:", description);
    showModal();
  };
  return (
    <React.Fragment>
      <div className="d-flex flex-column justify-between align-center text-center h-100 w-80">
        <div>
          <img alt="GateKeeper Logo" src={gkBlueHelmet} width="60" />
          <h2 className="color-success p-2">GATEKEEPER HUB IS READY FOR USE</h2>
          <div
            className="d-flex justify-between text-center w-100 align-center"
            style={{ paddingLeft: 80, paddingRight: 80 }}
          >
            <a
              className="d-flex flex-column align-center justify-center"
              onClick={() => goToDashboard()}
            >
              <Icon
                style={{ fontSize: "4em" }}
                component={() => <DashboardOutlined />}
              />
              Hub Dashboard
            </a>
            <a
              className="d-flex flex-column align-center justify-center"
              onClick={() => watchVideo()}
            >
              <Icon
                style={{ fontSize: "4em" }}
                component={() => <VideoCameraOutlined />}
              />
              Introductory Video
            </a>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        width={850}
        height={1200}
        onCancel={handleCancel}
        bodyStyle={{ paddingTop: 60 }}
        footer={null}
      >
        <Card
          cover={
            <ReactPlayer
              url={description.videoURL}
              playing={true}
              width={800}
            />
          }
        >
          <Meta title={description.title} description={description.info} />
        </Card>
      </Modal>
    </React.Fragment>
  );
}

export default RegistrationSuccessGoto;
