import React from "react";
import { Input, Popover } from "antd";

const defaultRules = {
  minlength: {
    text: "Minimum 10 characters long.",
    validate: (password) => {
      let minLength = 10;
      return password.trim().length >= minLength;
    },
    passed: false,
  },
  containNumbers: {
    text: "Contain at least one number.",
    minLength: 1,

    validate: (password) => {
      const regex = new RegExp("[0-9]", "g");
      return regex.test(password);
    },
    passed: false,
  },
  containLowerCaseAlphabet: {
    text: "Contain at least one lowercase character.",
    minLength: 1,
    validate: (password) => {
      const regex = new RegExp("[a-z]", "g");
      return regex.test(password);
    },
    passed: false,
  },
  containUpperCaseAlphabet: {
    text: "Contain at least one uppercase character.",
    minLength: 1,
    validate: (password) => {
      const regex = new RegExp("[A-Z]", "g");
      return regex.test(password);
    },
    passed: false,
  },
  containSymbols: {
    text: "Contain at least one symbol.",
    minLength: 1,
    validate: (password) => {
      const regex = new RegExp(
        "[!,%,&,@,#,$,^,*,?,_,~,/,:,;,',\",{,},\\\\,+,=,(,),-,<,>,.,\\-,\\|]",
        "g"
      );
      return regex.test(password);
    },
    passed: false,
  },
};

function PasswordWithRequirements({
  rules,
  onChange,
  isPasswordValid,
  ...otherProps
}) {
  const [validatedRules, setValidatedRules] = React.useState(rules);

  const content = React.useMemo(
    () => (
      <div>
        {Object.entries(validatedRules).map(([key, rule]) => {
          return (
            <div
              key={key}
              style={{ textDecoration: rule.passed ? "line-through" : "none" }}
            >
              {rule.text}
            </div>
          );
        })}
      </div>
    ),
    [validatedRules]
  );

  const validate = React.useCallback((password) => {
    let currentRules = { ...validatedRules };
    let updatedRules = { ...currentRules };
    Object.entries(currentRules).forEach(([key, rule]) => {
      updatedRules[key] = {
        ...updatedRules[key],
        passed: rule.validate(password),
      };
    });
    const allRulesPassed = Object.values(updatedRules).every(
      (rule) => rule.passed
    );
    if (isPasswordValid) {
      isPasswordValid(allRulesPassed);
    }
    setValidatedRules(updatedRules);
  }, []);

  const handleChange = (e) => {
    onChange(e);
    validate(e.target.value);
  };

  return (
    <>
      <Popover
        placement="bottom"
        trigger="focus"
        content={content}
        title="Password Requirements"
      >
        <Input.Password {...otherProps} onChange={handleChange} allowClear />
      </Popover>
    </>
  );
}

PasswordWithRequirements.defaultProps = {
  rules: defaultRules,
  onChange: () => {},
};

export default PasswordWithRequirements;
