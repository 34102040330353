import debounce from "lodash-es/debounce";
import memoize from "lodash-es/memoize";
// eslint-disable-next-line import/named
import { isDayjs } from "dayjs";
function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}

export const debouncedFunction = (func, timeout = 0) => {
  if (!func || !isFunction(func)) {
    throw new Error("Invalid function passed");
  }
  return (args) => debounce(() => func(args), timeout);
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea"); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

export const emulateAnchorClick = (link) => {
  const el = document.createElement("a");
  el.setAttribute("href", link);
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

export const saveFile = (url, fileName) => {
  const a = document.createElement("a");
  a.style = "display: none";
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
};

export function createParamsUrl(paramsObject) {
  const query = Object.entries(paramsObject)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, paramValue]) => !!paramValue)
    .map(([paramKey, paramValue]) => {
      if (isDayjs(paramValue)) {
        return (
          encodeURIComponent(paramKey) +
          "=" +
          encodeURIComponent(paramValue.utc().format())
        );
      }

      return (
        encodeURIComponent(paramKey) + "=" + encodeURIComponent(paramValue)
      );
    })
    .join("&");
  return query;
}

/**
 * Inserts element in array and returns new array
 *
 * @param {Array} originalArray Original array
 * @param {number} index Index to insert element at
 * @param {*} newItem Element to insert
 * @returns {Array} New array
 */
export const insertAt = (originalArray, index, newItem) => {
  let newArr = [...originalArray];
  newArr.splice(index, 0, newItem);
  return newArr;
};

/**
 * @typedef {Object} RemovedState
 * @property {*} removed - The removed item
 * @property {Array} newArray - New array after item is removed
 */
/**
 * Removes element in array at given index
 *
 * @param {Array} originalArray Original array
 * @param {number} index Index to insert element at
 * @param {*} newItem Element to insert
 * @returns {Object} RemovedState with removed and newArray
 */
export const removeAt = (originalArray, index) => {
  let newArray = [...originalArray];
  const [removed] = newArray.splice(index, 1);
  return {
    removed,
    newArray,
  };
};

/**
 * Lowercase strings. To use in map functions.
 * @example Array.map(toLowerCase)
 *
 * @param {*} str
 * @returns new array with lowercase strings
 */
export const toLowerCase = (str) => {
  return str.toLowerCase();
};

/**
 * Elimitate null and undefined from array.
 * @example Array.filter(eliminateNullUndefined)
 *
 * @param {*} item
 * @returns new array with null and undefined
 */
export const eliminateNullUndefined = (item) => {
  if (item === true || item === false) {
    return true;
  }

  return !!item;
};

/**
 * Waits for required milliseconds
 *
 * @export
 * @param {number} [timeout=2000] Timeout in milliseconds
 * @returns {Promise} promise that will resolve after timeout.
 */
export function wait(timeout = 2000) {
  return new Promise((res) => {
    let timer = setTimeout(() => {
      clearTimeout(timer);
      res();
    }, timeout);
  });
}

/**
 * Check if item is null or undefined
 *
 * @export
 * @param {*} Any item
 * @returns {Boolean}
 */
export function isNullOrUndefined(item) {
  if (item === null || item === undefined) {
    return true;
  }
  return false;
}

/**
 * Check if item is not null and undefined
 *
 * @export
 * @param {*} Any item
 * @returns {Boolean}
 */
export function isNotNullOrUndefined(item) {
  if (item !== null && item !== undefined) {
    return true;
  }
  return false;
}

/**
 * Check if string is empty
 *
 * @export
 * @param {*} Any item
 * @returns {Boolean}
 */
export function isNotEmptyString(item) {
  if (typeof item !== "string") {
    throw new Error("Input is not string");
  }

  if (item !== "") {
    return true;
  }

  return false;
}

/**
 * Check if string has windows in it
 *
 * @export
 * @param {String} Any string
 * @returns {Boolean} true if windows, false otherwise
 */
export function isWindows(str) {
  if (!str || str.length === 0) return false;
  if (str.toLowerCase().includes("windows")) {
    return true;
  }
  return false;
}

export function _getBaseDomainForHostname(hostName) {
  let domain = hostName;

  if (hostName !== null) {
    const parts = hostName.split(".").reverse();

    if (parts !== null && parts.length > 1) {
      domain = parts[1] + "." + parts[0];

      if (hostName.toLowerCase().indexOf(".co.uk") !== -1 && parts.length > 2) {
        domain = parts[2] + "." + domain;
      }
    }
  }

  return domain;
}

export const getBaseDomainForHostname = memoize(_getBaseDomainForHostname);

export function _createValidUrl(passedString) {
  const hasProtocol = /^(?:(?:https?|ftp):\/\/)/g;
  return hasProtocol.test(passedString)
    ? passedString
    : "https://" + passedString;
}

export const createValidUrl = memoize(_createValidUrl);
