import React from "react";
import { Button } from "antd";
// eslint-disable-next-line import/no-unresolved
import gkBlueHelmet from "../../../assets/images/gklogo-helmet-blue.png";

function RegistrationSuccessCongratulations({ next }) {
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="d-flex flex-column justify-between align-center text-center h-100">
          <div>
            <img alt="GateKeeper Logo" src={gkBlueHelmet} width="60" />
            <div className="p-1"></div>
            <h2 className="color-success">Congratulations</h2>
            <div>The administrator profile has been successfully created.</div>
            <div className="p-1"></div>
            <div className="mb-2">Next, we will add your license.</div>
          </div>
          <div className="mb-2">
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
}

export default RegistrationSuccessCongratulations;
