import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, message, Radio, Space } from "antd";
import { cleanupBackendMessage } from "../../../Utils/ErrorMessages";
import { Setup2FALoginScreen } from "../../../DataService/Account";

function Setup2FAStep1({
  deliveryType,
  setDeliveryType,
  next,
  stepLoading,
  stamp,
  setQrBase64Image,
  setOtpSecret,
}) {
  const handleChangeTransportType = async (e) => {
    const { value } = e.target;
    setDeliveryType(value);
  };

  const handleQRCodeSetup = async () => {
    try {
      const data = { type: 3, stamp };
      const response = await Setup2FALoginScreen(data);
      setQrBase64Image(response.qrBase64Image);
      setOtpSecret(response.otpSecret);
      return true;
    } catch (error) {
      console.log("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) || "Error generating QR code."
      );
    }
    return false;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!deliveryType) {
      message.error("Choose your 2FA method");
      return;
    }
    if (deliveryType === "phone") {
      next();
    }
    if (deliveryType === "authenticator") {
      if (await handleQRCodeSetup()) next();
    }
  };
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Radio.Group
          className="mt-1 mb-3"
          onChange={handleChangeTransportType}
          value={deliveryType}
          optionType="button"
          buttonStyle="default"
          style={{ textAlign: "center", width: "100%" }}
        >
          <Space direction="vertical">
            <Radio.Button
              style={{
                maxWidth: "360px",
                minWidth: "240px",
              }}
              value={"phone"}
            >
              SMS (Text Message)
            </Radio.Button>

            <Radio.Button
              style={{
                maxWidth: "360px",
                minWidth: "240px",
              }}
              value={"authenticator"}
            >
              Authenticator App
            </Radio.Button>
          </Space>
        </Radio.Group>
        <div className="h-center">
          <Button
            htmlType="submit"
            type="primary"
            loading={stepLoading}
            style={{ width: "160px" }}
            onClick={handleSubmit}
          >
            Next
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default Setup2FAStep1;
