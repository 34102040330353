import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, Tooltip, message, Modal, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import useMountedState from "../../shared/hooks/useMountedState";
import { Setup2FALoginScreen } from "../../../DataService/Account";
import { cleanupBackendMessage } from "../../../Utils/ErrorMessages";
import { useAuthentication } from "../../shared/AuthenticationStore";

const TwoFAType = { phone: 1, email: 2, authenticator: 3, backup: 4 };

function Setup2FAStep3({ prev, deliveryType, stamp, phoneNumber }) {
  const { verifyForce2FAAndLogin, loginLoading } = useAuthentication();
  const [sendingCode, setSendingCode] = React.useState(false);
  const [code, setCode] = React.useState("");

  const isMounted = useMountedState();

  const handleCodeChange = (e) => {
    const { value } = e.target;
    setCode(value);
  };

  const handleResendCode = async () => {
    try {
      setSendingCode(true);
      if (deliveryType === "email" || deliveryType === "phone") {
        const data = { type: 1, stamp, phoneNumber };
        const response = await Setup2FALoginScreen(data);
        message.success(response);
      }
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Error sending OTP code via SMS."
      );
    } finally {
      if (isMounted()) {
        setSendingCode(false);
      }
    }
  };
  const backupCodesModal = (backupCodes) => {
    const columnA = [],
      columnB = [];

    backupCodes.forEach((item, i) => {
      if (i % 2 === 0) {
        columnA.push(item);
      } else {
        columnB.push(item);
      }
    });
    Modal.success({
      title: `Congratulations! You have successfully enabled 2FA for your account`,
      content: (
        <React.Fragment>
          <div style={{ textAlign: "justify", justifyContent: "center" }}>
            <p>
              Save these backup codes for 2-Factor Authentication. You can use
              them to log in to your account if your chosen 2FA method is not
              accessible.
            </p>
            <Row>
              <Col span={6} offset={4}>
                {columnA.map((item, i) => {
                  return <div key={i}>{item}</div>;
                })}
              </Col>
              <Col span={6} offset={0}>
                {columnB.map((item, i) => {
                  return <div key={i}>{item}</div>;
                })}
              </Col>
            </Row>
          </div>
        </React.Fragment>
      ),
      destroyOnClose: true,
      closable: true,
      okText: "Copy to Clipboard",
      okType: "primary",
      onOk() {
        navigator.clipboard.writeText(backupCodes);
        message.success("Copied codes to clipboard");
      },
    });
  };

  const handleVerify2FA = async (code) => {
    try {
      const response = await verifyForce2FAAndLogin(
        stamp,
        TwoFAType[deliveryType],
        code
      );
      if (response && response.token) {
        backupCodesModal(response.recoveryCodes);
      }
      const { recoveryCodes } = response;
      console.log("recoveryCodes:", recoveryCodes);
    } catch (error) {
      console.log("error", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Failed to verify One-Time-Passcode."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!code) {
      message.error("OTP is required");
      return;
    }
    await handleVerify2FA(code);
  };
  return (
    <React.Fragment>
      <Form
        layout="vertical"
        wrapperCol={{ span: 14, offset: 6 }}
        onSubmit={handleSubmit}
      >
        <Form.Item htmlFor="otpcode" className="mb-2">
          <Input.Group>
            <Input
              value={code}
              onChange={handleCodeChange}
              id="otpcode"
              autoComplete={"new-username"}
              autoFocus
              allowClear
              style={{ width: "calc(100% - 40px)", textAlign: "center" }}
            />{" "}
            {deliveryType === "phone" ? (
              <Tooltip title="Resend">
                <Button icon={<ReloadOutlined />} onClick={handleResendCode} />
              </Tooltip>
            ) : null}
          </Input.Group>
        </Form.Item>
      </Form>

      <div className="h-center mt-1">
        <Button
          htmlType="submit"
          type="primary"
          style={{ width: "160px" }}
          loading={loginLoading || sendingCode}
          onClick={handleSubmit}
        >
          {loginLoading
            ? "Signing in"
            : sendingCode
            ? "Sending Code"
            : "Submit"}
        </Button>
      </div>
      <div className="h-center mt-1">
        <Button onClick={prev} type="link" style={{ width: "160px" }}>
          Go Back
        </Button>
      </div>
    </React.Fragment>
  );
}

export default Setup2FAStep3;
