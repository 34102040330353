import React from "react";
import { Link } from "react-router-dom";
import TaskCard from "./TaskCard";

function AddLicenseTask({ task }) {
  return (
    <React.Fragment>
      <TaskCard task={task} title={"Add a License"}>
        <p>GateKeeper Hub requires a valid license to enable its features.</p>
        <p>
          On <Link to="/administration#licenses">Administration</Link> page, add
          a license from <span className="bold">Licenses</span> section.
        </p>
        <p>
          You can purchase new licenses from{" "}
          <a
            href="https://portal.gkchain.com/"
            target="blank"
            rel="noreferrer noopener"
          >
            GateKeeper Customer Portal
          </a>
          .
        </p>
      </TaskCard>
    </React.Fragment>
  );
}

export default AddLicenseTask;
