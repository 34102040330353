import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button } from "antd";
import { useFormik } from "formik";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

function PortalLoginForm({ initialValues, onSubmit, validate }) {
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    validateOnMount: true,
  });

  const {
    touched,
    errors,
    handleSubmit,
    isValid,
    isSubmitting,
    getFieldProps,
    dirty,
  } = formik;

  const getError = (field, onlyDirty = true) => {
    if (onlyDirty) {
      return errors[field] && touched[field] ? errors[field] : null;
    }
    return errors[field] ? errors[field] : null;
  };

  const getValidateStatus = (field, onlyDirty) => {
    return getError(field, onlyDirty) ? "error" : "";
  };

  return (
    <React.Fragment>
      <Form {...formItemLayout} className="flex-2 w-100">
        <Form.Item
          label="Email"
          htmlFor="email"
          help={getError("email")}
          validateStatus={getValidateStatus("email")}
        >
          <Input
            autoComplete={"new-username"}
            type="email"
            autoFocus
            id="email"
            {...getFieldProps("email")}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          htmlFor="password"
          help={getError("password")}
          validateStatus={getValidateStatus("password")}
        >
          <Input.Password
            autoComplete={"new-password"}
            id="password"
            {...getFieldProps("password")}
          />
        </Form.Item>

        <div className="all-center">
          <Form.Item label="" htmlFor="save">
            <Button
              type="primary"
              htmlType="submit"
              title={!isValid ? "Please fix the errors to save changes." : null}
              disabled={!isValid || !dirty}
              loading={isSubmitting}
              onClick={handleSubmit}
              size="large"
            >
              Log in
            </Button>
          </Form.Item>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default PortalLoginForm;
