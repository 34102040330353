import React from "react";
import { Link } from "react-router-dom";
import TaskCard from "./TaskCard";

function InstallClientTask({ task }) {
  return (
    <React.Fragment>
      <TaskCard task={task} title={"Install a Client Application"}>
        <p>
          Connect a computer to GateKeeper Hub by installing GateKeeper Client
          Application on the computer and point it to the URL of GateKeeper Hub.
        </p>
        <p>
          On <Link to="/computers">Computers</Link> page, you will see the list
          of computers with GateKeeper Client application installed.
        </p>
        <p>
          The client application can be downloaded from{" "}
          <Link to="/downloads">Downloads</Link> page.
        </p>
      </TaskCard>
    </React.Fragment>
  );
}

export default InstallClientTask;
