import React from "react";
import GKRegularLayout from "./GKRegularLayout";
import { Route, Switch } from "react-router-dom";
import Login from "../Login";
import { PrivateRoute } from "../shared/PrivateRoute";
import ForgotPassword from "../Login/ForgotPassword";
// import SSO from "../Login/SSO";
import { HubConfigurationProvider } from "../shared/HubConfigurationStore";
import Setup2FA from "../Login/Setup2FA";
import AccountSetup from "../Login/AccountSetup";

function GKPublicLayout() {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/login" exact={true} component={Login} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/setup2FA" component={Setup2FA} />
        <Route path="/accountConfirmation" component={AccountSetup} />
        {/* <Route path="/single-sign-on" component={SSO} /> */}
        <HubConfigurationProvider>
          <PrivateRoute path="/" component={GKRegularLayout} />
        </HubConfigurationProvider>
      </Switch>
    </React.Fragment>
  );
}

export default GKPublicLayout;
