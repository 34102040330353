import { CreateQueryWithIsActiveOrganizationAndFilter, ajax } from "./Common";
export async function GetServerVersion() {
  return await ajax(
    {
      url: `/hub/api/v4/Server/Version`,
      method: `GET`,
      dataType: `json`,
    },
    { needsToken: false }
  );
}
export async function GetServerIPAddess() {
  return await ajax(
    {
      url: `/hub/api/v4/Server/IPAddressAndPort`,
      method: `GET`,
      dataType: `json`,
    },
    { needsToken: false }
  );
}
export async function GetServerLogos() {
  return await ajax(
    {
      url: `/hub/api/v4/Server/Logos`,
      method: `GET`,
      dataType: `json`,
    },
    { needsToken: false }
  );
}
export async function AddServerLogo(imageData, isSmallLogo) {
  return await ajax({
    url: `/hub/api/v4/Server/Logos?isSmallLogo=` + isSmallLogo,
    method: `POST`,
    data: imageData,
    processData: false,
    contentType: false,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
export async function DeleteServerLogo(isSmallLogo) {
  return await ajax({
    url: `/hub/api/v4/Server/Logos?isSmallLogo=` + isSmallLogo,
    method: `DELETE`,
  });
}
export async function GetServerSettings() {
  return await ajax({
    url: `/hub/api/v4/Server/Settings`,
    method: `GET`,
    dataType: `json`,
  });
}
export async function GetRequiresLicense() {
  return await ajax({
    url: `/hub/api/v4/Server/RequiresLicense`,
    method: `GET`,
  });
}
export async function GetActiveUsesAndLicensesCount() {
  return await ajax(
    {
      url: `/hub/api/v4/Server/ActiveUsersLicensesCount`,
      method: `GET`,
      dataType: `json`,
    },
    {
      needsToken: false,
    }
  );
}
export async function CheckIfHubRegistered() {
  return await ajax({
    url: `/hub/api/v4/Server/RegisterHub`,
    method: `GET`,
  });
}
export async function RegisterHubToCustomerPortal(data) {
  const response = await ajax({
    method: `POST`,
    url: `/hub/api/v4/Server/RegisterHub`,
    data,
  });
  return response?.data;
}

export async function GetHubUsers(
  isActive = true,
  query = "",
  organizationId = ""
) {
  let url = `/hub/api/v4/Server/HubUsers`;
  url = CreateQueryWithIsActiveOrganizationAndFilter(
    url,
    isActive,
    query,
    organizationId
  );
  return await ajax({
    url,
    method: `GET`,
    dataType: `json`,
  });
}
