export const saveInStorage = async (key, value) => {
  try {
    const data = await localStorage.setItem(key, JSON.stringify(value));
    return data;
  } catch (error) {
    console.error("error:", error);
    return null;
  }
};

export const getFromStorage = async (key) => {
  try {
    const _value = await localStorage.getItem(key);
    return JSON.parse(_value);
  } catch (error) {
    console.error("error:", error);
    return null;
  }
};

export const clearInStorage = async (key) => {
  try {
    await localStorage.removeItem(key);
  } catch (error) {
    console.error("error:", error);
  }
};
