import React from "react";

const groups = (
  <p>
    The Groups and Settings page allows you to set the authorization rules for
    GateKeeper. Groups are combinations of{" "}
    <a className="gk-link-underline" href="/users">
      Users
    </a>{" "}
    and{" "}
    <a className="gk-link-underline" href="/computers">
      Computers
    </a>
    , where every user in the group has access to every computer in that group.
    Computers in the{" "}
    <span className="group-name organization-group-name">Organization</span>{" "}
    group will be accessible to every GateKeeper user. If you want to give
    access to some computers to certain users, please put them in the same
    group. This page also allows you to choose client side settings to be
    applied to computers. The settings for a group will be applied to all
    computers in that group.
  </p>
);

const computers = (
  <p>
    The Computers page allows you to manage the computers on your network that
    are connected to GateKeeper Hub. Hover over a row in the Computers table to
    show action buttons to{" "}
    <span className="description-action-text">Manage</span> the computer. A
    sidebar will show the profile for the computer, which group it belongs to,
    and allow you to set a specific password to be used to log in to the
    computer for every GateKeeper User. Use the Kiosk Mode Password if you have
    a common account that every user will log into. You can also deploy new
    versions of the client software for each computer through the sidebar.
  </p>
);

const remoteManagement = (
  <p>
    The Remote Management page allows you to manage the system properties of
    computers on your network that are connected to GateKeeper Hub. Hover over a
    row in the Computers table to show action buttons to view and manage
    computer properties such as{" "}
    <span className="description-action-text">
      CPU usage, RAM, Processes, Services, and Logged in Users
    </span>
    . You can also install and uninstall applications remotely from here.
  </p>
);

const users = (
  <p>
    The Users page allows you to manage various properties of a GateKeeper User.
    You can add new users one at a time, or through Active Directory, or by
    uploading a CSV file. Hover over a row in the Users table to show action
    buttons to <span className="description-action-text">Manage</span> the user.
    You can manage the GateKeeper Tokens assigned to each user, reset their PIN,
    and update their computer and web passwords.
  </p>
);
const adUsers = (
  <React.Fragment>
    <p>
      GateKeeper Hub can be used to update Active Directory accounts assigned to
      users or computers currently in GateKeeper Hub. You can provision new
      users in your Active Directory and assign them GateKeeper Tokens through
      the{" "}
      <span className="description-action-text">Create New Account in AD</span>
      functionality. This requires setting up an Active Directory account with
      WRITE permissions through GateKeeper Hub Manager.
    </p>
    <p>
      <span className="bold">Important:</span> Only Hub Administrators can
      manage Active Directory accounts.
    </p>
  </React.Fragment>
);

const radiusManagement = (
  <React.Fragment>
    <p>
      GateKeeper Hub supports 2FA authentication through RADIUS. You can set up
      RADIUS authentication for your GateKeeper users by setting up a RADIUS
      server and configuring it in GateKeeper Hub. Once the RADIUS server is
      installed on your network, you can connect it to compatible RADIUS clients
      such as VPN servers for 2FA into those applications.
    </p>
    <p>
      Begin setting up the RADIUS server and connecting it to your GateKeeper
      Hub by navigating to the{" "}
      <a className="gk-link-underline" href="/administration#radiusSetup">
        RADIUS
      </a>{" "}
      tab on the Administration page.
    </p>
    <p>
      <span className="bold">Important:</span> Only Hub Administrators can
      manage users that will authenticate through the RADIUS server.
    </p>
  </React.Fragment>
);
const radiusLogs = (
  <p>
    The RADIUS Audit page shows the RADIUS Accounting logs that were received by
    the GateKeeper RADIUS Server.
  </p>
);
const alerts = (
  <p>
    The Alerts page allows you to set up customized alerts based on events sent
    by GateKeeper client application to the Hub. Certain System Alerts such as
    low battery status and new software releases are pre-set for you to use. Hub
    administrators can choose to create new alerts and have them delivered to
    Hub administrators via notifications, text messages or email. An Alert
    comprises of computers, users, and events that are sent from the client
    application to the Hub. An{" "}
    <a className="gk-link-underline" href="/alerts/logs">
      Alert Log
    </a>{" "}
    will be created every time an alert is triggered, and these logs can then be
    filtered and analyzed further.
  </p>
);

const scans = (
  <p>
    The Scans page shows the real-time location of every GateKeeper token near
    any GateKeeper enabled computers. These scans can be used to identify where
    a GateKeeper User is at any given time. The scans database can grow quite
    quickly if you have a large number of users and computers. You can choose to
    disable collecting scans by choosing the appropriate option in the Audit
    Information section of the{" "}
    <a className="gk-link-underline" href="/administration#hubFeatures">
      Administration
    </a>{" "}
    page.
  </p>
);
const downloads = (
  <p>
    The Downloads page allows you to download the latest versions of the
    GateKeeper software and reference guides. Check this page to ensure that you
    have the latest version of the client, server and other applications
    provided as part of the GateKeeper Enterprise software.
  </p>
);
const administration = (
  <p>
    The Administration page allows you to manage the configuration and access to
    the GateKeeper Hub. You can create new Hub administrators, assign them roles
    to access various parts of the Hub, add new licenses, and choose which audit
    data to collect.
  </p>
);
const clientLogs = (
  <p>
    The Client Logs page shows the audit logs collected from every GateKeeper
    enabled computer. These logs provide information regarding when computers
    were locked and unlocked using GateKeeper, which websites were visited by
    GateKeeper users, failed logins, and other events. These logs can be
    filtered in various ways, and can also be exported to a CSV file for further
    analysis.
  </p>
);
const serverLogs = (
  <p>
    The Server Logs page shows the logs regarding activities done on the
    GateKeeper Hub website. Every action that a Hub administrator does is logged
    here. You can see information regarding which users were added, which tokens
    were updated, passwords changed, and other data in these logs. These logs
    can also be exported to CSV files for further analysis.{" "}
  </p>
);

const alertLogs = (
  <p>
    The Alerts Audit page shows the logs regarding any alerts that were
    generated by GateKeeper Hub. Every occurrence of an{" "}
    <a className="gk-link-underline" href="/alerts/manage">
      Alert
    </a>{" "}
    set up by the Hub Administrator will be logged here. You can filter these
    logs by alerts, computers, users, etc. and can also export them to CSV files
    for further analysis.
  </p>
);
const reports = (
  <p>
    The Create Report page gives you access to various reports that you can run
    on the data collected by the GateKeeper Hub. You can run Computer, User,
    Activity, System Access and Location reports. For example, if you want to
    see which users have logged in to which computers using their GateKeeper
    tokens, run the{" "}
    <span className="description-action-text">Individual Sessions By User</span>{" "}
    report under System Reports. These reports can also be set to run
    automatically on a schedule using the{" "}
    <a className="gk-link-underline" href="/reports/scheduled-reports">
      Schedule Report
    </a>{" "}
    page.
  </p>
);
const scheduledReports = (
  <p>
    The Schedule Report page allows you to set up schedules for automatic
    delivery of your report. You can choose to automatically generate any report
    and have it delivered to you via email on a daily, weekly or monthly basis.
    The reports can be delivered in PDF or CSV formats. You can also choose the
    Hub Administrators who will receive these reports.
  </p>
);
const previousReports = (
  <p>
    The Reports History page shows a list of all the reports that have been
    generated on the Hub. Any report that is exported to CSV or PDF file will be
    available for download on this page. Furthermore, any reports that are
    automatically generated on a schedule will also be available for download on
    this page.
  </p>
);
const firstTimeUse = (
  <p>
    The GateKeeper Hub is the centralized management and control system for your
    GateKeeper deployment. The Hub allows you to manage all your users, their
    passwords, computers, and the policies for locking and unlocking them.
    Additionally, you can collect audit logs for all activities on the client
    side and generate detailed reports for computer usage on your network. The
    Hub also provides you with options to connect your on-premise or Azure
    Active Directory so that you can easily on-board all your users on to the
    GateKeeper platform.
  </p>
);
const dashboard = (
  <p>
    The Dashboard page provides a snapshot of the GateKeeper activity on your
    network. You can see the statistics regarding locking, unlocking and other
    data at the top of the page. You can choose to display various charts and
    table showing data collected by the Hub from the GateKeeper enabled
    computers on your network. You can see the{" "}
    <span className="description-action-text">Status of Active Computers</span>,{" "}
    <span className="description-action-text">
      Top Active Users and Computers
    </span>
    ,{" "}
    <span className="description-action-text">
      Number of Sessions and the Logged In Time
    </span>
    , and other data. Clicking on the statistics and charts will run the
    corresponding report for you.
  </p>
);
const descriptions = {
  dashboard: {
    info: dashboard,
    videoURL: "https://vimeo.com/455580142",
    title: "Dashboard",
  },
  firstTimeUse: {
    info: firstTimeUse,
    videoURL: "https://vimeo.com/455580142",
    title: "GateKeeper Hub",
  },
  groups: {
    info: groups,
    videoURL: "https://vimeo.com/455580242",
    title: "Groups and Settings",
  },
  computers: {
    info: computers,
    videoURL: "https://vimeo.com/455580288",
    title: "GateKeeper Computers",
  },
  "remote-management": {
    info: remoteManagement,
    videoURL: null,
    title: "Remote Management",
  },
  users: {
    info: users,
    videoURL: "https://vimeo.com/455580357",
    title: "GateKeeper Users",
  },
  "ad-management": { info: adUsers, videoURL: null, title: "AD Managment" },
  "radius/manage": {
    info: radiusManagement,
    videoURL: null,
    title: "RADIUS Managment",
  },
  "radius/accounting": {
    info: radiusLogs,
    videoURL: null,
    title: "RADIUS Accounting",
  },
  "alerts/manage": {
    info: alerts,
    videoURL: "https://vimeo.com/455580620",
    title: "Manage Alerts",
  },
  "alerts/logs": { info: alertLogs, videoURL: null, title: "Alerts Audit" },
  "reports/on-demand-reports": {
    info: reports,
    videoURL: "https://vimeo.com/455580570",
    title: "Create Report",
  },
  "reports/scheduled-reports": {
    info: scheduledReports,
    videoURL: null,
    title: "Schedule Report",
  },
  "reports/previous-reports": {
    info: previousReports,
    videoURL: null,
    title: "Reports History",
  },
  scans: { info: scans, videoURL: null, title: "Scans" },
  downloads: {
    info: downloads,
    videoURL: "https://vimeo.com/455580650",
    title: "Downloads",
  },
  administration: {
    info: administration,
    videoURL: null,
    title: "Hub Administration",
  },
  "logs/server-logs": {
    info: serverLogs,
    videoURL: "https://vimeo.com/455580503",
    title: "Server Audit Logs",
  },
  "logs/client-logs": {
    info: clientLogs,
    videoURL: "https://vimeo.com/455580400",
    title: "Client Audit Logs",
  },
};

export default descriptions;
