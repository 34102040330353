import React from "react";
import useMountedState from "./useMountedState";

/**
 * Alternative to React.useState() which only runs setState if component is mounted.
 *
 * @export
 * @param {*} initialState
 * @returns {[state, safeSetState]} [state, setState]
 */
export function useSafeState(initialState) {
  const [state, setState] = React.useState(initialState);
  const isMounted = useMountedState();
  let safeSetState = React.useCallback(
    (...args) => (isMounted() ? setState(...args) : () => {}),
    [setState, isMounted]
  );
  return [state, safeSetState];
}
