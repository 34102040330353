import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input } from "antd";
import {
  validatePhoneNumber,
  validateEmail,
} from "./first-user-form-validators";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

function FirstUserAdminProfileForm({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
}) {
  const handleChangeFirstName = (e) => {
    const value = e.target.value;
    setFirstName({ ...firstName, value });
  };

  const handleChangeLastName = (e) => {
    const value = e.target.value;
    setLastName({ ...lastName, value });
  };

  const handleChangeEmail = (e) => {
    const value = e.target.value;
    const validatedValue = validateEmail(value);

    setEmail({ value, ...validatedValue });
  };

  const handleChangePhoneNumber = (e) => {
    const value = e.target.value;
    const validatedValue = validatePhoneNumber(value);
    setPhoneNumber({ value, ...validatedValue });
  };
  return (
    <React.Fragment>
      <Form {...formItemLayout} className="w-100">
        <Form.Item
          label="First Name"
          htmlFor="firstName"
          help={firstName.help}
          validateStatus={firstName.validateStatus}
        >
          <Input
            autoComplete={"new-username"}
            autoFocus
            id="firstName"
            value={firstName.value}
            onChange={handleChangeFirstName}
          />
        </Form.Item>
        <Form.Item
          label="Last Name"
          htmlFor="lastName"
          help={lastName.help}
          validateStatus={lastName.validateStatus}
        >
          <Input
            autoComplete={"new-username"}
            id="lastName"
            value={lastName.value}
            onChange={handleChangeLastName}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          htmlFor="email"
          help={email.help}
          validateStatus={email.validateStatus}
        >
          <Input
            autoComplete={"new-username"}
            id="email"
            value={email.value}
            onChange={handleChangeEmail}
          />
        </Form.Item>
        <Form.Item
          label="Phone number"
          htmlFor="phoneNumber"
          help={phoneNumber.help}
          validateStatus={phoneNumber.validateStatus}
        >
          <Input
            autoComplete={"new-username"}
            id="phoneNumber"
            value={phoneNumber.value}
            onChange={handleChangePhoneNumber}
          />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}

export default FirstUserAdminProfileForm;
