import React from "react";
import { Button, Card, Col, Row } from "antd";
import ForgotPasswordUsernameEmail from "./ForgotPasswordSteps/ForgotPasswordUsernameEmail";
import RecoveryCodeOptions from "./ForgotPasswordSteps/RecoveryCodeOptions";
import RecoveryCodeInputContainer from "./ForgotPasswordSteps/RecoveryCodeInputContainer";
import ChangePassword from "./ForgotPasswordSteps/ChangePassword";
// import PasswordChangeSuccess from "./ForgotPasswordSteps/PasswordChangeSuccess";
import { useForgotPassword } from "../../shared/ForgotPasswordStore";
import { useApiCall } from "use-api-call";
import { GetServerLogos } from "../../../DataService/Server";
import gkBlueHelmet from "../../../assets/images/gklogo-helmet-blue.png";

const ForgotPasswordStepContainer = (props) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const { resetData } = useForgotPassword();
  const [smallLogoSrc, setSmallLogoSrc] = React.useState(null);

  const { data: logoData } = useApiCall(GetServerLogos, {
    invokeOnMount: true,
  });

  React.useEffect(() => {
    if (!logoData) {
      return;
    }

    const { smallLogo } = logoData;
    if (!smallLogo) {
      setSmallLogoSrc(gkBlueHelmet);
      return;
    }
    const _smallLogo = "data:image/bmp;base64," + smallLogo;

    setSmallLogoSrc(_smallLogo);
  }, [logoData]);
  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const resetForgotPasswordStep = React.useCallback(() => {
    setCurrentStep(0);
    // resetForgotPasswordStepData();
    resetData();
  }, []);

  React.useEffect(() => {
    resetForgotPasswordStep();
  }, []);

  const stepProps = {
    ...props,
    next,
    prev,
    currentStep,
    resetForgotPasswordStep,
  };

  const steps = [
    {
      title: "Reset your GateKeeper Hub Password",
      description:
        "Enter the username or email address associated with your account to begin the password reset process",
      content: <ForgotPasswordUsernameEmail {...stepProps} />,
    },
    {
      title: "Receive a One-Time Recovery Code",
      description:
        "Please choose where you would like to receive a one-time recovery code to verify your account.",
      content: <RecoveryCodeOptions {...stepProps} />,
    },
    {
      title: "Enter your Recovery Code",
      description:
        "Please enter the recovery code you were sent to your preferred choice.",
      content: <RecoveryCodeInputContainer {...stepProps} />,
    },
    {
      title: "Enter your New Password",
      description: "Choose a complex password for your GateKeeper Hub account.",
      content: <ChangePassword {...stepProps} />,
    },
    {
      title: "Congratulations!",
      description:
        "Your GateKeeper Hub password has been changed successfully. Please Return to the Sign-In page to access your GateKeeper Hub.",
    },
  ];

  return (
    <React.Fragment>
      <Card
        title={null}
        style={{ maxWidth: "480px", minWidth: "300px" }}
        actions={[
          <Button key="returnToSignIn" type="link" href="/login">
            Return to Sign-In
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <div className="all-center">
              <img src={smallLogoSrc} alt="GateKeeper mini logo" width={80} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="pb-1 mt-3 bold all-center">
              <span
                style={{
                  fontSize: "1.2em",
                }}
              >
                {steps[currentStep].title}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="pb-1 mt-1">
              <span>{steps[currentStep].description}</span>
            </div>
          </Col>
        </Row>

        <div>{steps[currentStep].content}</div>
      </Card>
    </React.Fragment>
  );
};

export default ForgotPasswordStepContainer;
