import { Button, Tooltip } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import TaskCard from "./TaskCard";

function UseReportsTask({ task, settings }) {
  const history = useHistory();

  const handleAddButtonClick = (e) => {
    history.push("/reports/on-demand-reports");
  };

  const featureEnabled = settings?.EnableReports?.isAllowed;

  return (
    <React.Fragment>
      <TaskCard
        task={task}
        title={"Generate a Report"}
        actions={[
          <Tooltip
            key="add"
            title={!featureEnabled ? "Reports feature is not enabled." : ""}
          >
            <Button onClick={handleAddButtonClick} disabled={!featureEnabled}>
              Generate a Report
            </Button>
          </Tooltip>,
        ]}
      >
        <p>
          You can generate various reports about GateKeeper usage in your
          company.
        </p>
        {!featureEnabled ? (
          <p>
            You need to enable Reports feature from{" "}
            <Link to="/administration#hubFeatures">Administration</Link> page
            before generating a report.
          </p>
        ) : null}
      </TaskCard>
    </React.Fragment>
  );
}

export default UseReportsTask;
