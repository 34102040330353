import React from "react";
import { Steps } from "antd";
import FirstUserWelcome from "./FirstUserWelcome";
import FirstUserAdminProfile from "./FirstUserAdminProfile";
import FirstUserAdminCredential from "./FirstUserAdminCredential";
import FirstUserSummary from "./FirstUserSummary";

const { Step } = Steps;

function FirstUserSteps(props) {
  const [currentStep, setCurrentStep] = React.useState(0);

  const [firstName, setFirstName] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const [lastName, setLastName] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const [email, setEmail] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const [phoneNumber, setPhoneNumber] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const [username, setUsername] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const [password, setPassword] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const [confirmPassword, setConfirmPassword] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const formProps = {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    username,
    setUsername,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const resetNewUser = React.useCallback(() => {
    setCurrentStep(0);
  }, []);

  React.useEffect(() => {
    // resetNewUser();
  }, []);

  const goToStep = (step) => {
    if (typeof step !== "number" || step < 0) {
      return;
    }

    setCurrentStep(step);
  };

  const getStepTitle = (title = "", stepNumber) => {
    const shouldAllowClick = currentStep > stepNumber;
    return (
      <span
        style={{ cursor: shouldAllowClick ? "pointer" : "default" }}
        onClick={() => {
          if (shouldAllowClick) {
            goToStep(stepNumber);
          }
        }}
      >
        {title}
      </span>
    );
  };

  const stepProps = {
    ...props,
    next,
    prev,
    currentStep,
    resetNewUser,
    formProps,
  };

  const steps = [
    {
      title: getStepTitle("Welcome", 0),
      content: <FirstUserWelcome {...stepProps} />,
    },
    {
      title: getStepTitle("Admin Profile", 1),
      content: <FirstUserAdminProfile {...stepProps} />,
    },
    {
      title: getStepTitle("Admin Password", 2),
      content: <FirstUserAdminCredential {...stepProps} />,
    },
    {
      title: getStepTitle("Submit", 3),
      content: <FirstUserSummary {...stepProps} />,
    },
  ];

  return (
    <React.Fragment>
      <Steps progressDot current={currentStep}>
        {steps.map((item, stepIndex) => (
          <Step key={stepIndex} title={item.title} />
        ))}
      </Steps>
      <div className="p-2 steps-content" style={{ height: "95%" }}>
        {steps[currentStep].content}
      </div>
    </React.Fragment>
  );
}

export default FirstUserSteps;
