import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, message } from "antd";
import PasswordWithRequirements from "../../../shared/UI/PasswordWithRequirements";
import { useForgotPassword } from "../../../shared/ForgotPasswordStore";
import { cleanupBackendMessage } from "../../../../Utils/ErrorMessages";
import useMountedState from "../../../shared/hooks/useMountedState";
import { generateRandomPassword } from "../../../shared/utils/passwordUtils";
import { copyToClipboard } from "../../../shared/utils/general";

function ChangePassword({ next }) {
  const defaultRandomPasswordOptions = {
    length: 16,
    numbers: true,
    symbols: true,
    uppercase: true,
    excludeSimilarCharacters: true,
    strict: true,
    exclude: "/",
  };
  const { updatePassword, updateKey } = useForgotPassword();
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const [confirmPassword, setConfirmPassword] = React.useState({
    value: "",
    validateStatus: "",
    help: "",
  });

  const [isPasswordStrong, setIsPasswordStrong] = React.useState(false);
  const isMounted = useMountedState();

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      setPassword({
        ...password,
        value,
        validateStatus: "error",
        help: "Cannot be empty.",
      });
    } else {
      setPassword({
        ...password,
        value,
        validateStatus: "",
        help: "",
      });
    }
    updateKey("password", value);
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    if (value !== password.value) {
      setConfirmPassword({
        ...confirmPassword,
        value,
        validateStatus: "error",
        help: "Does not match password.",
      });
    } else {
      setConfirmPassword({
        ...confirmPassword,
        value,
        validateStatus: "",
        help: "",
      });
    }
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      await updatePassword();
      next();
    } catch (error) {
      console.error("error:", error);
      message.error(
        cleanupBackendMessage(error.responseText) ||
          "Failed to change password."
      );
    } finally {
      if (isMounted()) {
        setLoading(false);
      }
    }
  };

  const arePasswordsSame = () => {
    return password.value === confirmPassword.value;
  };

  const checkIfPasswordStrong = React.useCallback(
    (allPasswordRulesChecked) => {
      setIsPasswordStrong(allPasswordRulesChecked);
    },
    [password, confirmPassword]
  );

  const isPasswordValid = isPasswordStrong && arePasswordsSame();
  const formLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleRandomPassword = async () => {
    const randomPassword = generateRandomPassword(defaultRandomPasswordOptions);
    copyToClipboard(randomPassword);
    message.success("Secure password copied to clipboard");
  };
  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleNext();
        }}
        {...formLayout}
      >
        <Form.Item
          label={
            <div className="d-flex justify-between align-center w-100">
              <span className="bold">New Password:</span>
              <span>
                <Button
                  type="link"
                  className="p-0"
                  tabIndex="-1"
                  onClick={handleRandomPassword}
                >
                  Generate Password
                </Button>
              </span>
            </div>
          }
          htmlFor="password"
          className="mb-0"
          validateStatus={password.validateStatus}
          help={password.help}
        >
          <PasswordWithRequirements
            value={password.value}
            onChange={handlePasswordChange}
            isPasswordValid={checkIfPasswordStrong}
            id="password"
            autoComplete={"new-password"}
            autoFocus
          />
        </Form.Item>
        <Form.Item
          label={<span className="bold">Confirm Password:</span>}
          htmlFor="confirmPassword"
          validateStatus={confirmPassword.validateStatus}
          help={confirmPassword.help}
        >
          <Input.Password
            allowClear
            value={confirmPassword.value}
            onChange={handleConfirmPasswordChange}
            autoComplete={"new-password"}
            id="confirmPassword"
          />
        </Form.Item>
        <div className="h-center">
          <Button
            htmlType="submit"
            type="primary"
            style={{ width: "160px" }}
            disabled={!isPasswordValid}
            loading={loading}
            title={
              !isPasswordValid ? "Password doesn't meet requirements." : null
            }
          >
            {loading ? "Updating" : "Change Password"}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default ChangePassword;
