import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import React from "react";
import { SETUP_TASKS, useSetupHub } from "./SetupHubStore";
import AddAdminTask from "./Tasks/AddAdminTask";
import AddUserTask from "./Tasks/AddUserTask";
import AddLicenseTask from "./Tasks/AddLicenseTask";
import AddScheduledReportTask from "./Tasks/AddScheduledReportTask";
import AddAlertTask from "./Tasks/AddAlertTask";
import ChangeFeaturesTask from "./Tasks/ChangeFeaturesTask";
import ChangeGroupSettingsTask from "./Tasks/ChangeGroupSettingsTask";
import InstallClientTask from "./Tasks/InstallClientTask";
import UseGateKeeperTask from "./Tasks/UseGateKeeperTask";
import FilterLogsTask from "./Tasks/FilterLogsTask";
import UseReportsTask from "./Tasks/UseReportsTask";
import AddActiveDirectoryTask from "./Tasks/AddActiveDirectoryTask";

function SetupHubTasks() {
  const { tasks, settings } = useSetupHub();
  const [finishedTasks, setFinishedTasks] = React.useState(null);
  const [unfinishedTasks, setUnfinishedTasks] = React.useState(null);
  const [skippedTasks, setSkippedTasks] = React.useState(null);
  React.useEffect(() => {
    let finishedTasks = {};
    let unfinishedTasks = {};
    let skippedTasks = {};
    Object.entries(tasks).forEach(([key, value]) => {
      if (value.finished) {
        finishedTasks[key] = value;
      } else if (value.skipped) {
        skippedTasks[key] = value;
      } else {
        unfinishedTasks[key] = value;
      }
    });
    setSkippedTasks(skippedTasks);
    setFinishedTasks(finishedTasks);
    setUnfinishedTasks(unfinishedTasks);
  }, [tasks]);

  const TASK_COMPONENT_MAP = React.useMemo(() => {
    if (!settings) {
      return {};
    }

    let coreTasks = {
      [SETUP_TASKS.INSTALL_CLIENT]: (
        <InstallClientTask
          settings={settings}
          task={tasks[SETUP_TASKS.INSTALL_CLIENT]}
        />
      ),
      [SETUP_TASKS.ADD_USER]: (
        <AddUserTask settings={settings} task={tasks[SETUP_TASKS.ADD_USER]} />
      ),
      [SETUP_TASKS.USE_GATEKEEPER]: (
        <UseGateKeeperTask
          settings={settings}
          task={tasks[SETUP_TASKS.USE_GATEKEEPER]}
        />
      ),
      [SETUP_TASKS.CHANGE_GROUP_SETTINGS]: (
        <ChangeGroupSettingsTask
          settings={settings}
          task={tasks[SETUP_TASKS.CHANGE_GROUP_SETTINGS]}
        />
      ),
    };
    let adminTasks = {
      [SETUP_TASKS.CHANGE_FEATURES]: (
        <ChangeFeaturesTask
          settings={settings}
          task={tasks[SETUP_TASKS.CHANGE_FEATURES]}
        />
      ),
      [SETUP_TASKS.ADD_ADMIN]: (
        <AddAdminTask settings={settings} task={tasks[SETUP_TASKS.ADD_ADMIN]} />
      ),
      [SETUP_TASKS.ADD_LICENSE]: (
        <AddLicenseTask
          settings={settings}
          task={tasks[SETUP_TASKS.ADD_LICENSE]}
        />
      ),
    };
    let reportingTasks = {
      [SETUP_TASKS.FILTER_AUDIT_LOGS]: (
        <FilterLogsTask
          settings={settings}
          task={tasks[SETUP_TASKS.FILTER_AUDIT_LOGS]}
        />
      ),
      [SETUP_TASKS.ADD_ALERT]: (
        <AddAlertTask settings={settings} task={tasks[SETUP_TASKS.ADD_ALERT]} />
      ),
      [SETUP_TASKS.USE_REPORTS]: (
        <UseReportsTask
          settings={settings}
          task={tasks[SETUP_TASKS.USE_REPORTS]}
        />
      ),
      [SETUP_TASKS.ADD_SCHEDULED_REPORT]: (
        <AddScheduledReportTask
          settings={settings}
          task={tasks[SETUP_TASKS.ADD_SCHEDULED_REPORT]}
        />
      ),
    };
    let advancedTasks = {
      [SETUP_TASKS.ADD_ACTIVE_DIRECTORY]: (
        <AddActiveDirectoryTask
          settings={settings}
          task={tasks[SETUP_TASKS.ADD_ACTIVE_DIRECTORY]}
        />
      ),
    };

    let availableTasks = {
      ...coreTasks,
      ...adminTasks,
      ...reportingTasks,
      ...advancedTasks,
    };

    return availableTasks;
  }, [SETUP_TASKS, tasks, settings]);

  if (!finishedTasks && !unfinishedTasks) {
    return "Loading...";
  }

  return (
    <React.Fragment>
      <p>
        Complete these tasks to fully secure your organization with GateKeeper
        Enterprise suite.
      </p>
      <div className="my-2">
        <h2>
          <WarningOutlined style={{ color: "orange" }} />
          <span className="ml-1">Pending Tasks</span>
        </h2>
      </div>
      {Object.keys(unfinishedTasks).map((task) => (
        <div key={task} className="mb-1">
          {TASK_COMPONENT_MAP[task]}
        </div>
      ))}
      <div className="my-2">
        <h2>
          <CheckCircleOutlined style={{ color: "green" }} />
          <span className="ml-1">Completed Tasks</span>
        </h2>
      </div>
      {Object.keys(finishedTasks).map((task) => (
        <div key={task} className="mb-1">
          {TASK_COMPONENT_MAP[task]}
        </div>
      ))}
      <div className="my-2">
        <h2>
          <WarningOutlined style={{ color: "gray" }} />
          <span className="ml-1">Skipped Tasks</span>
        </h2>
      </div>
      {Object.keys(skippedTasks).map((task) => (
        <div key={task} className="mb-1">
          {TASK_COMPONENT_MAP[task]}
        </div>
      ))}
      {/* Empty div to allow space for KB/Help button at bottom left */}
      <div className="m-5" />
    </React.Fragment>
  );
}

export default SetupHubTasks;
