import React from "react";
import useMountedState from "./hooks/useMountedState";
import { saveInStorage, getFromStorage, clearInStorage } from "./utils/storage";
import { Spin } from "antd";
import {
  GetLoggedInUserDetails,
  Login,
  Logout,
  Login2FA,
  Verify2FALoginScreen,
  DemoLogin,
} from "../../DataService/Account";
import { useSafeState } from "./hooks/useSafeState";
import { disable as disableDarkMode } from "darkreader";
import { checkUserPageAccessDetails } from "./roles";
import { getToken } from "../../DataService/data-service-utils";
import dayjs from "../shared/utils/dayjsUtils";

const AuthenticationContext = React.createContext({});

function AuthenticationProvider(props) {
  const [loginLoading, setLoginLoading] = useSafeState(false);
  const { children, ...otherProps } = props;
  const isMounted = useMountedState();
  const [checkingIfAuthenticated, setCheckingIfAuthenticated] =
    useSafeState(true);
  const [tokens, setTokens] = useSafeState(null);
  const [isAuthenticated, setIsAuthenticated] = useSafeState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useSafeState(false);
  const [loggedInUser, setLoggedInUser] = useSafeState(null);

  const isTokenAvailable = async () => {
    const tokens = await getToken();
    if (!tokens) {
      return false;
    }
    const { token } = tokens;
    const expiryTime = token?.exp;
    const expiryTimeUTC = dayjs.unix(expiryTime).utc();
    const currentTimeUTC = dayjs().utc();

    if (currentTimeUTC.isSameOrAfter(expiryTimeUTC)) {
      await clearInStorage("gk_tokens");
      await clearInStorage("gk_user");
      await clearInStorage("gk_hasFeatureModalBeenShown");
      await clearInStorage("zd_hasOpened");
      await clearInStorage("selectedOrganizationId");
      return false;
    }
    return true;
  };

  const getLoggedInUserDetails = async (userId) => {
    const user = await GetLoggedInUserDetails(userId);
    const access = checkUserPageAccessDetails(user?.roles);
    let userWithRoles = {
      ...user,
      access,
    };
    const userWithRolesBase64 = Buffer.from(
      JSON.stringify(userWithRoles)
    ).toString("base64");

    await saveInStorage("gk_user", userWithRolesBase64);

    const organizationsByName = user.organizations.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const globalOrganization = organizationsByName.find((x) => x.isDefault);
    if (globalOrganization) {
      await saveInStorage("selectedOrganizationId", globalOrganization.id);
    } else {
      await saveInStorage("selectedOrganizationId", organizationsByName[0].id);
    }
    setLoggedInUser(userWithRoles);
  };

  const authenticate = async (username, password) => {
    const response = await Login({
      username,
      password,
    });

    if (!response) return;

    if (response && response.twoFA && response.twoFA.stamp) {
      setRedirectToReferrer(true);
      setIsAuthenticated(false);
      return response;
    }
    const { token, refreshToken, signature } = response;

    await saveInStorage("gk_tokens", { token, refreshToken, signature });
    let userId = response?.token?.id;
    if (userId) {
      await getLoggedInUserDetails(userId);
    }
    setTokens({ token, refreshToken, signature });
    setRedirectToReferrer(true);
    setIsAuthenticated(true);
    return response;
  };
  const authenticate2FA = async (stamp, type, code) => {
    const response = await Login2FA({
      stamp,
      type,
      code,
    });

    await saveInStorage("gk_tokens", response);
    let userId = response?.token?.id;
    if (userId) {
      await getLoggedInUserDetails(userId);
    }
    setTokens(response);
    setRedirectToReferrer(true);
    setIsAuthenticated(true);
    return response;
  };

  const login = async (username, password) => {
    try {
      setLoginLoading(true);
      return await authenticate(username, password);
    } catch (error) {
      console.error("error:", error);
      throw error;
    } finally {
      if (isMounted()) {
        setLoginLoading(false);
      }
    }
  };
  const demoLogin = async ({ name, company, email, phone }) => {
    try {
      setLoginLoading(true);
      return await authenticateDemo(name, company, email, phone);
    } catch (error) {
      console.error("error:", error);
      throw error;
    } finally {
      if (isMounted()) {
        setLoginLoading(false);
      }
    }
  };
  const loginWith2FA = async (stamp, type, code) => {
    try {
      setLoginLoading(true);
      return await authenticate2FA(stamp, type, code);
    } catch (error) {
      console.error("error:", error);
      throw error;
    } finally {
      if (isMounted()) {
        setLoginLoading(false);
      }
    }
  };

  const verifyForce2FAAndAuthenticate = async (stamp, type, code) => {
    const response = await Verify2FALoginScreen({
      stamp,
      type,
      code,
    });

    const { token, refreshToken, signature } = response;
    await saveInStorage("gk_tokens", { token, refreshToken, signature });
    let userId = response?.token?.id;
    if (userId) {
      await getLoggedInUserDetails(userId);
    }
    setTokens({ token, refreshToken, signature });
    setRedirectToReferrer(true);
    setIsAuthenticated(true);
    return response;
  };

  const verifyForce2FAAndLogin = async (stamp, type, code) => {
    try {
      setLoginLoading(true);
      return await verifyForce2FAAndAuthenticate(stamp, type, code);
    } catch (error) {
      console.error("error:", error);
      throw error;
    } finally {
      if (isMounted()) {
        setLoginLoading(false);
      }
    }
  };
  const loginFromURL = async () => {
    try {
      const params = window.location.search?.replace("?", "").split("&") ?? [];

      if (!params || params?.length === 0) {
        return false;
      }
      const acceptedKeys = ["token", "id", "refreshToken"];
      const paramsObject = {};
      params.forEach((param) => {
        const [key, value] = param.split("=");
        if (acceptedKeys.includes(key)) {
          paramsObject[key] = value;
        }
      });

      if (Object.keys(paramsObject).length !== acceptedKeys.length) {
        return false;
      }

      const loginObject = {
        signature: "",
        refreshToken: paramsObject.refreshToken,
        token: {
          jwt: paramsObject.token,
          id: paramsObject.id,
        },
      };

      await saveInStorage("gk_tokens", loginObject);

      let userId = loginObject?.token?.id;

      if (userId) {
        await getLoggedInUserDetails(userId);
      }

      setTokens(loginObject);
      setRedirectToReferrer(true);
      setIsAuthenticated(true);
      return true;
    } catch (error) {
      console.error("url login errorerror:", error);
      return false;
    } finally {
      if (isMounted()) {
        setLoginLoading(false);
      }
    }
  };

  const authenticateDemo = async (name, company, email, phone) => {
    const response = await DemoLogin({
      name,
      company,
      email,
      phone,
    });

    if (!response) return null;

    const { token, refreshToken, signature } = response;

    await saveInStorage("gk_tokens", { token, refreshToken, signature });
    let userId = response?.token?.id;
    if (userId) {
      await getLoggedInUserDetails(userId);
    }
    setTokens({ token, refreshToken, signature });
    setRedirectToReferrer(true);
    setIsAuthenticated(true);
    return response;
  };

  const logout = async () => {
    const currentToken = await getToken();
    if (currentToken) {
      try {
        const token = currentToken?.token?.jwt;
        const refreshToken = currentToken?.refreshToken;
        await Logout({ token, refreshToken });
      } catch (error) {
        console.error("logout error: ", error);
      }
    }
    await clearInStorage("gk_tokens");
    await clearInStorage("gk_user");
    await clearInStorage("gk_hasFeatureModalBeenShown");
    await clearInStorage("zd_hasOpened");
    await clearInStorage("selectedOrganizationId");
    disableDarkMode();
    if (isMounted()) {
      setRedirectToReferrer(false);
      setIsAuthenticated(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (window.location.href.toLowerCase().includes("accountconfirmation")) {
        setCheckingIfAuthenticated(false);
      } else if (window.location.href.toLowerCase().includes("setup2fa")) {
        setCheckingIfAuthenticated(false);
      } else if (
        window.location.href.toLowerCase().includes("forgotpassword")
      ) {
        setCheckingIfAuthenticated(false);
      } else {
        setCheckingIfAuthenticated(true);
        const haveTokens = await isTokenAvailable();
        const loggedInUserBase64 = await getFromStorage("gk_user");
        let loggedInUser = null;
        try {
          loggedInUser = JSON.parse(
            Buffer.from(loggedInUserBase64, "base64").toString()
          );
        } catch (error) {
          loggedInUser = null;
        }
        if (haveTokens && loggedInUser) {
          setRedirectToReferrer(true);
          setIsAuthenticated(true);
          setLoggedInUser(loggedInUser);
        }
        setCheckingIfAuthenticated(false);
      }
    })();
  }, []);

  const value = React.useMemo(
    () => ({
      authenticate,
      demoLogin,
      logout,
      isAuthenticated,
      login,
      redirectToReferrer,
      loginLoading,
      loggedInUser,
      getLoggedInUserDetails,
      tokens,
      loginFromURL,
      loginWith2FA,
      verifyForce2FAAndLogin,
    }),
    [
      authenticate,
      demoLogin,
      logout,
      isAuthenticated,
      login,
      redirectToReferrer,
      loginLoading,
      loggedInUser,
      getLoggedInUserDetails,
      tokens,
      loginFromURL,
      loginWith2FA,
      verifyForce2FAAndLogin,
    ]
  );

  return (
    <AuthenticationContext.Provider value={value} {...otherProps}>
      {checkingIfAuthenticated ? (
        <div className="vh-100 vw-100 all-center">
          <Spin />
        </div>
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}

function useAuthentication() {
  const context = React.useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      "useAuthentication must be used within AuthenticationProvider"
    );
  }

  return context;
}

export { AuthenticationProvider, useAuthentication };
